'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:UploadAudioModalCtrl
 * @description
 * # UploadAudioModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('UploadAudioModalCtrl', function ($scope, $rootScope, $uibModalInstance, $timeout,
                                                  $filter, $sce, $http, $state, modalOptions, Upload,
                                                  Playlist, gettextCatalog, $q) {
        $scope.modalOptions = modalOptions;

        $scope.data = {
            phase: 'start',
            errors: null,
            playerStatus: -1,
            pinPosition: 0
        };
        $scope.form = {
            file: null,
            url: modalOptions.url,
            onlyInstrumental: false
        };
        $scope.maxIsSet = false;
        $scope.checkStop = false;
        $scope.soundcloudMaxIsSet = false;
        $scope.soundCheckStop = false;

        $scope.slider = {
            min: 0,
            max: 200,
            options: {
                floor: 0,
                ceil: 200,
                translate: function(value) {
                    jQuery('.rz-bar-wrapper.rz-left-out-selection').css('visibility', 'visible');
                    return $filter('date')(new Date(1970, 0, 1).setSeconds(value / 100), ((value / 100) > 60 * 60) ? 'hh:mm:ss' : 'mm:ss');
                },
                onChange: function() {
                    if ($scope.slider.min > 0 || $scope.slider.options.ceil !== $scope.slider.max) {
                        jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).removeClass('no-background');
                    }
                    else {
                        jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).addClass('no-background');
                    }
                }
            }
        };

        $scope.timeSlider = {
            value: 0,
            options: {
                floor: 0,
                ceil: 200,
                translate: function(value) {
                    return $filter('date')(new Date(1970, 0, 1).setSeconds(value / 100), ((value / 100) > 60 * 60) ? 'hh:mm:ss' : 'mm:ss');
                },
                onEnd: function(/* sliderId, modelValue, highValue, pointerType */){
                    $rootScope.youtubePlayer.playVideo();
                },
                onChange: function() {
                    $rootScope.youtubePlayer.seekTo($scope.timeSlider.value / 100);
                    $rootScope.youtubePlayer.pauseVideo();
                }
            }
        };
        $scope.volumeSlider = {
            value: 100,
            options: {
                floor: 0,
                ceil: 100,
                step: 5,
                hideLimitLabels: true,
                showSelectionBar: true,
                onChange: function() {
                    $rootScope.youtubePlayer.setVolume($scope.volumeSlider.value);
                }
            }
        };
        $scope.soundcloudSlider = {
            min: 0,
            max: 200,
            options: {
                floor: 0,
                ceil: 200,
                translate: function(value) {
                    return $filter('date')(new Date(1970, 0, 1).setSeconds(value / 100), ((value / 100) > 60 * 60) ? 'hh:mm:ss' : 'mm:ss');
                },
                onChange: function() {
                    if ($scope.soundcloudSlider.min > 0 ||
                          Math.floor($scope.soundcloudSlider.options.ceil) !== Math.floor($scope.soundcloudSlider.max)
                    ) {
                        jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).removeClass('no-background');
                    } else {
                        jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).addClass('no-background');
                    }
                }
            }
        };

        $scope.soundcloudTimeSlider = {
            value: 0,
            options: {
                floor: 0,
                ceil: 200,
                translate: function(value) {
                    return $filter('date')(new Date(1970, 0, 1).setSeconds(value / 100), ((value / 100) > 60 * 60) ? 'hh:mm:ss' : 'mm:ss');
                },
                onEnd: function(/* sliderId, modelValue, highValue, pointerType */){
                    $rootScope.soundcloudPlayer.play();
                },
                onChange: function() {
                    $rootScope.soundcloudPlayer.seekTo($scope.soundcloudTimeSlider.value * 10);
                    $rootScope.soundcloudPlayer.pause();
                }
            }
        };
        $scope.soundcloudVolumeSlider = {
            value: 100,
            options: {
                floor: 0,
                ceil: 100,
                step: 5,
                hideLimitLabels: true,
                showSelectionBar: true,
                onChange: function() {
                    $rootScope.soundcloudPlayer.setVolume($scope.soundcloudVolumeSlider.value / 100);
                }
            }
        };

        if (typeof $rootScope.youtubePlayer === 'undefined') {
            $rootScope.youtubePlayer = null;
        }
        if (typeof $rootScope.youtubePlayerIsPlaying === 'undefined') {
            $rootScope.youtubePlayerIsPlaying = false;
        }

        if (typeof $rootScope.soundcloudPlayer === 'undefined') {
            $rootScope.soundcloudPlayer = null;
        }
        if (typeof $rootScope.soundcloudPlayerIsPlaying === 'undefined') {
            $rootScope.soundcloudPlayerIsPlaying = false;
        }

        this.cancel = $scope.$dismiss;

        $scope.searchByLink = function() {
            var startTime = 0;
            var endTime = 0;
            $scope.data.phase = 'uploading';
            if ($rootScope.youtubePlayer) {
                $rootScope.youtubePlayer.pauseVideo();
                startTime = $scope.slider.min / 100;
                endTime = $scope.slider.max / 100;
            }
            if ($rootScope.soundcloudPlayer) {
                $rootScope.soundcloudPlayer.pause();
                startTime = $scope.soundcloudSlider.min / 100;
                endTime = $scope.soundcloudSlider.max / 100;
            }
            $scope.handleLinkSearch($scope.form.url, startTime, endTime, $scope.form.onlyInstrumental);
        };

        $scope.onClickPlayPause = function () {
            if ($rootScope.youtubePlayer.getPlayerState() === 2 ||
                $rootScope.youtubePlayer.getPlayerState() === 5
            ) {
                $rootScope.youtubePlayer.playVideo();
            } else {
                $rootScope.youtubePlayer.pauseVideo();
            }
            $scope.data.playerStatus = $rootScope.youtubePlayer.getPlayerState();
        };

        $scope.soundcloudOnClickPlayPause = function () {
            if ($scope.data.playerStatus) {
                $rootScope.soundcloudPlayer.play();
                $scope.data.playerStatus = false;
            } else {
                $rootScope.soundcloudPlayer.pause();
                $scope.data.playerStatus = false;
            }
        };

        $scope.changeYoutubePlayerState = function(){
            if ($rootScope.youtubePlayer) {
                $scope.data.playerStatus = $rootScope.youtubePlayer.getPlayerState();
            }
        };
        $scope.checkYoutubePlayerState = function(){
            if ($rootScope.youtubePlayer && typeof $rootScope.youtubePlayer.getPlayerState === 'function') {
                $scope.data.playerStatus = $rootScope.youtubePlayer.getPlayerState();

                if ($rootScope.youtubePlayer.getDuration() > 0 &&
                    !$scope.maxIsSet) {

                    var maxLength = 150000;

                    $scope.slider.options.ceil = $rootScope.youtubePlayer.getDuration() * 100;
                    $scope.slider.max = ($rootScope.youtubePlayer.getDuration() * 100) > maxLength ? maxLength : $rootScope.youtubePlayer.getDuration() * 100;
                    $scope.timeSlider.options.ceil = $rootScope.youtubePlayer.getDuration() * 100;
                    $scope.maxIsSet = true;
                    if($scope.slider.options.ceil > maxLength){
                        $scope.slider.min = 0;
                        $scope.slider.options.minRange = 1000;
                        $scope.slider.options.maxRange = maxLength;
                        $scope.slider.options.pushRange = true;
                    }
                }
                if ($rootScope.youtubePlayer.getDuration() > 0 && $rootScope.youtubePlayer.getPlayerState() !== 2 && $rootScope.youtubePlayer.getPlayerState() !== 3) {
                    //$scope.data.pinPosition = ($rootScope.youtubePlayer.getCurrentTime() / $rootScope.youtubePlayer.getDuration()) * 100;
                    $scope.timeSlider.value = $rootScope.youtubePlayer.getCurrentTime() * 100;
                }

            }
            if (!$scope.checkStop) {
                $timeout(function () {
                    $scope.checkYoutubePlayerState();
                }, 100);
            }
        };

        $scope.checkSoundcloudPlayerState = function(){
            if ($rootScope.soundcloudPlayer) {
                $rootScope.soundcloudPlayer.getDuration(function(duration){
                    if (duration > 0 &&
                        !$scope.soundcloudMaxIsSet) {
                        $scope.soundcloudSlider.options.ceil = duration / 10;
                        $scope.soundcloudSlider.max = duration / 10;
                        $scope.soundcloudTimeSlider.options.ceil = duration / 10;
                        $scope.soundcloudMaxIsSet = true;
                    }
                    if (duration > 0 && !$scope.data.playerStatus) {
                        $rootScope.soundcloudPlayer.getPosition(function(position){
                            $scope.soundcloudTimeSlider.value = position / 10;
                        });
                    }
                });

                $rootScope.soundcloudPlayer.isPaused(function(isPaused){
                    $scope.data.playerStatus = isPaused;
                });

            }
            if (!$scope.soundcloudCheckStop) {
                $timeout(function () {
                    $scope.checkSoundcloudPlayerState();
                }, 100);
            }
        };

        $scope.loadScript = function(scriptSrc) {
            return $q(function(resolve) {
                if (!document.querySelector('script[src="' + scriptSrc + '"]')) {
                    var tag = document.createElement('script');
                    // for non-Youtube, use the onload event handler
                    if (!scriptSrc.match(/youtube/)) {
                        tag.onload = resolve;
                    }
                    tag.src = scriptSrc;
                    document.head.appendChild(tag);

                    // for youtube, use the ready API
                    if (scriptSrc.match(/youtube/)) {
                        window.onYouTubeIframeAPIReady = function() { resolve(); };
                    }
                } else {
                    resolve();
                }
            });
        };

        $scope.handleUrl = function(url) {
            if (!$scope.isYoutubeLink(url)
                && !$scope.isSoundcloudLink(url)
            ) {
                $scope.data.errors = ['URL is not a valid Youtube or soundcloud URL'];
            } else if ($scope.isYoutubeLink(url)){
                var loadCallback = function() {
                    $scope.modalOptions.mode = 'loadingYoutubeLink';
                    $scope.modalOptions.youtubeId = $scope.getYoutubeId(url);
                    $rootScope.$emit('resetYoutubePlayer');

                    $timeout(function () {
                        document.getElementsByClassName('player-row')[0].style.opacity = 1;
                        jQuery(jQuery('.rz-bubble').get(10)).addClass('no-opacity');
                        jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).addClass('no-background');
                        $rootScope.youtubePlayerIsPlaying = false;
                        $rootScope.youtubePlayer = new YT.Player('youtubePlayer', {
                            height: '390',
                            width: '540',
                            videoId: $scope.getYoutubeId(url),
                            playerVars: {controls: 0},
                            events: {
                                'onStateChange': function (event) {
                                    $rootScope.youtubePlayerIsPlaying = !(event.target.getPlayerState() !== 1 && event.target.getPlayerState() !== 3);
                                    $rootScope.$emit('changeYoutubePlayerState');
                                },
                                'onReady': function (event) {
                                    event.target.playVideo();
                                    $timeout(function () {
                                        $rootScope.youtubePlayer.pauseVideo();
                                        $rootScope.youtubePlayer.seekTo(0);
                                        $scope.soundcloudTimeSlider.value = 0;
                                        jQuery(jQuery('.rz-pointer').get(6)).css('left', 0);
                                    }, 1000);
                                }
                            }
                        });
                    }, 0);
                };

                $scope.loadScript('https://www.youtube.com/iframe_api').then(loadCallback);
            }
            else if ($scope.isSoundcloudLink(url)){
                var loadCallback = function() {
                    $scope.modalOptions.mode = 'loadingSoundcloudLink';
                    $scope.modalOptions.soundcloudLink = url;
                    $rootScope.$emit('resetSoundcloudPlayer');
                    $scope.modalOptions.soundcloudLinkIframe = $sce.trustAsResourceUrl('https://w.soundcloud.com/player/?url=' + encodeURI(url) + '&auto_play=false&show_artwork=true&color=0066cc&sharing=false&download=false&show_comments=false&hide_related=true&callback=true');

                    setTimeout(function () {
                        $rootScope.soundcloudPlayer = SC.Widget('soundcloudPlayer');
                        jQuery(jQuery('.rz-bubble').get(10)).addClass('no-opacity');
                        jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).addClass('no-background');
                    }, 200);
                    setTimeout(function () {
                        document.getElementsByClassName('player-row')[0].style.opacity = 1;
                        jQuery(jQuery('.rz-bubble').get(10)).addClass('no-opacity');
                        jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).addClass('no-background');
                    }, 500);
                };

                $scope.loadScript('https://w.soundcloud.com/player/api.js').then(loadCallback);
            }
        };

        $scope.search = function () {
            if ($scope.form.file) {
                if ($scope.musicMatcherForm.file.$error.maxSize) {
                    $scope.data.errors = [ gettextCatalog.getString('Please select a file smaller then 30MB.')]
                } else {
                    // Handle file upload
                    $scope.handleFileUpload($scope.form.file, $scope.form.onlyInstrumental);
                }
            } else if ($scope.form.url) {
                // Show additional form for making a selection
                $scope.handleUrl($scope.form.url);
            } else {
                $scope.data.errors = [ gettextCatalog.getString('Enter a URL or upload a file.')]
            }
        };

        $scope.isYoutubeLink = function (url) {
            var regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var matchYoutube = url.match(regExpYoutube);
            return matchYoutube && matchYoutube[2].length === 11;
        };

        $scope.getYoutubeId = function (url) {
            var regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var matchYoutube = url.match(regExpYoutube);
            return matchYoutube[2];
        };

        $scope.isSoundcloudLink = function (url) {
            var regExpSoundcloud = /^.*?soundcloud\.com\/([\w\-.]+)\/([\w\-.]+)/;
            var matchSoundcloud = url.match(regExpSoundcloud);
            return matchSoundcloud && matchSoundcloud[2];
        };

        // upload on file select or drop
        $scope.handleLinkSearch = function (url, startTime, endTime, onlyInstrumental) {
            if (!url) {
                return false;
            }
            $scope.data.errors = null;
            $scope.data.phase = 'uploading';

            $http.post(Playlist.single('/search/by_link').$url(), {
                url: url,
                startTime: Math.round(startTime),
                endTime: Math.round(endTime),
                onlyInstrumental: onlyInstrumental
            }).then(function (result) {
                $scope.showSearchResults(result.data.resultId);
            }).catch(function (data) {
                $scope.data.errors = data && data.errors || [ gettextCatalog.getString('Upload failed.')];
                if (data.message !== undefined) {
                    $scope.data.errors = [data.message];
                }
                $scope.data.phase = 'start';
            });
        };

        // upload on file select or drop
        $scope.handleFileUpload = function (file, onlyInstrumental) {
            if (!file) {
                return false;
            }
            $scope.data.errors = null;
            $scope.data.phase = 'uploading';

            Upload.upload({
                url: Playlist.single('/search/upload').$url(),
                data: {
                    onlyInstrumental: onlyInstrumental,
                    file: file
                }
            }).then(function (result) {
                $scope.showSearchResults(result.data.resultId);
            }).catch(function (data /* , status, headers, config */) {
                $scope.data.errors = data && data.errors || [ gettextCatalog.getString('Upload failed.')];
                if (data.message !== undefined) {
                    $scope.data.errors = [data.message];
                }
                $scope.data.phase = 'start';
            });
        };

        $scope.showSearchResults = function(resultId) {
            $scope.data.errors = null;
            $scope.data.phase = 'success';

            // Switch to the search results page
            var urlParams = {
                scope: 'tracks',
                resultId: resultId
            };
            $state.go('root.search-results', urlParams, { inherit: false, reload: 'root.search-results' });

            // Close modal
            $scope.$close();
        };

        if ($rootScope.audioModalCloseInit !== true) {
            $rootScope.$on('audioModalClose', function () {
                $scope.checkStop = true;
                if ($rootScope.youtubePlayer) {
                    $rootScope.youtubePlayer.destroy();
                    $rootScope.youtubePlayer = null;
                }
                $scope.soundcloudCheckStop = true;
                if ($rootScope.soundcloudPlayer) {
                    $rootScope.soundcloudPlayer = null;
                }
            });
        }

        $rootScope.audioModalCloseInit = true;
        if ($rootScope.changeYoutubePlayerStateInit !== true) {
            $rootScope.$on('changeYoutubePlayerState', function () {
                $scope.checkStop = false;
                $scope.changeYoutubePlayerState();
            });
        }
        $rootScope.changeYoutubePlayerStateInit = true;

        if ($rootScope.resetYoutubePlayerInit !== true) {
            $rootScope.$on('resetYoutubePlayer', function () {
                $scope.slider.options.ceil = 200;
                $scope.slider.max = 200;
                $scope.timeSlider.options.ceil = 200;
                $scope.maxIsSet = false;
                $scope.timeSlider.value = 0;
                $rootScope.youtubePlayer = null;
            });
        }
        $rootScope.resetYoutubePlayerInit = true;

        if ($rootScope.resetSoundcloudPlayerInit !== true) {
            $rootScope.$on('resetSoundcloudPlayer', function () {
                $scope.soundcloudSlider.options.ceil = 200;
                $scope.soundcloudSlider.max = 200;
                $scope.soundcloudTimeSlider.options.ceil = 200;
                $scope.soundcloudMaxIsSet = false;
                $scope.soundcloudTimeSlider.value = 0;
                $rootScope.soundcloudPlayer = null;
                $scope.data.playerStatus = false;
            });
        }
        $rootScope.resetsoundcloudPlayerInit = true;

        // Jump to selection step when URL is supplied
        if (modalOptions.url) {
            $scope.handleUrl(modalOptions.url);
        }
    });
