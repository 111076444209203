'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Category', function (restmod, $sce, RemotePlayQueue, playService, $http, userService) {
        return restmod.model('/categories').mix('PagedModel', function() {
            this.setProperty('pageHeader', 'X-Pagination-Current-Page');
            this.setProperty('pageCountHeader', 'X-Pagination-Page-Count');
        }, {
            carriers: { hasMany: 'Carrier' },
            $hooks: {
                'after-feed': function () {
                    this.video = $sce.trustAsResourceUrl(this.video);
                    this.isSystemAdmin = userService.isSystemAdmin();
                }
            },
            $extend: {
                Record: {
                    play: function (options) {
                        options = angular.extend({ onlyWhenEmpty: false, startPaused : false }, options);

                        // Use the click event to play a silent MP3, to circumvent iOS restrictions
                        playService.playSilentFirst();

                        var queue = new RemotePlayQueue(this);
                        if (options.startAtTrack) {
                          queue.setPositionByTrack(options.startAtTrack);
                        }

                        queue.load().then(function(queue) {
                            playService.playQueue(queue, options);
                        });
                    },
                    generateTrackWaveforms: function()
                    {
                        return $http.post(this.$url() + '/generate-track-waveforms');
                    },

                    updateTrackDurations: function()
                    {
                        return $http.post(this.$url() + '/update-track-durations');
                    },

                    identifyVersions: function()
                    {
                        return $http.post(this.$url() + '/identify-versions').then(function(response) {
                            $window.location.reload();
                        });
                    },
                }
            }
        });
    });
