'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.userType
 * @description
 * # userType
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('userType', function () {
    // AngularJS will instantiate a singleton by calling "new" on this function
  });
