'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.trackEditModal
 * @description
 * # trackEditModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('trackEditModal', function($uibModal) {
      return function(_options) {
        var instance = $uibModal.open({
          animation: false,
          templateUrl: 'views/track-edit-modal.html',
          controller: 'TrackEditModalCtrl',
          controllerAs: 'TrackEditModalCtrl',
          windowClass: 'track-edit-modal',
          backdrop: 'static',
          resolve: {
            options : function() {
              return _options;
            }
          }
        });

        return instance.result;
      };
    });
