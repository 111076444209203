'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Account', function (restmod, $http) {
        return restmod.model('/account').mix('SharedModel', {
            contactFirstname: { map: 'contactFirstname' },
            contactLastname: { map: 'contactLastname' },
            passwordConfirm: { mask: 'CU' },
            company: { belongsTo: 'Company', key: 'companyID' },
            $extend: {
                Record: {
                    requestPasswordReset: function(data)
                    {
                        var apiUrl = this.$url() + '/request-password-reset';
                        return $http.post(apiUrl, data);
                    },
                    resetPassword: function(data)
                    {
                        var apiUrl = this.$url() + '/reset-password';
                        return $http.post(apiUrl, data);
                    },
                    checkUpdateLegacyUsername: function()
                    {
                        var apiUrl = this.$url() + '/check-update-legacy-username';
                        return $http.get(apiUrl);
                    },
                    updateLegacyUsername: function()
                    {
                        var apiUrl = this.$url() + '/update-legacy-username';
                        return $http.post(apiUrl);
                    },
                    validate: function(data)
                    {
                        var apiUrl = this.$url() + '/validate';
                        return $http.post(apiUrl, data);
                    },
                    cancel: function(data)
                    {
                        var apiUrl = this.$url() + '/cancel';
                        return $http.post(apiUrl, data);
                    },
                    logoutAllSessions: function(data)
                    {
                        var apiUrl = this.$url() + '/clear-session';
                        return $http.post(apiUrl, data);
                    }
                }
            }
        });
    });
