'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('SiteNews', function (restmod) {
        return restmod.model().mix('PagedModel', function() {
            this.setProperty('pageHeader', 'X-Pagination-Current-Page');
            this.setProperty('pageCountHeader', 'X-Pagination-Page-Count');
        }, {
            site: { hasOne: 'Site'},
            category: { hasOne: 'SiteNewsCategory' },
            carrier: { hasOne: 'Carrier' },
            catalogue: { hasOne: 'Label' },
            $hooks: {
                'after-feed' : function() {
                    switch (this.type)
                    {
                        case 'carrier': this.$action = 'Listen!'; break;
                        case 'catalogue': this.$action = 'View!'; break;
                        case 'video': this.$action = 'Watch!'; break;
                    }

                    this.$backgroundImage = this.thumbnails['400x400'] ? 'url(' + this.thumbnails['400x400'] + ')' : null;
                }
            },
            $config: {
                name: 'siteNews',
                plural: 'siteNews'
            }
        });
    });
