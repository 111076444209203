'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:hideOnMobile
 * @description
 * # hideOnMobile
 */
angular.module('musicdirectorApp')
  .directive('hideOnMobile', function () {
    // Very simple mobile detection, not 100% reliable
    var isMobileDevice = function () {
      return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

    return {
      restrict: 'A',
      link: function postLink(scope, element) {
        if (isMobileDevice()) {
          element.hide();
        }
      }
    };
  });
