'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:ownEventOnly
 * @description
 * # ownEventOnly
 */
angular.module('musicdirectorApp')
  .directive('ownEventOnly', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                var handler = function (e) {
                    if (e.target !== e.currentTarget)
                    {
                        e.preventDefault();
                    }
                };

                element.bind(attr.ownEventOnly, handler);

                scope.$on('$destroy', function () {
                    element.unbind(attr.ownEventOnly, handler);
                });
            }
        };
    });
