'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:olark
 * @description
 * # olark
 */
angular.module('musicdirectorApp')
  .directive('olark', function ($window, $log, gettextCatalog, languageService, userService, PubSub) {
    var isVisible = false;

    return {
      templateUrl: 'views/chat.html',
      restrict: 'E',
      replace: true,
      controller: function($scope) {
        if (angular.isUndefined($window.olark))
        {
            $log.warn('Olark library not loaded (olark not defined)');
            return;
        }
        if (!$scope.currentSite.chat.enabled ||
            $scope.currentSite.chat.provider !== 'olark'
        ) {
            $log.warn('Chat is disabled or Olark is not the chat provider.');
            return;
        }

        olark.configure('box.start_hidden', true);
        olark.configure('system.is_single_page_application', true);

        olark.identify($scope.currentSite.chat.account);

        olark('api.box.onExpand', function() {
            isVisible = true;
        });
        olark('api.box.onShrink', function() {
            olark('api.box.hide');
            isVisible = false;
        });
        olark('api.box.onHide', function() {
            isVisible = false;
        });

        // var syncUserData = function() {
        //     userService.getAccount().then(function (account) {
        //         if (account !== null) {
        //             olark('api.visitor.updateFullName', { fullName: account.name });
        //             olark('api.visitor.updateEmailAddress', { emailAddress: account.email });
        //         } else {
        //             olark('api.visitor.updateFullName', { fullName: ' ' });
        //             olark('api.visitor.updateEmailAddress', { emailAddress: 'user@example.org' });
        //         }
        //     });
        // };
        // syncUserData();
        //
        // PubSub.subscribe('userService.onCurrentUserChange', function (/* event, tokenData */) {
        //   syncUserData();
        // });
      },

      link: function postLink(scope, element) {
        // Show Olark on click on chat button
        element.click(function (event) {
          event.preventDefault();
          if (element.hasClass('disabled') === false) {
            if (isVisible) {
              olark('api.box.hide');
            } else {
              olark('api.box.expand');
            }
          }
        });
      }
    };
  });
