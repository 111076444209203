'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.stampPromoModal
 * @description
 * # stampPromoModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('usernameChangeModal', function ($uibModal, $state, userService, usernameChangeService) {
    return function(_options) {
      var currentState = $state.current;

      var instance = $uibModal.open({
        animation: false,
        backdrop: 'static',
        templateUrl: '../../views/username-change-modal.html',
        controller: 'UsernameChangeModalCtrl',
        controllerAs: 'UsernameChangeModalCtrl',
        windowClass: 'username-change-modal',
        resolve : {
          modalOptions : function() {
            return angular.extend({ message : '' }, _options);
          },
          isChangeRequired: function() {
              return usernameChangeService.getIsChangeRequired();
          },
          account: function() {
            return userService.getAccount()
          }
        }
      });

      return instance.result.finally(function () {
        if (currentState.name.length === 0) {
          $state.go('root.index');
        } else {
          $state.go(currentState);
        }
      });
    };
  });
