'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.playlistEditorModal
 * @description
 * # playlistEditorModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('playlistEditorModal', function($uibModal, Playlist) {
        return function(_options) {
            var instance = $uibModal.open({
                animation: false,
                templateUrl: 'views/playlist-editor-modal.html',
                controller: 'PlaylistEditorModalCtrl',
                controllerAs: 'PlaylistEditorModalCtrl',
                windowClass: 'playlist-editor-modal',
                backdrop: 'static',
                resolve: {
                    options : function() {
                        return _options;
                    },
                    editors: function () {
                        return Playlist.$new(_options.model.$pk).getEditors();
                    }
                }
            });

            return instance.result;
        };
    });
