'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.languageInterceptorService
 * @description
 * # languageInterceptorService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('languageInterceptorService', function (PubSub) {
        var languageHeader = 'en-US';

        function request(config) {
            if (languageHeader) {
                config.headers['Accept-Language'] = languageHeader;
            }
            return config;
        }

        function setLanguage(language)
        {
            if (language !== 'en')
            {
                languageHeader = language + '-' + language.toUpperCase() + ';q=0.8,en-US;q=0.6';
            }
            else {
                languageHeader = 'en-US';
            }
            return languageHeader;
        }

        // Watch for login changes
        PubSub.subscribe('languageService.onLanguageChange', function ( data) {
            if (data !== null &&
                data.language)
            {
                setLanguage(data.language);
            }
        });

        return {
            request: request,
            get language() { return false; },
            set language(value) {
                return setLanguage(value);
            }
        };
    });
