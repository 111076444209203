'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.loginModal
 * @description
 * # loginModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('playlistExportModal', function($uibModal) {
        return function() {
            var instance = $uibModal.open({
                animation: false,
                templateUrl: 'views/playlist-export-modal.html',
                controller: 'PlaylistExportModalCtrl',
                controllerAs: 'PlaylistExportModalCtrl',
                windowClass: 'playlist-export-modal'
            });

            return instance.result;
        };
    });
