'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:genre
 * @description
 * # genre
 */
angular.module('musicdirectorApp')
  .directive('genre', function () {
    return {
      templateUrl: 'views/genre-item.html',
      scope: {
        genre: '=item',
      },
      restrict: 'E',
      link: function(scope, element) {
        var linkElement = element.find('a');

        scope.data = {
          videoLoaded: false
        };

        var mouseEnterHandler = function() {
          scope.$apply(function() {
            scope.data.videoLoaded = true;
          });
          linkElement.addClass('mouse-over');

          var videoElement = element.find('video');
          if (videoElement[0]) {
            videoElement[0].play();
          }
        };
        var mouseLeaveHandler = function() {
          // Pause and jump to start
          linkElement.removeClass('mouse-over');

          var videoElement = element.find('video');
          if (videoElement[0]) {
            videoElement[0].pause();
          }
          scope.$apply(function() {
            scope.data.videoLoaded = false;
          });
        };

        if (linkElement.length)
        {
          linkElement.on('mouseenter', mouseEnterHandler)
              .on('mouseleave', mouseLeaveHandler);
        }

        scope.$on('$destroy', function() {
          linkElement.off('mouseenter', mouseEnterHandler)
              .off('mouseleave', mouseLeaveHandler);
        });
      }
    };
  });
