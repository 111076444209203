'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:track
 * @description
 * # track
 */
angular.module('musicdirectorApp')
    .directive('mainMenu', function () {
        return {
            restrict: 'E',
            replace: true,
            template: '<div ng-include="templateUrl"></div>',
            controller: 'MainMenuCtrl',
            link: function (scope) {
                scope.templateUrl = scope.getTemplateUrl();
            }
        };
    });

