'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.loginModal
 * @description
 * # loginModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('loginModal', function($rootScope, $uibModal, userService, gettextCatalog) {
        function assignCurrentUser (tokenData) {
            userService.setCurrentUser(tokenData);
            return tokenData;
        }

        return function(_options) {
            var instance = $uibModal.open({
                backdrop: 'static',
                animation: false,
                keyboard: _options?.showDismissButton,
                templateUrl: '../../views/login-modal.html',
                controller: 'LoginModalCtrl',
                controllerAs: 'LoginModalCtrl',
                windowClass: 'login-modal',
                resolve : {
                    modalOptions : function() {
                        var message = gettextCatalog.getString('Login with your account');
                        if ($rootScope.currentSite &&
                            $rootScope.currentSite.shortName
                        ) {
                            message = gettextCatalog.getString('Login with your {{siteName}} account', { siteName: $rootScope.currentSite.shortName });
                        }

                        return angular.extend({ message: message, showDismissButton: true, allowRegister: true }, _options);
                    }
                }
            });

            return instance.result.then(assignCurrentUser);
        };
    });
