'use strict';

/**
 * @ngdoc filter
 * @name musicdirectorApp.filter:filenamize
 * @function
 * @description
 * # filenamize
 * Filter in the musicdirectorApp.
 */
angular.module('musicdirectorApp.filters')
    .filter('filenamize', function () {
    return function (input) {
      return input.replace(/[\s\/]/g, '_').replace(/[\.,]/g, '').replace(/[_]{2,}/g, '_');
    };
  });
