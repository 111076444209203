/**
 * @ngdoc overview
 * @name musicdirectorApp
 * @description
 * # musicdirectorApp
 *
 * Main module of the application.
 */
"use strict"
angular
    .module('musicdirectorApp.filters', [
        'ngLodash'
    ]);

angular
    .module('musicdirectorApp', [
        'musicdirector.config',
        'musicdirectorApp.filters',
        'ngCookies',
        'ui.router',
        'ui.router.stateHelper',
        'ngSanitize',
        'ngFacebook',
        'angular-oauth2',
        'restmod',
        'PubSub',
        'ui.bootstrap',
        'gettext',
        'ngLodash',
        'infinite-scroll',
        'ui.bootstrap.showErrors',
        'dibari.angular-ellipsis',
        'com.2fdevs.videogular',
        'com.2fdevs.videogular.plugins.controls',
        'nl.websight.videogular.plugins.scrubandselect',
        'socialLinks',
        'noCAPTCHA',
        'angular-loading-bar',
        'validation.match',
        'rt.debounce',
        'LocalStorageModule',
        'angular-growl',
        'ngFileUpload',
        'ngTagsInput',
        'ngDragDrop',
        'dndLists',
        'angular-google-analytics',
        'ng.shims.placeholder',
        'rzModule',
        'duScroll',
        'angularViewportWatch',
        'ngCacheBuster',
        'angular-bind-html-compile',
        'ngFileSaver',
        'slug',
        'pretty-checkable',
        'http-auth-interceptor',
        'angular-cookie-law',
        'tmh.dynamicLocale'
    ])

	// translations for the datepicker on the download history page
    .config(function(tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('https://code.angularjs.org/1.2.20/i18n/angular-locale_{{locale}}.js');
    })

    .config(function($compileProvider) {
        $compileProvider.aHrefSanitizationTrustedUrlList(/^\s*(https?|ftp|mailto|tel):/);
    })

    .config(function(OAuthProvider, OAuthTokenProvider, configuration) {
        OAuthProvider.configure({
            baseUrl: configuration.oAuthBaseUrl,
            clientId: 'webapp'
        });

        var expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);

        OAuthTokenProvider.configure({
            name: 'token',
            options: {
                secure: true,
                path: '/',
                expires: expireDate,
                samesite: 'lax',
            }
        });
    })

    .config(function($facebookProvider) {
        $facebookProvider.setAppId('1620816518150663');
    })

    .config(['restmodProvider', 'configuration', function(restmodProvider, configuration) {
        restmodProvider.rebase({
            $config: {
                urlPrefix: configuration.apiUrlPrefix
            }
        }, 'apiMixin');
    }])

    .config(['growlProvider', function(growlProvider) {
        growlProvider.onlyUniqueMessages(true);
        growlProvider.globalTimeToLive(5000);
        growlProvider.globalDisableCountDown(true);
        growlProvider.globalPosition('bottom-right');
    }])

    .config(['noCAPTCHAProvider', function (noCaptchaProvider) {
        noCaptchaProvider.setSiteKey('6LccNgYTAAAAAORmoqlc7Il_qtKVP01RDwPpornd');
        noCaptchaProvider.setTheme('dark');
    }])

    .config(['$httpProvider', function($httpProvider) {
        //Enable cross domain calls
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.withCredentials = true;
        $httpProvider.interceptors.push('siteInterceptorService');
        $httpProvider.interceptors.push('bearerInterceptorService');
        $httpProvider.interceptors.push('languageInterceptorService');
    }])

    .config(function(httpRequestInterceptorCacheBusterProvider){
      httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*(api|views|languages).*/], true);
    })

    .config(function($locationProvider) {
        $locationProvider.html5Mode(true).hashPrefix('!');
    })

    .config(function($uibTooltipProvider) {
        $uibTooltipProvider.options({ appendToBody : true, popupDelay : 500 });
    })

    .config(function(seoDataProvider) {
        seoDataProvider.setDefaultPageTitle('BMG Production Music | you need great music')
            .setDefaultMetaDescription('Production Music Libraries')
            .setPageTitleSuffix('')
            .setDefaultPageImage('https://images.bmgproductionmusic.nl/sites/logos/1.png')
            .setNoSuffixWhenTitleIs(['BMG Production Music NL | you need great music', 'MusicDIRECTOR NL | you need great music']);
    })

    .config(function($urlRouterProvider, stateHelperProvider, seoDataProvider) {
        $urlRouterProvider.otherwise('/');

        stateHelperProvider.state({
            name: 'root',
            resolve: {
                currentSite: function($state, $window, $q, $location, $rootScope, Site, languageService, infoModal, gettextCatalog,
                                      localStorageService, authStore, userService, OAuthToken, PubSub,
                                      languageInterceptorService, appAuthService) {
                    // use default language from local storage
                    var defaultLanguage = localStorageService.get('language');

                    // overwrite with language query parameter
                    var queryLanguage = $location.search().language;
                    if (typeof queryLanguage !== 'undefined' &&
                      queryLanguage.match(/^[a-z\-]{2,}$/i) !== null
                    ) {
                        defaultLanguage = $location.search().language;
                    }

                    var handlePossiblePublicSite = function (site) {
                        if (!site.isPublic && !$rootScope.isAuthenticated) {
                            return appAuthService.requireLogin({
                                modal: {
                                    message: gettextCatalog.getString('Please login to access this application.'),
                                    showDismissButton: false,
                                    allowRegister: false,
                                }
                            }).then(function () {
                                return handleFoundSite(site);
                            });
                        } else {
                            return handleFoundSite(site);
                        }
                    };

                    var handleFoundSite = function(site) {
                        site.$pk = site.ID;
                        $rootScope.currentSite = site;

                        // Change SEO title
                        var title = site.title + ' | you need great music';
                        seoDataProvider.setDefaultPageTitle(title);
                        seoDataProvider.setPageTitleSuffix(' - ' + title);
                        seoDataProvider.setNoSuffixWhenTitleIs([title]);

                        // Setup available languages
                        languageService.setAvailableLanguages(site.availableLanguages);

                        // Initialize default language
                        if (defaultLanguage === null ||
                            site.availableLanguages.indexOf(defaultLanguage) === -1) {
                            defaultLanguage = site.defaultLanguage;
                        }

                        // directly assign default language, to fix initial translation errors
                        languageInterceptorService.language = defaultLanguage;
                        languageService.setCurrentLanguage(site, defaultLanguage);

                        PubSub.publish('site:loaded', site);

                        return site;
                    };

                    // Check authentication first
                    var authPromise = $q(function(resolve) {
                        if (!$rootScope.isAuthenticated) {
                            // Check remote auth storage, for cross-domain authentication
                            authStore.data.then(function (data) {
                                if (data !== null) {
                                    OAuthToken.setToken(data);
                                    userService.setCurrentUser(data);
                                }
                                resolve();
                            }, function() {
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    });

                    // Get current site after checking authentication status
                    return authPromise.then(function() {
                        userService.updateAnalytics();

                        var promise = Site.$find('by_domain', {
                            domain: $window.location.host,
                            language: defaultLanguage
                        }).$asPromise();

                        return promise.then(handlePossiblePublicSite, function (result) {
                            switch (result.$response.status)
                            {
                                case 403:
                                    // Logout current user
                                    userService.logout();

                                    // login for site
                                    return appAuthService.requireLogin({ modal : {
                                        message : gettextCatalog.getString('Please login to access this application.'),
                                        showDismissButton: false,
                                        allowRegister: false,
                                    }}).then(function() {
                                        // Retry site
                                        var retryPromise = Site.$find('by_domain', {
                                            domain: $window.location.host,
                                            language: defaultLanguage
                                        }).$asPromise();

                                        // If still a failure, show error message
                                        return retryPromise.then(handleFoundSite, function () {
                                            infoModal({
                                                title: gettextCatalog.getString('Unable to access site'),
                                                html: '<p>' + gettextCatalog.getString('You do not have permission to access this site.') + '</p>',
                                                closeButtonText: null
                                            });

                                            return $q.reject();
                                        });
                                    });

                                case 404:
                                    infoModal({
                                        title: gettextCatalog.getString('Site not found'),
                                        html: '<p>' + gettextCatalog.getString('This domain is not linked to an active site.') + '</p>',
                                        closeButtonText: null
                                    });

                                    return $q.reject();

                                default:
                                    infoModal({
                                        title: gettextCatalog.getString('Temporary problem'),
                                        html: '<p>' + gettextCatalog.getString('We are experiencing a temporary problem. Please try again later.') + '</p>',
                                        closeButtonText: null
                                    });

                                    return $q.reject();

                            }
                        });
                    });
                }
            },
            data: {
                requireLogin: false
            },
            views : {
                'navbar' : {
                    templateUrl : 'views/_navbar.html',
                    controller: 'NavbarCtrl'
                },
                'headerbar' : {
                    template: '<div ng-include="getTemplateUrl(\'_headerbar\')"></div>',
                    controller : 'HeaderBarCtrl'
                },
                'mainnav' : {
                    template: '<div ng-include="getTemplateUrl(\'_mainnav\')"></div>',
                    controller : 'HeaderBarCtrl'
                },
                'need-help' : {
                    templateUrl : 'views/_need_help.html'
                },
                'player' : {
                    templateUrl : 'views/_player.html',
                    controller: 'PlayerCtrl'
                },
                'header' : {},
                'content' : {},
                'footer' : {
                    templateUrl : 'views/_footer.html'
                }
            },
            abstract: true,
            children: [
                {
                    name: 'index',
                    url: '/',
                    views : {
                        'header@': {
                            templateUrl: 'views/index.header.html'
                        },
                        'content@' : {
                            template: '<div ng-include="getTemplateUrl(\'index\')"></div>',
                            controller: 'HomeCtrl'
                        }
                    },
                    children: [
                        {
                            name: 'extra',
                            url: 'extra',
                            children: [
                                {
                                    name: 'promo',
                                    url: '/promo',
                                    onEnter: function (stampPromoModal, $state) {
                                        stampPromoModal().then(function () {
                                            $state.go('root.index');
                                        });
                                    },
                                    children: [
                                        {
                                            name: 'next-round',
                                            url: '/next-round',
                                            onEnter: function ($state, StampService, stampPromoModal) {
                                                return StampService.nextRound().then(function() {
                                                    stampPromoModal().then(function () {
                                                        $state.go('root.index');
                                                    });
                                                });
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            name: 'account',
                            url: 'account',
                            children: [
                                {
                                    name: 'validate',
                                    url: '/validate?id&token',
                                    params: {
                                        id: '',
                                        token: ''
                                    },
                                    onEnter: function($stateParams, $state, accountValidationModal) {
                                        accountValidationModal({
                                            token: $stateParams.token,
                                            id: $stateParams.id
                                        }).then(function() {
                                            $state.go('root.index');
                                        });
                                    }
                                },
                                {
                                    name: 'reset-password',
                                    url: '/reset-password?token',
                                    params: {
                                        id: '',
                                        token: ''
                                    },
                                    data: {
                                        requireLogin: false,
                                    },
                                    onEnter: function($stateParams, $state, passwordResetModal) {
                                        passwordResetModal({
                                            token: $stateParams.token
                                        }).then(function() {
                                            $state.go('root.index');
                                        });
                                    }
                                },
                                {
                                    name: 'forgot-password',
                                    url: '/forgot-password',
                                    onEnter: function ($state, forgotPasswordModal) {
                                        forgotPasswordModal().then(function() {
                                            $state.go('root.index');
                                        }, function() {
                                            $state.go('root.index');
                                        });
                                    }
                                },
                                {
                                    name: 'login',
                                    url: '/login?username&password',
                                    params: {
                                        username: '',
                                        password: ''
                                    },
                                    onEnter: function($rootScope, $stateParams, $state, userService, infoModal, gettextCatalog, OAuth, loginModal) {
                                        // Try an automatic login when username and password are given
                                        if ($stateParams.username &&
                                            $stateParams.password
                                        ) {
                                            var loginData = {
                                                username: $stateParams.username,
                                                password: $stateParams.password
                                            };

                                            return OAuth.getAccessToken(loginData).then(function(response) {
                                                userService.setCurrentUser(response.data);

                                                // Redirect to homepage
                                                $state.go('root.index');
                                            }, function() {
                                                infoModal({
                                                    title: gettextCatalog.getString('Login failed'),
                                                    html: '<p>' + gettextCatalog.getString('We could not log you in with the supplied credentials.') + '</p>'
                                                }).then(function() {
                                                    // Redirect to homepage
                                                    $state.go('root.index');
                                                });
                                            });
                                        } else {
                                            // Only show login when we are not already logged in
                                            if (!$rootScope.isAuthenticated) {
                                                // Show the normal login modal
                                                return loginModal().finally(function() {
                                                    $state.go('root.index');
                                                });
                                            } else {
                                                $state.go('root.index');
                                            }
                                        }
                                    }
                                },
                                {
                                    name: 'register',
                                    url: '/register',
                                    onEnter: function($rootScope, $state, $cookies, registrationModal, registrationBundeswehrModal) {
                                        if (!$rootScope.isAuthenticated) {
                                            if ($cookies.bundeswehr) {
                                                return registrationBundeswehrModal().finally(function () {
                                                    $state.go('root.index');
                                                });
                                            } else {
                                                return registrationModal().finally(function () {
                                                    $state.go('root.index');
                                                });
                                            }
                                        } else {
                                            $state.go('root.index');
                                        }
                                    }
                                },
                                {
                                    name: 'settings',
                                    url: '/settings',
                                    data: {
                                        requireLogin: true
                                    },
                                    views: {
                                        'content@': {
                                            controller: 'AccountSettingsCtrl',
                                            templateUrl: 'views/account-settings.html'
                                        }
                                    }
                                },
                                {
                                    name: 'cancel',
                                    url: '/cancel',
                                    data: {
                                        requireLogin: true
                                    },
                                }
                            ]
                        }
                    ]
                },
                {
                    name: 'all-albums',
                    url: '/all-albums',
                    views : {
                        'content@' : {
                            templateUrl: 'views/bmg/all-albums.html',
                            controller: 'HomeCtrl'
                        }
                    }
                },
                {
                    name: 'not-found',
                    url: '/404',
                    views : {
                        'content@' : {
                            templateUrl: 'views/404.html',
                            controller: 'NotFoundCtrl'
                        }
                    }
                },
                {
                    name: 'genre',
                    url: '/genres',
                    resolve: {
                        categories: function (currentSite) {
                            return currentSite.categories.$search({ 'per-page' : 48, expand : 'thumbnails' }).$asPromise();
                        }
                    },
                    views: {
                        'content@': {
                            templateUrl: 'views/genre.html',
                            controller: 'GenreCtrl'
                        }
                    },
                    children: [{
                      name: 'detail',
                      url: '/{genreId:int}?sort&sortSeed',
                      reloadOnSearch: false,
                      params: {
                        sort: 'newest',
                        sortSeed: null
                      },
                      resolve: {
                        category: function ($stateParams, Category, currentSite, languageService) {
                          return Category.$find($stateParams.genreId, {language: languageService.getCurrentLanguage(), expand: 'thumbnails'}).$asPromise().then(function(category) {
                            return category.carriers.$fetch({ sort: $stateParams.sort, 'sort-seed': $stateParams.sortSeed, expand: 'thumbnails', 'per-page' : 42 }).$asPromise().then(function(/* carriers */) {
                              return category;
                            });
                          });
                        }
                      },
                      views: {
                        'content@': {
                          templateUrl: 'views/genre.detail.html',
                          controller: 'GenreDetailCtrl'
                        }
                      }
                    }]
                },
                {
                    name: 'catalogue',
                    url: '/labels?letter&category',
                    params: {
                      letter: null,
                      category: null
                    },
                    resolve: {
                        labels: function($stateParams, currentSite) {
                            return currentSite.labels
                              .$search({ 'per-page' : 48, 'first-letter' : $stateParams.letter, 'active-category' : $stateParams.category })
                              .$asPromise();
                        },
                        categories: function (currentSite, $q) {
                            var deferred = $q.defer();
                            var loadMore = function() {
                              if (this.$pageCount > this.$page) {
                                return currentSite.categories.$fetch({ 'per-page' : 100, page: this.$page + 1 }).$then(loadMore);
                              }
                              deferred.resolve(this);
                            };

                            // load initial set
                            currentSite.categories.$reset();
                            currentSite.categories.$fetch({ 'per-page' : 100 }).$then(loadMore, function() {
                              deferred.reject();
                            });

                            return deferred.promise;
                        }
                    },
                    views : {
                        'content@': {
                            templateUrl: 'views/catalogue.html',
                            controller: 'CatalogueCtrl'
                        }
                    },
                    children: [
                        {
                            name: 'detail',
                            url: '/{catalogueId:int}?sort&sortSeed',
                            reloadOnSearch: false,
                            params: {
                                sort: 'newest',
                                sortSeed: null
                            },
                            resolve: {
                                label: function ($stateParams, $q, currentSite, Label, infoModal, gettextCatalog, languageService) {
                                    var deferred = $q.defer();

                                    Label.$find($stateParams.catalogueId, {language: languageService.getCurrentLanguage(), expand: 'thumbnails,rating,randomRelatedLabels'}).$asPromise().then(function(label) {
                                        return label.carriers.$fetch({ sort: $stateParams.sort, 'sort-seed': $stateParams.sortSeed, expand: 'thumbnails', 'per-page' : 42 }).$asPromise().then(function() {
                                            deferred.resolve(label);
                                        });
                                    }, function() {
                                        deferred.reject();
                                        infoModal({
                                            title: gettextCatalog.getString('Catalogue not available'),
                                            html: '<p>' + gettextCatalog.getString('This catalogue could not be opened. Please check the URL and your login state and try again.') + '</p>'
                                        });
                                    });

                                    return deferred.promise;
                                }
                            },
                            views: {
                                'content@': {
                                    templateUrl: 'views/catalogue.detail.html',
                                    controller: 'CatalogueDetailCtrl'
                                }
                            }
                        }
                    ]
                },
                {
                    name: 'recently-played',
                    url: '/recently-played',
                    resolve: {
                        tracks: function(RecentlyPlayed, currentSite) {
                            return RecentlyPlayed.$search({ site_id : currentSite.$pk, expand: 'track,carrier,label' }).$asPromise();
                        },
                        downloads: function(RecentlyDownloaded, currentSite) {
                            return RecentlyDownloaded.$search({ site_id : currentSite.$pk, expand: 'track,carrier,label', page: 1, perPage: 10}).$asPromise();
                        }
                    },
                    data: {
                        requireLogin: true
                    },
                    views : {
                        'content@': {
                            templateUrl: 'views/recently-played.html',
                            controller: 'RecentlyPlayedCtrl'
                        }
                    }
                },
                {
                    name: 'playlists.detail',
                    url: '/playlists/{playlistId:int}',
                    data: {
                        requireLogin: false
                    },
                    resolve: {
                        playlist: function ($stateParams, $state, $q, Playlist, infoModal, gettextCatalog, currentSite /*jshint unused:false*/) {
                            var deferred = $q.defer();

                            Playlist.$find($stateParams.playlistId).$asPromise().then(function(playlist) {
                                deferred.resolve(playlist);
                            }, function() {
                                deferred.reject();
                                infoModal({
                                    title: gettextCatalog.getString('Playlist not available'),
                                    html: '<p>' + gettextCatalog.getString('This playlist could not be loaded. Please check the URL and your login state and try again.') + '</p>'
                                }).finally(function() {
                                    $state.go('root.index');
                                });
                            });

                            return deferred.promise;
                        },
                        track: function(){ return null;}
                    },
                    views: {
                        'content@': {
                            templateUrl: 'views/playlists.detail.html',
                            controller: 'PlaylistDetailCtrl'
                        }
                    }
                },
                {
                    name: 'playlists.shared',
                    url: '/playlists/{secret:string}',
                    data: {
                        requireLogin: false
                    },
                    resolve: {
                        playlist: function ($stateParams, $state, $q, Playlist, infoModal, gettextCatalog, currentSite /*jshint unused:false*/) {
                            var deferred = $q.defer();

                            Playlist.$getBySecret($stateParams.secret).then(function(playlist) {
                                deferred.resolve(playlist);
                            }, function() {
                                deferred.reject();
                                infoModal({
                                    title: gettextCatalog.getString('Playlist not available'),
                                    html: '<p>' + gettextCatalog.getString('This playlist could not be loaded. Please check the URL and your login state and try again.') + '</p>'
                                }).finally(function() {
                                    $state.go('root.index');
                                });
                            });

                            return deferred.promise;
                        },
                        track: function(){ return null;}
                    },
                    views: {
                        'content@': {
                            templateUrl: 'views/playlists.detail.html',
                            controller: 'PlaylistDetailCtrl'
                        }
                    },
                    children: [{
                        name: 'track',
                        url: '/track/{trackId:int}',
                        resolve: {
                            track: function(Track, $stateParams, currentSite /*jshint unused:false*/) {
                                return Track.$find($stateParams.trackId,{expand: 'carrier,thumbnails'}).$asPromise();
                            },
                        },
                        views: {
                            'content@': {
                                controller: 'PlaylistDetailCtrl',
                                templateUrl: 'views/playlists.detail.html'
                            }
                        }
                    }]
                },
                {
                    name: 'playlists',
                    url: '/my-music',
                    data: {
                        requireLogin: true
                    },
                    views : {
                        'content@': {
                            templateUrl: 'views/playlists.html',
                            controller: 'PlaylistsCtrl'
                        }
                    }
                },
                {
                    name: 'carrier',
                    url: '/carrier/{carrierId:int}',
                    resolve: {
                        carrier: function($stateParams, $q, Carrier, infoModal, gettextCatalog, currentSite /*jshint unused:false*/) {
                            var deferred = $q.defer();

                            Carrier.$find($stateParams.carrierId, {expand: 'categories,label,thumbnails,tracks' + (currentSite.$pk === 21 ? ',track.artistsNames,track.extra' : '')}).$asPromise().then(function(carrier) {
                                deferred.resolve(carrier);
                            }, function() {
                                deferred.reject();
                                infoModal({
                                    title: gettextCatalog.getString('Album not available'),
                                    html: '<p>' + gettextCatalog.getString('This album could not be loaded.') + '</p>'
                                });
                            });

                            return deferred.promise;
                        },
                        track: function() { return null; }
                    },
                    views : {
                        'content@' : {
                            templateUrl: 'views/carrier.detail.html',
                            controller: 'CarrierCtrl'
                        }
                    }
                },
                {
                    name: 'track',
                    url: '/track/{trackId:int}?select',
                    reloadOnSearch: false,
                    params: {
                        select: null
                    },
                    resolve: {
                        track: function(Track, $stateParams, currentSite /*jshint unused:false*/) {
                            return Track.$find($stateParams.trackId, {expand: 'carrier,thumbnails'}).$asPromise();
                        },
                        carrier: function(track, Carrier, currentSite /*jshint unused:false*/) {
                            return Carrier.$find(track.carrier.$pk, {expand: 'categories,label,thumbnails,tracks' + (currentSite.$pk === 21 ? ',track.artistsNames' : '')}).$asPromise();
                        }
                    },
                    views : {
                        'content@' : {
                            templateUrl: 'views/carrier.detail.html',
                            controller: 'CarrierCtrl'
                        },
                        'player' : {
                            templateUrl : 'views/_player.html',
                            controller: 'PlayerCtrl'
                        }
                    }
                },
                {
                    name: 'search-results',
                    url: '/search/results/{scope:string}?query&subQuery&fields&genre&catalogue&duration&releaseyear&bpm&rating&sort&page&referenceId&resultId&startTime&endTime',
                    reloadOnSearch: false,
                    params: {
                        scope: 'tracks',
                        query: '',
                        fields: null,
                        genre: null,
                        catalogue: null,
                        duration: null,
                        releaseyear: null,
                        bpm: null,
                        rating: null,
                        sort: 'best-match',
                        page: '1',
                        referenceId: '',
                        resultId: '',
                        startTime: null,
                        endTime: null,
                        subQuery: '',
                        logSearch: false
                    },
                    views : {
                        'content@' : {
                            templateUrl: 'views/search-results.html',
                            controller: 'SearchResultsCtrl'
                        }
                    }
                },
                {
                    name: 'pages',
                    url: '/pages/{language}/{url}',
                    params: {
                        url: '',
                        language: ''
                    },
                    resolve: {
                        page: function(Page, $location, $stateParams, currentSite, languageService, languageInterceptorService) {
                            var language = $stateParams.language;

                            // Only set language if there is a language given
                            if (language &&
                                language !== '-'
                            ) {
                                // Set language to provided language
                                languageService.setCurrentLanguage(currentSite, language);
                                languageInterceptorService.language = language;
                            }

                            // If language was a dash, fetch the current language and update the url
                            if (language === '-') {
                                language = languageService.getCurrentLanguage();
                                $location.url('/pages/' + language + '/' + $stateParams.url);
                            }

                            return Page.$find('by_url', {
                                expand: 'references,fx',
                                site_id: currentSite.$pk,
                                language: language,
                                url: $stateParams.url
                            }).$asPromise();
                        }
                    },
                    views : {
                        'content@' : {
                            templateUrl: 'views/page.html',
                            controller: 'PageCtrl'
                        }
                    },
                  children: [
                    {
                      name: 'detail',
                      url: '/{contentId:int}/{slug}',
                      params: {
                        slug: null
                      },
                      views: {
                        'content@': {
                          templateUrl: 'views/page.html',
                          controller: 'PageCtrl'
                        }
                      }
                    }
                  ]
                },
                {
                    name: 'sinterklaas',
                    url: '/sinterklaas',
                    redirectTo: '/carrier/131396'
                },
                {
                    name: 'music-matcher',
                    params: {
                        url: null
                    },
                    url: '/music-matcher?url',
                    onEnter: function($rootScope, $stateParams, $state, userService, infoModal, gettextCatalog, appAuthService, uploadAudioModal) {
                        return appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to use the MusicMatcher') }}).then(function() {
                            $rootScope.uploadAudioModalInstance = uploadAudioModal({
                                mode: 'audioUploader',
                                url: $stateParams.url
                            });
                        }).finally(function() {
                            $state.go('root.index');
                        });
                    }
                },
                {
                    name: 'subscribe',
                    url: '/subscribe',
                    redirectTo: '/pages/-/subscribe'
                },
                {
                    name: 'edl-tool',
                    url: '/edl-tool',
                    onEnter: function($state, edlToolModal, appAuthService, gettextCatalog) {
                        return appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to use the EDL tool') }}).then(function() {
                            edlToolModal({}).then(function() {
                                $state.go('root.index');
                            });
                        }).finally(function() {
                            $state.go('root.index');
                        });
                    },
                },
                {
                    name: 'registration-bundeswehr',
                    url: '/registration-bundeswehr',
                    onEnter: function($state, $cookies, $rootScope, registrationBundeswehrModal) {
                        $cookies.bundeswehr = true;
                        if (!$rootScope.isAuthenticated) {
                            return registrationBundeswehrModal().finally(function () {
                                $state.go('root.index');
                            });
                        } else {
                            $state.go('root.index');
                        }
                    },
                }
            ]
        });
    })

    /* Handling of expired oAuth tokens */
  .run(function ($rootScope, $window, authService, userService, authStore, OAuth, OAuthToken, PubSub) {
    $rootScope.$on('event:auth-loginRequired', function () {
      var refreshToken = OAuthToken.token ? OAuthToken.token.refresh_token : null;

      return OAuth.getRefreshToken().then(function (response) {
        // merge old refresh token into new token data
        OAuthToken.setToken(angular.merge({ refresh_token: refreshToken }, OAuthToken.getToken()));
        authStore.data = OAuthToken.getToken();

        // Use the new token as bearer token
        authService.loginConfirmed('succes', function(config) {
          config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
          PubSub.publish('auth:accessTokenChange', response.data);
          return config;
        });
      }, function(rejection) {
        // Bad refresh token, remove tokens
        authStore.clear();
        OAuthToken.removeToken();

        authService.loginCancelled();

        // Redirect to `/login` with the `error_reason`.
        $window.location.href = '/login?error_reason=token_expired';
        return $window.location.href;
      });
    });
  })

    .config(function ($windowProvider, AnalyticsProvider, configuration) {
        var $window = $windowProvider.$get();
        var enableAnalytics = false;

        if (configuration.useAnalytics) {
            // Find configuration data for this domain
            for (var domain in configuration.analyticsAccounts) {
                var domainData = configuration.analyticsAccounts[domain];
                if (domainData.domains.indexOf($window.location.host) !== -1 ||
                    domain === 'default'
                ) {
                    AnalyticsProvider.setAccount({
                        tracker: domainData.trackingId,
                        crossDomainLinker: domainData.domains.length > 1,
                        crossLinkDomains: domainData.domains,
                        trackEvent: true
                    });
                    AnalyticsProvider.setPageEvent('$stateChangeSuccess');
                    AnalyticsProvider.ignoreFirstPageLoad(true);
                    enableAnalytics = true;
                }
            }
        } else {
            // Setup dummy account, library requires it
            AnalyticsProvider.setAccount('UA-XXXXX-XX');
        }

        // Disable analytics if we can't find configuration or when it's disabled for this environment
        if (!enableAnalytics) {
            AnalyticsProvider.disableAnalytics(true);
        }
    })

    .config(function (slugProvider) {
      slugProvider.defaults.mode = 'rfc3986';
    })

    .run(function()
    {
        // Load the facebook SDK asynchronously
        (function(){
            // If we've already installed the SDK, we're done
            if (document.getElementById('facebook-jssdk')) {return;}

            // Get the first script element, which we'll use to find the parent node
            var firstScriptElement = document.getElementsByTagName('script')[0];

            // Create a new script element and set its id
            var facebookJS = document.createElement('script');
            facebookJS.id = 'facebook-jssdk';

            // Set the new script's source to the source of the Facebook JS SDK
            facebookJS.src = '//connect.facebook.net/en_US/all.js';

            // Insert the Facebook JS SDK into the DOM
            firstScriptElement.parentNode.insertBefore(facebookJS, firstScriptElement);
        }());
    })

    .run(function($rootScope, userService, PubSub, authStore) {
        function syncAuthenticationStatus() {
            $rootScope.isAuthenticated = userService.isAuthenticated();
            $rootScope.isSystemAdmin = false;

            if ($rootScope.currentSite) {
                $rootScope.userCanPlay = $rootScope.currentSite.publicCanPlay;
                $rootScope.userViewPlayButton = $rootScope.userCanPlay;
                $rootScope.userCanDownload = $rootScope.currentSite.publicCanDownload;
                $rootScope.userViewDownloadButton = $rootScope.currentSite.publicViewDownloadButton;
            } else {
                // global defaults
                $rootScope.userCanPlay = true;
                $rootScope.userViewPlayButton = true;
                $rootScope.userCanDownload = false;
                $rootScope.userViewDownloadButton = true;
            }

            if ($rootScope.isAuthenticated) {
                userService.isSystemAdmin().then(function (isSystemAdmin) {
                    $rootScope.isSystemAdmin = isSystemAdmin;
                });
                userService.canPlay().then(function(canPlay) {
                    $rootScope.userCanPlay = canPlay;
                    $rootScope.userViewPlayButton = canPlay;
                });
                userService.canDownload().then(function(canDownload) {
                    $rootScope.userCanDownload = canDownload;
                });
                userService.viewDownloadButton().then(function(viewDownloadButton) {
                    $rootScope.userViewDownloadButton = viewDownloadButton;
                });
			}
        }

        PubSub.subscribe('userService.onCurrentUserChange', function(tokenData) {
            syncAuthenticationStatus();
            if ($rootScope.isAuthenticated)
            {
                authStore.data = tokenData;
            }
            else {
                authStore.clear();
            }
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            syncAuthenticationStatus();
        });

        syncAuthenticationStatus();
    })

    .run(function($rootScope, $state, userService, loginModal, seoData) {
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState /*, fromParams */) {

            if (toState.redirectTo) {
                location.href = toState.redirectTo;
            }

            // Always change back to default SEO data
            seoData.revertToDefault();

            var requireLogin = toState.data.requireLogin;

            if (requireLogin &&
                userService.getCurrentUser() === null)
            {
                event.preventDefault();

                loginModal()
                    .then(function () {
                        return $state.go(toState.name, toParams);
                    })
                    .catch(function () {
                        // If this is the initial page, and we canceled login, go to the homepage
                        if (fromState.url === '^')
                        {
                            return $state.go('root.index');
                        }
                    });
            }
        });
    })

    .run(function($anchorScroll, $window, $rootScope, $location, $document) {
        // hack to scroll to top when navigating to new URLS but not back/forward
        // Also same the current scroll posistion
        var lastUrl, previousLocation, actualLocation  = null;
        var historyUrl = [];
        var startIndex = $window.window.history.length;
        var scrollY = 0;
        var wrapHistoryMethod = function(method) {
            var orig = $window.window.history[method];
            $window.window.history[method] = function() {
                var index = $window.window.history.state && $window.window.history.state.index ? $window.window.history.state.index : $window.window.history.length - startIndex;
                var args = Array.prototype.slice.call(arguments);
                args[0] = {index: index + 1};
                var retval = orig.apply(this, args);

                historyUrl[index] = $rootScope.forceYScrollPosition ? $rootScope.forceYScrollPosition : scrollY;

                // Check if the new URL is the same as the current URL (excluding query string)
                var newUrl = arguments[2].split(/\?/)[0];
                if (lastUrl === null ||
                    newUrl !== lastUrl)
                {
                    $anchorScroll();
                }
                lastUrl = newUrl;

                // Reset force scroll position
                $rootScope.forceYScrollPosition = null;
                return retval;
            };
        };
        wrapHistoryMethod('pushState');
        wrapHistoryMethod('replaceState');

        // Save the current scroll position
        $document.on('scroll', function(){
            scrollY = $window.scrollY;
        });

        // Automatic scroll to a position. Try again if the results are not there
        var setScrollPosision = function(position) {
            if (scrollY !== position)
            {
                $window.scrollTo(0, position);
                setTimeout(function(){setScrollPosision(position)}, 500);
            }
        };

        // Check if back or forward button is used
        $rootScope.$on('$locationChangeSuccess', function() {
            var index = $window.window.history.state && $window.window.history.state.index ? $window.window.history.state.index : 0;
            if(previousLocation == $location.url()) {
                if (historyUrl[index]) {
                    setTimeout(function () {
                        setScrollPosision(historyUrl[index])
                    }, 500);
                }
            }
            previousLocation = actualLocation;
            actualLocation = $location.url();
        });
    })

    .run(function($rootScope, $cookies, usernameChangeModal, PubSub, userService, usernameChangeService) {
        var checkStatusFunction = function() {
            // only trigger for NL site
            if (!$rootScope.currentSite || $rootScope.currentSite.$pk !== 1) {
                return;
            }
            if (userService.isAuthenticated()) {
                usernameChangeService.getIsChangeRequired().then(status => {
                    if (status) {
                        usernameChangeModal();
                    }
                });
            }
        };

        PubSub.subscribe('userService.onCurrentUserChange', checkStatusFunction);
        PubSub.subscribe('site:loaded', checkStatusFunction);
        checkStatusFunction();
    })

    // Required to trigger automatic Analytics tracker
    .run(function(PubSub, userService) {
        // Propagate account info to Analytics
        PubSub.subscribe('userService.onCurrentUserChange', function() {
            userService.updateAnalytics();
        });
    })

    // Global functions
    .run(function($rootScope) {
        $rootScope.addCacheBuster = function(buster) {
            if (buster === undefined) {
                return '';
            }
            return '?cacheBuster=' + buster.replace(/(\D|\s)+/g, '');
        };
    })

   .run(function($rootScope, $location) {
      $rootScope.$on('$locationChangeSuccess', function(event, newUrl) {
          const port = $location.port();
          const portString = port ? `:${port}` : '';
          $rootScope.canonicalHref = `${$location.protocol()}://${$location.host()}${portString}${$location.path()}`;
          $rootScope.locationPath = $location.path().replace(/^\//, '');
      });
   })
;

