'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:GenreDetailCtrl
 * @description
 * # GenreDetailCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('GenreDetailCtrl', function ($scope, $stateParams, $state, category, seoData) {
        $scope.data = {
            page: 1,
            category : category,
            pageLimit : 42,
            loadMoreDisabled: category.carriers.$page >= category.carriers.$pageCount,
            sort: $stateParams.sort
        };

        // Update SEO data
        seoData.pageTitle = category.title;
        seoData.metaDescription = category.text;

        $scope.changeSort = function(sort) {
            $scope.data.sort = sort;
            $scope.data.category.carriers.$refresh({
                expand: 'thumbnails',
                'per-page' : $scope.data.pageLimit,
                sort : sort,
                page: 1
            }).$then(function() {
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;

                // Sync sort options to URL
                var urlParams = {
                    genreId: category.$pk,
                    sort: sort,
                    sortSeed: angular.isDefined(this.$sortSeed) ? this.$sortSeed : null,
                    page: 1
                };
                $state.transitionTo($state.current.name, urlParams, { notify: false });
            });
        };

        $scope.loadMore = function()
        {
            $scope.data.loadMoreDisabled = true;
            $scope.data.category.carriers.$fetch({ expand: 'thumbnails', 'per-page' : $scope.data.pageLimit, page: $scope.data.category.carriers.$page + 1 }).$then(function() {
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;
            });
        };

        // START: code to test the event listeners
        $scope.$on('$viewContentLoaded', function() {
            console.log('view content loaded');
        });
        $scope.$on('$destroy', function() {
            console.log('destroyed');
        });
        // END

        // Start with a playlist based on the full category
        category.play({startPaused: true, onlyWhenEmpty: true});
    });
