'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistsCtrl
 * @description
 * # PlaylistsCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistsCtrl', function ($scope, $rootScope, Playlist, Track, playService,
                                           playlistResequenceModal, RemotePlayQueue, playlistEditModal, PubSub, userService)
    {
        $scope.data = {
            isInitializing: true,
            selectedPlaylist: null,
            selectedTracks: null,
            allDetails: false,
            playerScope: null,
            reordering: false,
            reoderItem: null,
            dragControlListeners: {
                accept: function (/* sourceItemHandleScope, destSortableScope */) {return true; },
                itemMoved: function (/* event */) {},
                dragStart: function (/* event */) {},
                dragMove: function (/* event */) {},
                dragEnd: function (/* event */) {},
                orderChanged: function(/* event */) {},
                clone: true
            },
            pageLimit : 100,
            loadMoreDisabled: true,
            loadingResults: true,
            currentPage: false,
            disableDrag: false
        };

        $scope.activePlaylist = 0;

        function refreshTracks()
        {
            // Clear current selection
            $scope.data.selectedTracks = null;

            return $scope.data.selectedPlaylist.linkedTracks.$refresh(
                {
                    expand: 'track,track.carrier,carrier.label,carrier.categories',
                    perPage: $scope.data.pageLimit,
                    page: 1
                }).$then(function(tracks){
                    $scope.data.loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
                    $scope.data.loadingResults = false;
                    $scope.data.currentPage = this.$metadata.currentPage;
            });
        }

        $scope.resequenceTracks = function()
        {
            return playlistResequenceModal({
                model: $scope.data.selectedPlaylist,
                loadMore: function() {
                    return loadMoreTracks();
                }
            }).then(function(/* playlist */) {
                // Reload tracks to update positions
                refreshTracks();
            });
        };

      $scope.removeSelectedTracks = function() {
        $scope.data.selectedPlaylist.removeTracks($scope.data.selectedTracks).then(function() {
          return refreshTracks();
        });
      };

      $scope.setSelectedTracks = function(tracks) {
          // Set attribute to null if there are no tracks selected
          if (jQuery.isEmptyObject(tracks)) {
              $scope.data.selectedTracks = null;
          } else {
              $scope.data.selectedTracks = [];
              // Convert map to array with only the keys
              angular.forEach(tracks, function(value, key) {
                  $scope.data.selectedTracks.push(key);
              });
          }
      };

      $scope.selectPlaylist = function(playlist) {
          $scope.data.allDetails = false;

          // Select playlist and update set the controller to initialized
          $scope.data.selectedPlaylist = playlist;
          $scope.data.isInitializing = false;

          $scope.activePlaylist = playlist.$pk;
          if (playlist !== null) {
              $scope.data.disableDrag = !(playlist.isEditor || playlist.isOwner);
              // Get the tracks for this playlist
              playlist.$isLoading = true;
              playlist.setRead().then(function (playlist) {
                  playlist.$isSelected = true;
                  playlist.$isLoading = true;

                  if (playlist.trackCount > 0) {
                      refreshTracks().$then(function () {
                          // Load playlist as player queue
                          new RemotePlayQueue(playlist).load().then(function (queue) {
                              playService.playQueue(queue, {startPaused: true, onlyWhenEmpty: true});
                          });

                          playlist.$isLoading = false;
                      }, function () {
                          playlist.$isSelected = false;
                          $scope.data.selectedPlaylist = null;
                          playlist.$isLoading = false;
                      });
                  }
                  else {
                      playlist.$isLoading = false;
                  }

                  PubSub.publish('playlist.select', playlist);
              });
          }
      };

      $scope.createNewPlaylistWithTracks = function(trackIds) {
          var newPlaylist = Playlist.$build({ addAsActive: 0, site : $rootScope.currentSite });
          return playlistEditModal({
              model: newPlaylist,
              addAsActive: false
          }).then(function(playlist) {
              // Add the tracks to the playlist
              var result = playlist.addTracks(trackIds);

              // Send out event to allow the playlist bar to refresh
              PubSub.publish('playlist.change', { playlist: playlist });

              return result;
          });
      };

      $scope.shareSelectedTracks = function() {
          return Track.shareMultiple($scope.data.selectedTracks);
      };

      function loadMoreTracks()
      {
          if (!$scope.data.loadingResults) {
              $scope.data.loadMoreDisabled = true;
              $scope.data.loadingResults = true;
              return $scope.data.selectedPlaylist.linkedTracks.$fetch(
                  {
                      expand: 'track,track.carrier,carrier.label,carrier.categories',
                      perPage: $scope.data.pageLimit,
                      page: $scope.data.selectedPlaylist.linkedTracks.$metadata.currentPage + 1
                  }
              ).$then(function (tracks) {
                  $scope.data.loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
                  $scope.data.loadingResults = false;
              });
          }
      }

      $scope.loadMoreTracks = function()
      {
          loadMoreTracks();
      };
    });
