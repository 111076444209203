'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('PlaylistFolder', function (restmod) {
        return restmod.model('/playlists').mix('Playlist', {
            playlist: { belongsTo: 'Playlist', mask:  'CU', key: 'referenceID' },
            $config: {
                'name': 'playlistFolder',
                'plural': 'playlistFolders'
            },
            $hooks: {
                'after-create' : function() {
                    // Reload parent
                    this.playlist.$fetch();
                },
                'after-destroy' : function() {
                    // Reload parent
                    this.playlist.$fetch();
                }
            }
        });
    });