'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:AccountValidationModalCtrl
 * @description
 * # AccountValidationModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('AccountValidationModalCtrl', function ($scope, modalOptions, Account) {
        $scope.data = {
            isValidating: true,
            result: {}
        };

        this.cancel = $scope.$dismiss;

        Account.single('/account').validate({
            token: modalOptions.token,
            id: modalOptions.id,
            site_id: $scope.currentSite.$pk
        }).then(function (result) {
            $scope.data.result = result.data;
            $scope.data.isValidating = false;
        }, function(result) {
            $scope.data.result = result.data;
            $scope.data.isValidating = false;
        });
    });
