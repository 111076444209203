'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:InfoModalCtrl
 * @description
 * # InfoModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('InfoModalCtrl', function ($scope, modalOptions) {
        $scope.modalOptions = modalOptions;

        for (var k in $scope.modalOptions.errors)
        {
            if (typeof $scope.modalOptions.errors[k] !== 'object') {
                $scope.modalOptions.errors[k] = {message: $scope.modalOptions.errors[k]};
            }
            else if ($scope.modalOptions.errors[k] instanceof Array) {
                $scope.modalOptions.errors[k] = {message: $scope.modalOptions.errors[k][0]};
            }
        }

        this.cancel = $scope.$dismiss;

        this.close = $scope.$close;
    });
