'use strict';

/**
 * @ngdoc function
 * @name webappMusicdirectorComApp.controller:GenreCtrl
 * @description
 * # GenreCtrl
 * Controller of the webappMusicdirectorComApp
 */
angular.module('musicdirectorApp')
    .controller('GenreCtrl', function ($scope, categories) {
        $scope.data = {
            categories: categories,
            loadMoreDisabled: categories.$page >= categories.$pageCount
        };

        $scope.loadMore = function () {
            $scope.data.loadMoreDisabled = true;
            $scope.data.categories.$fetch({
                'per-page': 48,
                expand: 'thumbnails',
                sort: 'title',
                page: $scope.data.categories.$page + 1
            }).$then(function () {
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;
            });
        };
    });
