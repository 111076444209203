'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.businessSector
 * @description
 * # companyType
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('BusinessSector', function (restmod) {
        return restmod.model('/business-sectors').mix({
            businessTypes: { hasMany: 'BusinessType' },
            $config: {
                name: 'businessSector',
                plural: 'businessSectors'
            }
        });
    });
