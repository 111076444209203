'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.requestService
 * @description
 * # requestService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('bearerInterceptorService', function () {
        function request(config) {
            // auth store is always leading and doesn't require a valid token
            if (config.url.match(new RegExp('^/?api/v1/auth-store')) !== null) {
                delete config.headers.Authorization;
            }
            return config;
        }

        return {
            request: request
        };
    });
