'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.PromoService
 * @description
 * # PromoService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('PromoService', function ($http, restmod) {
    return restmod.model('/promos').mix({
        $extend: {
            Model: {
                status: function() {
                    return $http.get(this.$url() + '/status');
                },

                participate: function(data) {
                    return $http.post(this.$url() + '/participate', data);
                },

                optOut: function() {
                    return $http.post(this.$url() + '/opt-out');
                }
            }
        }
    });
  });
