'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.copyrightModal
 * @description
 * # copyrightModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('copyrightModal', function ($uibModal, gettextCatalog) {
    return function(_options) {
      var templateUrl = _options.templateUrl ? _options.templateUrl : 'views/copyright-modal.html';
      var controller = _options.controller ? _options.controller : 'CopyrightModalCtrl';

      var instance = $uibModal.open({
        animation: false,
        backdrop: 'static',
        templateUrl: templateUrl,
        controller: controller,
        controllerAs: controller,
        windowClass: 'copyright-modal',
        resolve : {
          modalOptions : function() {
            return angular.extend({
              title: gettextCatalog.getString('Copyright information'),
              track: null,
              copyrightData: null,
              downloadButtonText: gettextCatalog.getString('Download'),
              closeButtonText: gettextCatalog.getString('Close')
            }, _options);
          }
        }
      });

      return instance.result;
    };
  });
