'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.loginModal
 * @description
 * # loginModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('edlToolModal', function($uibModal, $window) {
        return function(_options) {
            var instance = $uibModal.open({
                backdrop: 'static',
                animation: false,
                templateUrl: 'views/edl-tool-modal.html',
                controller: 'EdlToolModalCtrl',
                controllerAs: 'EdlToolModalCtrl',
                windowClass: 'edl-tool-modal',
                resolve: {
                    options : function() {
                        return _options;
                    }
                }
            });

            // redirect url to home page because current url has no content to show
            instance.result.then(function(){
                $window.location.href = '/';
            }, function (){
                $window.location.href = '/';
            });

            return instance.result;
        };
    });
