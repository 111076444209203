'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:LoginmodalCtrl
 * @description
 * # LoginmodalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('LoginModalCtrl', function ($scope, $state, $location, $facebook, $http, $cookies, OAuth,
                                            OAuthToken, modalOptions, registrationModal, forgotPasswordModal,
                                            configuration, $httpParamSerializerJQLike, infoModal, gettextCatalog,
                                            Analytics, userService, registrationBundeswehrModal)
    {
        $scope.data = { isSecure: $location.protocol() === 'https' };
        $scope.options = modalOptions;

        $scope.isLoggedIn = false;
        $scope.loginFailed = false;
        $scope.loginFormData = {};

        $scope.loginWithFacebook = function() {
            $facebook.login().then(function() {
                $facebook.api('/me').then(
                    function(facebookMeResponse) {
                        $facebook.getLoginStatus().then(function(facebookResponse) {
                            $http.post(configuration.oAuthBaseUrl + '/oauth2/token', $httpParamSerializerJQLike({
                                grant_type : 'facebook_access_token',
                                facebook_access_token : facebookResponse.authResponse.accessToken,
                                client_id : 'webapp'
                            }), {
                                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                                withCredentials: true
                            }).then(function(response) {
                                OAuthToken.setToken(response.data);
                                $scope.$close(response.data);
                            }, function(response) {
                                if (response.status === 404)
                                {
                                    $scope.$dismiss();

                                    // No user is currently linked to this account, show the registration popup
                                    registrationModal({
                                        initialData : {
                                            contactFirstname: facebookMeResponse.first_name,
                                            contactLastname: facebookMeResponse.last_name,
                                            email: facebookMeResponse.email
                                        },
                                        socialRegistrationData: {
                                            provider: 'facebook',
                                            providerUserId: facebookMeResponse.id,
                                            providerAuthResponse: facebookResponse.authResponse
                                        }
                                    });
                                }
                            });
                        });

                        $scope.isLoggedIn = true;
                    });
            });
        };

        $scope.forgotPassword = function() {
            $scope.$dismiss();
            forgotPasswordModal().then(function() {
                $state.go('root.index');
            }, function() {
                $state.go('root.index');
            });
        };

        $scope.register = function() {
            $scope.$dismiss();
            if ($cookies.bundeswehr) {
                registrationBundeswehrModal();
            } else {
                registrationModal();
            }
        };

        // Track the completion as pageview
        Analytics.trackPage('/account/login', 'Login');

        this.cancel = function() {
            if (modalOptions.showDismissButton) {
                $scope.$dismiss();
            }
        };

        this.submit = function () {
            if ($scope.loginForm.$valid)
            {
                OAuth.getAccessToken($scope.loginFormData, { ignoreAuthModule: true }).then(function(response) {
                    // Track the login
                    Analytics.trackEvent('user', 'login');

                    // Reload the account
                    userService.getAccount(true);

                    var oAuthData = response.data;

                    // Check if the user has access to the current site
                    if ($scope.currentSite)
                    {
                        $scope.currentSite.$refresh().$then(function(/* site */) {
                            $scope.$close(oAuthData);
                        }, function() {
                            infoModal({
                                title: gettextCatalog.getString('User not linked to this site'),
                                html: '<p>' + gettextCatalog.getString('Your user exists, but does not have access to this site. Please contact our support department.') + '</p>'
                            });

                            // Reset login form
                            $scope.loginFormData = {};
                        });
                    }
                    else
                    {
                        $scope.$close(oAuthData);
                    }
                }, function () {
                    // Track the play
                    Analytics.trackEvent('user', 'login-failed');

                    $scope.loginFailed = true;
                });
            }
        };
    });
