'use strict';

/**
 * @ngdoc filter
 * @name webappMusicdirectorComApp.filter:duration
 * @function
 * @description
 * # duration
 * Filter in the webappMusicdirectorComApp.
 */
angular.module('musicdirectorApp.filters')
  .filter('duration', function () {
    return function (input) {
        var seconds = Math.floor(parseInt(input) / 100);
        var secondsInMinute = (seconds % 60);
        if (secondsInMinute < 10)
        {
            secondsInMinute = '0' + secondsInMinute;
        }
        return Math.floor(seconds / 60) + ':' + secondsInMinute;
    };
  });
