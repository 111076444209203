'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:mailLink
 * @description
 * # mailLink
 */
angular.module('musicdirectorApp')
  .directive('mailLink', function () {
    return {
      replace: true,
      restrict: 'E',
      link: function postLink(scope, element, attrs) {
        element.html('<a href="mailto:' + attrs.l + '@' + attrs.d + '">' + element.text() + '</a>');
      }
    };
  });
