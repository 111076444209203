'use strict';

/**
 * @ngdoc filter
 * @name musicdirectorApp.filter:chunk
 * @function
 * @description
 * # chunk
 * Filter in the musicdirectorApp.
 */
angular.module('musicdirectorApp.filters')
  .filter('chunk', ['lodash', function (lodash) {
    return lodash.memoize(lodash.chunk);
  }]);
