'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:trackDetails
 * @description
 * # trackDetails
 */
angular.module('musicdirectorApp')
    .directive('trackVersion', function (PubSub) {
        return {
            restrict: 'A',
            replace: true,
            require: '^trackDetails',
            scope: {
                track: '=trackVersion',
                playScope: '='
            },
            link: function(scope, element, attrs, trackListCtrl) {
                angular.forEach(['currentSite', 'playScope'], function(value) {
                    scope[value] = trackListCtrl.getScopeAttribute(value);
                });

                var pubSubSubscriptions = [];
                pubSubSubscriptions.push(PubSub.subscribe('playService.playTrack', function(args) {
                    // If current track is playing, and new track is played, remove playing
                    if ((scope.track.$isPlaying ||
                        scope.track.$isPaused) &&
                        scope.track.$pk !== args.track.$pk)
                    {
                        scope.track.$isPlaying = false;
                        scope.track.$isPaused = false;
                    }
                }));
                pubSubSubscriptions.push(PubSub.subscribe('playService.setState', function(args) {
                    if (scope.track.$pk === args.track.$pk) {
                        scope.track.$isPlaying = args.track.$isPlaying;
                        scope.track.$isPaused = args.track.$isPaused;
                    }
                }));

                scope.$on('$destroy', function() {
                    for (var iSubscription = 0; iSubscription < pubSubSubscriptions.length; iSubscription++)
                    {
                        PubSub.unsubscribe(pubSubSubscriptions[iSubscription]);
                    }
                });
            },
            templateUrl: 'views/track-version.html'
        };
    });
