'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:confirmClick
 * @description
 * # confirmClick
 */
angular.module('musicdirectorApp')
    .directive('confirmClick', function(confirmModal, gettextCatalog) {
        return {
            restrict: 'A',
            scope: {
                'action': '&confirmClick',
                'header': '@confirmHeader',
                'text': '@confirmText',
                'yesText': '@confirmYesText',
                'noText': '@confirmNoText'
            },
            link: function(scope, elem /*, attrs */) {
                elem.on('click', function() {
                    return confirmModal({
                        title: scope.title || gettextCatalog.getString('Confirm'),
                        message: scope.text,
                        yesText: scope.yesText || gettextCatalog.getString('Yes'),
                        noText: scope.noText || gettextCatalog.getString('No')
                    }).then(function() {
                        scope.action();
                    });
                });
            }
        };
    });