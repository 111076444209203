'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.downloadCreditsModal
 * @description
 * # downloadCreditsModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('downloadCreditsModal', function (gettextCatalog, infoModal, $rootScope) {
    return function(_options) {
      var errorMessage;
      if (_options.credits.term.valid) {
        errorMessage = gettextCatalog.getPlural(Math.max(0, _options.credits.before), 'You currently have 1 credit.', 'You currently have {{$count}} credits.', {});
      } else {
        var currentTime = new Date().getTime();
        var startDate = new Date(_options.credits.term.start);
        var endDate = new Date(_options.credits.term.end);
        if (currentTime < startDate.getTime()) {
          errorMessage = gettextCatalog.getString('Your credit period has not yet started. It will start on {{startDate}}.', { startDate: startDate.toLocaleDateString() });
        } else if (currentTime > endDate.getTime()) {
          errorMessage = gettextCatalog.getString('Your credit period expired on {{endDate}}.', { endDate: endDate.toLocaleDateString() });
        }
      }

      var html;

      // french site has custom text
      if ($rootScope.currentSite.$pk === 19) {
          html = '<p>Il ne vous reste plus assez de crédits pour télécharger ce titre.<br>' +
              'Pour obtenir plus de crédits, contactez Anna-Louise pour les projets TV ou documentaire et Étienne pour le cinéma, les séries, la publicité et la radio.</p>' +
              '<p>Anna-Louise : <a href="mailto:anna-louise.dupont@bmg.com">anna-louise.dupont@bmg.com</a> / 01 40 72 52 24<br>' +
              'Étienne : <a href="mailto:etienne.salina@bmg.com">etienne.salina@bmg.com</a> / 01 40 72 78 76</p>' +
                '<p>À bientôt !</p>';
      } else {
          html = '<p>' + gettextCatalog.getString('You do not have enough credits to download this track.') + '<br>' +
              gettextCatalog.getPlural(_options.tracks, 'This action requires 1 credit.', 'This action requires {{$count}} credits.', {}) + ' ' +
              errorMessage + '</p>' +
              '<p>' + gettextCatalog.getString('Please contact us for licensing this track.') + '</p>' +
              '<p>' + $rootScope.currentSite.title + '<br>Tel: ' + $rootScope.currentSite.supportPhoneNumber + '<br>E-mail: ' + $rootScope.currentSite.supportEmailAddress + '</p>';
      }

      return infoModal({
        title: gettextCatalog.getString('Insufficient credits'),
        html: html
      });
    };
  });
