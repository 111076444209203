'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PageCtrl
 * @description
 * # PageCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PageCtrl', function ($scope, $state, $stateParams, page, Reference, videoModal, currentSite,
                                      languageService, seoData, Analytics, slug, $http) {
        $scope.page = page;
        $scope.templateUrl = 'views/page-' + page.type + '.html';
        $scope.baseUrl = $state.href('root.pages', $stateParams, {absolute: true});

        $scope.playerInstances = [];
        $scope.alertMessage = '';

        // use seo data from page
        if (page.seoTitle) {
            seoData.pageTitle = page.seoTitle;
        } else {
            seoData.pageTitle = page.title;
        }
        seoData.metaDescription = page.seoDescription;
        seoData.keywords = page.seoKeywords ? page.seoKeywords.split(',') : null;
        if (page.thumbnails['400x250'] &&
            page.thumbnails['400x250'].length > 0) {
            seoData.pageImage = page.thumbnails['400x250'];
        }

        $scope.data = {};

        if (page.type === 'playlists')
        {
            $scope.data = {
                newsItems: [],
                loadMoreDisabled: true
            };

            $scope.data.newsItems = currentSite.news.$search({ language: languageService.getCurrentLanguage(), 'per-page' : 12, category: 'Playlist' }).$then(function(newsItems) {
                $scope.data.loadMoreDisabled = newsItems.$page >= newsItems.$pageCount;
            });

            $scope.loadMore = function () {
                $scope.data.loadMoreDisabled = true;
                $scope.data.newsItems.$fetch({
                    'per-page': 12,
                    category: 'Playlist',
                    language: languageService.getCurrentLanguage(),
                    page: $scope.data.newsItems.$page + 1
                }).$then(function () {
                    $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;
                });
            };
        }
        else if (page.type === 'references')
        {
          // Load specific reference based on URL
          if ($stateParams.contentId) {
            Reference.$find($stateParams.contentId).$then(function(reference) {
              return $scope.showVideo(reference);
            });
          }
        }

        if (page.fx) {
            angular.forEach(page.fx, function(fx) {
                fx.$sources = [{ src: fx.audio, type: 'audio/mp3'}];
            });
        }

        $scope.showVideo = function (item) {
            // Track the letter filter
            Analytics.trackEvent('video', 'play', item.title);

            // Change the URL
            $state.transitionTo('root.pages.detail', angular.merge($stateParams, { contentId: item.ID, slug: item.title ? slug(item.title) : '' }), { notify: false });

            videoModal({model: item, baseUrl: $scope.baseUrl}).finally(function() {
              $state.transitionTo('root.pages', $stateParams, { notify: false });
            });
        };

        $scope.onPlayerReady = function(fx, $API) {
            $scope.playerInstances[fx.ID] = $API;
        };

        $scope.onPlayerUpdateState = function(fx, $state) {
            // Pause all other players
            if ($state === 'play') {
                angular.forEach($scope.playerInstances, function (value, valueKey) {
                    if (fx.ID !== valueKey) {
                        value.pause();
                    }
                });
            }
        };

        // handling the contact form
        $scope.submitContactForm = function(contactForm){
            var element = angular.element('#alert-box');
            element.removeClass();

            // check if the recaptcha is filled in
            if (angular.element('#g-recaptcha-response').val() === '')
            {
                $scope.alertMessage = 'Please prove you\'re not a robot by checking the recaptcha.';
                element.addClass('alert alert-warning');
                return;
            }

            // prepare the form values for posting
            var config = {
                ContactForm: {
                    name: contactForm.name,
                    email: contactForm.email,
                    message: contactForm.message,
                    cookieAcceptance: contactForm.cookieAcceptance
                },
                recaptcha: angular.element('#g-recaptcha-response').val()
            };

            // post the values to the api server
            $http({
                method: 'post',
                url: '/api/v1/contact-form/submit_form',
                data: config,
            }).then(function(response) {
                $scope.alertMessage = response.data.message;
                if (response.data.success === true)
                {
                    // contact form has been send
                    element.addClass('alert alert-succes');
                } else if (response.data.validation === true)
                {
                    // validation failed
                    element.addClass('alert alert-warning');
                } else {
                    // incorrect server response
                    element.addClass('alert alert-danger');
                }
            },function () {
                // request failed
                element.addClass('alert alert-warning');
                $scope.alertMessage = 'Something went wrong when we tried to send your email. Please try again.';
            });
        };
    });
