'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:setClassWhenAtTop
 * @description
 * # setClassWhenAtTop
 */
angular.module('musicdirectorApp')
    .directive('setClassWhenAtTop', function ($window) {
        var $win = angular.element($window); // wrap window object as jQuery object

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var topClass = attrs.setClassWhenAtTop, // get CSS class from directive's attribute value
                    offsetTop,
                    parent = element.parent(),
                    offsetFromTop = attrs.topOffset || null,
                    offsetFromTopInt = parseInt(attrs.topOffset) || 0,
                    setParentWidth = attrs.topSetParentWidth || false,
                    setParentHeight = attrs.topSetParentHeight || false,
                    fixWidth = attrs.topFixWidth || false,
                    hasClass = false;

                function update()
                {
                    offsetTop = parent.offset().top;

                    if ($win.scrollTop() >= (offsetTop - offsetFromTopInt)) {
                        // Only process the changes when the element does not have the fixed class
                        if (!hasClass) {
                            if (setParentWidth) {
                                element.parent().css('width', element.outerWidth() + 'px');
                            }
                            if (setParentHeight) {
                                element.parent().css('height', element.outerHeight() + 'px');
                            }
                            if (fixWidth) {
                                element.css('width', element.outerWidth());
                            }
                            element.addClass(topClass);
                            if (offsetFromTop) {
                                element.css('top', offsetFromTop + 'px');
                            }
                            hasClass = true;
                        }
                    } else {
                        // Only process the changes when the element has the fixed class
                        if (hasClass) {
                            element.removeClass(topClass);
                            if (fixWidth) {
                                element.css('width', '');
                            }
                            if (offsetFromTop) {
                                element.css('top', '');
                            }
                            if (setParentWidth) {
                                element.parent().css('width', '');
                            }
                            if (setParentHeight) {
                                element.parent().css('height', '');
                            }
                            hasClass = false;
                        }
                    }
                }

                $win.on('scroll', function (/* e */) {
                    update();
                });
            }
        };
    });