'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.companyType
 * @description
 * # companyType
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Company', function (restmod) {
        return restmod.model('/companies').mix({
            site: { belongsTo: 'Site', key: 'siteID' },
            houseNo: { map: 'houseNo' },
            address2: { map: 'address2' },
            $extend: {
                Model: {
                    $searchByData: function(_data) {
                        return this.$search(_data);
                    }
                }
            }
        });
    });
