'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.loginModal
 * @description
 * # loginModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('shareModal', function($uibModal, userService) {
        return function(_options) {
            var instance = $uibModal.open({
                animation: false,
                templateUrl: 'views/share-modal.html',
                controller: 'ShareModalCtrl',
                controllerAs: 'ShareModalCtrl',
                windowClass: 'share-modal',
                resolve: {
                    options : function() {
                        return angular.extend({
                            method: 'by-url',
                            multipleModelIds: null,
                            shareUrl: null,
                            selection: null
                        }, _options);
                    },
                    account: function() {
                        return userService.getAccount();
                    }
                },
                backdrop: 'static'
            });

            return instance.result;
        };
    });
