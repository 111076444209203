'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.downloadService
 * @description
 * # downloadService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('downloadService', function ($q, $http, $interval, downloadModal, infoModal, PubSub, appAuthService,
                                        gettextCatalog, localStorageService, configuration, growl, downloadCreditsModal,
                                        confirmModal, $httpParamSerializer, Analytics, $rootScope, userService ) {

        function checkFormat(_options)
        {
            var options = angular.merge({
                selectFormat: false
            }, _options);

            var deferred = $q.defer();

            var defaults = {
                remember: true
            };
            if (localStorageService.get('downloadFormat')) {
                defaults.format = localStorageService.get('downloadFormat');
                defaults.remember = false;
            }
            if (localStorageService.get('downloadEmailCopyrightInfo')) {
                defaults.emailCopyrightInfo = localStorageService.get('downloadEmailCopyrightInfo');
                defaults.remember = false;
            }

            if('sourceID' in options && 'source' in options){
                defaults.sourceID = options.sourceID;
                defaults.source = options.source;
            }

            // when format is known and we don't want to select a new format
            if ('format' in defaults &&
                defaults.format !== null &&
                !options.selectFormat
            ) {
                deferred.resolve(defaults);
            } else {
                // trigger download modal
                downloadModal(defaults)
                    .then(function (result) {
                        if (result.remember) {
                            // Store selection in browser locatie storage
                            localStorageService.set('downloadFormat', result.format);
                            localStorageService.set('downloadEmailCopyrightInfo', result.emailCopyrightInfo);
                        }

                        deferred.resolve(result);
                    })
                    .catch(function () {
                        deferred.reject();
                    });
            }

            return deferred.promise;
        }

        function downloadTrack(track, options)
        {
          var downloadTrack = function() {
            if (angular.isUndefined(options)) {
              options = {};
            }

            return checkFormat(options).then(function (data) {
              // Publish an event
              PubSub.publish('downloadService.track', [track, data.format, data.emailCopyrightInfo]);

              // Track the download
              Analytics.trackEvent('track', 'download', track.title, track.track, true, {dimension2: data.format});

              return data;
            });
          };

          // check if we can login to authenticate for downloads
          if (!$rootScope.userCanDownload && !$rootScope.isAuthenticated) {
            return appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to download tracks') }}).then(function() {
              return downloadTrack();
            });
          }
          // check if we can download tracks
          else if (!$rootScope.userCanDownload) {
            infoModal({
              title: gettextCatalog.getString('Can\'t download this track'),
              html: '<p>' + gettextCatalog.getString('User doesn\'t have enough rights to download tracks.') + '</p>',
            });
            return null;
          }
          else {
            return downloadTrack();
          }
        }

        function downloadCarrier(carrier)
        {
            // Downloading requires a logged in user
            return appAuthService.requireLogin({ modal : { message : 'Please login to download carriers' }}).then(function() {
                return checkFormat().then(function(data) {
                    // Publish an event
                    PubSub.publish('downloadService.carrier', [carrier, data.format, data.emailCopyrightInfo]);

                    // Track the download
                    Analytics.trackEvent('carrier', 'download', carrier.code, carrier.$pk, true, { dimension2: data.format });

                    return handleLargeDownload(carrier, data, []);
                });
            });
        }

        function downloadPlaylist(playlist, trackIds, format)
        {
            // Downloading requires a logged in user
            return appAuthService.requireLogin({ modal : { message : 'Please login to download playlists' }}).then(function() {
                return checkFormat(format).then(function(format) {
                    // Publish an event
                    PubSub.publish('downloadService.playlist', [playlist, format]);

                    // Track the download
                    Analytics.trackEvent('playlist', 'download', playlist.title, null, true, { dimension2: format });

                    return handleLargeDownload(playlist, format, { 'include_track_ids[]': trackIds });
                });
            });
        }

        function downloadTrackVersionsStems(track, versions, stems)
        {
            versions = versions === undefined ? false : versions;
            stems = stems === undefined ? false : stems;
            // Downloading requires a logged in user
            return appAuthService.requireLogin({ modal : { message : 'Please login to download track versions' }}).then(function() {
                return checkFormat().then(function(format) {
                    // Publish an event
                    PubSub.publish('downloadService.trackVersions', [track, format]);

                    // Track the download
                    if (versions && stems) {
                      Analytics.trackEvent('trackVersionsAndStems', 'download', track.title, null, true, {dimension2: format});
                    } else if (versions && !stems) {
                      Analytics.trackEvent('trackVersions', 'download', track.title, null, true, {dimension2: format});
                    } else if (!versions && stems) {
                      Analytics.trackEvent('trackStems', 'download', track.title, null, true, {dimension2: format});
                    }

                    return handleLargeDownload(track, format, { downloadVersions: versions, downloadStems: stems });
                });
            });
        }

        function handleLargeDownload(model, data, _options)
        {
            var options = angular.extend({format: data.format.toLowerCase(), emailCopyrightInfo: data.emailCopyrightInfo}, _options);
            var serializedOptions = $httpParamSerializer(options);

            // Handle download
            var downloadUrl = model.$url() + '/download?' + serializedOptions;

            // Check credit requirements
            return $http.get(downloadUrl + '&check-only=1').then(function(result) {
                if (result.data.allowed === true) {
                    // When the site does not use credits, start the download immediately
                    if (result.data.credits === false) {
                        return startDownloadFromUrl(downloadUrl);
                    } else {
                        return confirmModal({
                            title: gettextCatalog.getString('Confirm download'),
                            message: gettextCatalog.getString('Completing this download requires') + ' ' +
                                gettextCatalog.getPlural(result.data.tracks, '1 credit', '{{$count}} credits', {}) + '. ' +
                                gettextCatalog.getString('Do you want to continue?')
                        }).then(function() {
                            return startDownloadFromUrl(downloadUrl);
                        });
                    }
                }
                // Insufficient credits
                else {
                    infoModal({
                        title: gettextCatalog.getString('Unable to download'),
                        message: gettextCatalog.getString('We are currently unable to process your download request.'),
                        closeButtonText: gettextCatalog.getString('Close')
                    });
                }
            }, function(response) {
                if (response.status === 402) {
                    downloadCreditsModal(response.data);
                }
            });
        }

        function startDownloadFromUrl(downloadUrl)
        {
            return $http.get(downloadUrl).then(function (result) {
                if (result.data.status === 'ok') {
                    // Show info model and start polling for job completion
                    infoModal({
                        title: gettextCatalog.getString('Preparing download'),
                        message: gettextCatalog.getString('We are preparing your download. Depending on the number of tracks, this can take a few minutes. When the download is ready, it will popup on the bottom right of your screen.'),
                        closeButtonText: gettextCatalog.getString('Close')
                    });

                    // Show 'loading' growl
                    var growlInstance = growl.info('<i class="icon fa fa-spinner fa-spin"></i> ' + gettextCatalog.getString('Preparing download...'), {
                        ttl: -1,
                        disableIcons: true,
                        clickToClose: false
                    });

                    var jobIdentifier = result.data.job_identifier;

                    var statusIntervalPromise = $interval(function () {
                        $http.get(configuration.apiUrlPrefix + '/job-status?identifier=' + jobIdentifier, { ignoreLoadingBar: true }).then(function (result) {
                            if (result.data.result !== null) {
                                // Hide loading growl
                                if (growlInstance !== null) {
                                    growlInstance.destroy();
                                }

                                $interval.cancel(statusIntervalPromise);
                                growl.success(gettextCatalog.getString('Your files are ready to download!') + ' <a class="btn btn-primary" href="' + result.data.result.downloadPath + '" target="_blank">' + gettextCatalog.getString('Download') + '</a>', {ttl: -1});
                            }
                        });
                    }, 3000);
                }
                else {
                    infoModal({
                        title: gettextCatalog.getString('Unable to download'),
                        message: gettextCatalog.getString('We are currently unable to process your download request.'),
                        closeButtonText: gettextCatalog.getString('Close')
                    });
                }
            });
        }

        return {
            downloadTrack: downloadTrack,
            downloadCarrier: downloadCarrier,
            downloadPlaylist: downloadPlaylist,
            downloadTrackVersionsStems: downloadTrackVersionsStems
        };
  });
