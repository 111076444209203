'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:ConfirmModalCtrl
 * @description
 * # ConfirmModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('ConfirmModalCtrl', function($scope, $uibModalInstance, modalOptions) {
        $scope.modalOptions = modalOptions;

        this.yes = $scope.$close;
        this.no = $scope.$dismiss;

        if (angular.isUndefined($scope.modalOptions.expectInput)) {
          $scope.modalOptions.expectInput = false;
        }
        if (angular.isUndefined($scope.modalOptions.additionalCheckbox)) {
          $scope.modalOptions.additionalCheckbox = false;
        }
        $scope.disabled = true;
        $scope.update = function() {
            if ($scope.modalOptions.expectInput) {
                $scope.disabled = angular.uppercase($scope.modalOptions.inputValue) !== $scope.modalOptions.compareValue;
            }
            if ($scope.modalOptions.additionalCheckbox) {
                $scope.disabled = !$scope.modalOptions.additionalCheckboxValue;
            }
        };
    });
