'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:UsernameChangeModalCtrl
 * @description
 * # UsernameChangeModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('UsernameChangeModalCtrl', function ($scope, $timeout, account, isChangeRequired) {
      $scope.data = {
          isChangeRequired: isChangeRequired,
          updateFailed: false,
          isUpdating: false,
          account,
          closeButtonText: "Sluiten"
      }

      let counter = 0;
      let timeout = null;

      this.change = function() {
          $scope.data.isUpdating = true;
          account.updateLegacyUsername().then(() => {
              $scope.data.isChangeRequired = false;
              $scope.data.isUpdating = false;

              const timeFunction = function () {
                  counter += 1;
                  $scope.data.closeButtonText = `Sluiten (${10 - counter})`;

                  if (counter < 10) {
                      timeout = $timeout(timeFunction, 1000);
                  } else {
                      $scope.$dismiss();
                  }
              };

              timeout = $timeout(timeFunction, 1000);
          }).catch(() => {
              $scope.data.isUpdating = false;
              $scope.data.updateFailed = true;
          })
      };

      this.close = function() {
          if (timeout !== null) {
              clearTimeout(timeout);
          }
          $scope.$dismiss();
      };
  });
