'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:convertToNumber
 * @description
 * # convertToNumber
 */
angular.module('musicdirectorApp')
  .directive('convertToNumber', function () {
      return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
          ngModel.$parsers.push(function(val) {
            return parseInt(val, 10) || null;
          });
          ngModel.$formatters.push(function(val) {
            return '' + val;
          });
        }
      };
    });
