'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:StampPromoModalCtrl
 * @description
 * # StampPromoModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('StampPromoModalCtrl', function ($scope, modalOptions, stampStatus, StampService, registrationModal,
                                               loginModal, userService) {
      $scope.data = {
          termsAccepted: false,
          rulesVisible: false,
          stampStatus: stampStatus,
          stamps: [],
          stampsRequired: stampStatus.stamps !== stampStatus.stampLimit,
          requiresUserInput: !stampStatus.optedOut && !stampStatus.participates && $scope.isAuthenticated
      };

      $scope.status = {
          isOpen1: false,
          isOpen2: true
      };

      // Fill data object with corresponding stamps
      for (var iStamp = 1; iStamp <= stampStatus.stampLimit; iStamp++)
      {
          $scope.data.stamps[iStamp-1] = {
              filled: iStamp <= stampStatus.stamps,
              number: iStamp,
              rotation: Math.round((Math.random() * 36) - 18)
          };
      }

      this.participate = function() {
          var self = this;
          return StampService.participate().then(function() {
            self.refresh();
          });
      };

      this.optOut = function() {
          return StampService.optOut().then(function() {
              $scope.$dismiss();
          });
      };

      this.toggleRules = function() {
          $scope.data.rulesVisible = !$scope.data.rulesVisible;
      };

      this.login = function() {
          $scope.$dismiss();
          loginModal();
      };

      this.register = function() {
          $scope.$dismiss();
          registrationModal();
      };

      this.cancel = function() {
          $scope.$dismiss();
      };

      this.refresh = function() {
        StampService.single('/stamps/status').$resolve().$asPromise().then(function(stampStatus) {
          $scope.data.stampStatus = stampStatus;
          $scope.data.stampsRequired = stampStatus.stamps !== stampStatus.stampLimit;
          $scope.data.requiresUserInput = !stampStatus.optedOut && !stampStatus.participates && $scope.isAuthenticated;
        });
      };
  });
