'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.privacyPolicyModal
 * @description
 * # copyrightModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('privacyPolicyModal', function ($uibModal, $rootScope, gettextCatalog) {
    return function(_options) {
      var instance = $uibModal.open({
        animation: false,
        backdrop: 'static',
        templateUrl: 'views/privacy-policy-modal.html',
        controller: 'PrivacyPolicyModalCtrl',
        controllerAs: 'PrivacyPolicyModalCtrl',
        windowClass: 'privacy-policy-modal',
        resolve : {
          privacyPolicy: function() {
              return $rootScope.currentSite.latestPrivacyPolicy.$resolve().$asPromise();
          },
          modalOptions: function() {
            return angular.extend({
              cancelBtnText: gettextCatalog.getString('Cancel'),
              agreeBtnText: gettextCatalog.getString('I agree'),
              requireAgreement: true
            }, _options);
          }
        }
      });

      return instance.result;
    };
  });
