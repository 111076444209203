'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.reference
 * @description
 * # reference
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .factory('Reference', function ($window, restmod, slug) {
    return restmod.model('/references').mix({
      site: { belongsTo: 'Site' },
      $hooks: {
        'after-fetch': function() {
          this.$deeplink = this.ID + '/' + slug(this.title);
        }
      }
    });
  });
