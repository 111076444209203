'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:CompanySelectModalCtrl
 * @description
 * # CompanySelectModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('CompanySelectModalCtrl', function ($scope, modalOptions) {
        $scope.companies = modalOptions.models;
        $scope.formData = {
            selectedCompany: null
        };

        this.cancel = $scope.$dismiss;

        this.createNew = function () {
            $scope.$close(null);
        };

        this.submit = function () {
            if ($scope.companySelectForm.$valid)
            {
                $scope.$close($scope.formData.selectedCompany);
            }
        };
    });
