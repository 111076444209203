'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Playlist', function ($http, $window, restmod, downloadService, shareModal, $q, appAuthService, gettextCatalog,
                                   playService, RemotePlayQueue) {
        function changeFollow(playlist, state)
        {
            // Downloading requires a logged in user
            return appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to download tracks') }}).then(function() {
                // Toggle the state by default
                if (angular.isUndefined(state))
                {
                    state = !playlist.isFollowing;
                }

                // Owners can't follow their own playlists
                if (playlist.isOwner) {
                    return false;
                }

                var methodUrl = playlist.$url() + '/follow';
                if (state) {
                    return $http.put(methodUrl).then(function () {
                        playlist.isFollowing = true;
                        return playlist;
                    });
                }
                else {
                    return $http.delete(methodUrl).then(function () {
                        playlist.isFollowing = false;
                        return playlist;
                    });
                }
            });
        }

        return restmod.model('/playlists').mix('PagedModel', function() {
            this.setProperty('pageHeader', 'X-Pagination-Current-Page');
            this.setProperty('pageCountHeader', 'X-Pagination-Page-Count');
        }, {
            site: { belongsTo: 'Site', mask: 'U', key: 'siteID' },
            linkedTracks: { hasMany: 'PlaylistTrack', path : 'tracks' },
            folderCount: {mask:'CU'},
            folders: { hasMany: 'PlaylistFolder', path : 'folders' },
            fromCarrier: {mask:'CU'},
            reference: { belongsTo: 'Playlist', mask: 'U', key: 'referenceID'},
            shareSecret: {mask:'CU'},
            timeExport: {mask:'CU'},
            timestampChange: {mask:'CU'},
            timestampCreate: {mask:'CU'},
            trackCount: {mask:'CU'},
            isOwner: {mask:'CU'},
            isFavorite: {mask:'CU'},
            isFollowing: {mask:'CU'},
            type: 'playlist',
            $hooks: {
                'after-feed': function() {
                    this.$isSelected = false;
                    this.$isLoading = false;
                    this.$isOpen = this.isFolderFavorite;
                    if (this.$isOpen)
                    {
                        // Preload folders
                        this.folders.$resolve();
                    }
                }
            },
            $extend: {
                Model: {
                    $getFavorite: function() {
                        return this.$find('favorite').$then(function (playlist)
                        {
                            return playlist;
                        }, function () {
                            return null;
                        });
                    },

                    $getBySecret: function(secret) {
                        var self = this;
                        var methodUrl = this.$url() + '/by_secret?share_secret=' + secret;
                        return $http.get(methodUrl).then(function(result) {
                            return self.$buildRaw(result.data);
                        });
                    }
                },

                Record : {
                    getEditors: function() {
                        var methodUrl = this.$url() + '/get-editors';
                        return $http.get(methodUrl);
                    },

                    searchEditors: function(q) {
                        var methodUrl = this.$url() + '/search-editors?q='+q;
                        return $http.get(methodUrl).then(function(results){
                            return results.data.map(function(el){
                                return {'ID': el.ID, 'name': el.name+' ('+el.email+')'};
                            });
                        });
                    },

                    saveEditors: function(editors) {
                        var methodUrl = this.$url() + '/save-editors';
                        return $http.post(methodUrl,{ 'editors': editors});
                    },

                    play: function (options) {
                        options = angular.extend({ onlyWhenEmpty: false, startPaused : false }, options);

                        new RemotePlayQueue(this).load().then(function(queue) {
                            playService.playQueue(queue, options);
                        });
                    },

                    toggleFavorite : function(state) {
                        // Toggle local option
                        if (angular.isUndefined(state))
                        {
                            state = !this.isFavorite;
                        }
                        this.isFavorite = state;

                        var methodUrl = this.$url() + '/favorite';
                        if (this.isFavorite)
                        {
                            return $http.put(methodUrl);
                        }
                        else
                        {
                            return $http.delete(methodUrl);
                        }
                    },

                    export: function(format)
                    {
                        var methodUrl = this.$url() + '/export?format=' + format.toLowerCase();
                        $http.get(methodUrl).then(function(result) {
                            $window.location.href = result.data.downloadUrl;
                        });
                    },

                    clone: function(title)
                    {
                        var methodUrl = this.$url() + '/clone?name=' + encodeURIComponent(title);
                        return $http.post(methodUrl);
                    },

                    follow: function()
                    {
                        return changeFollow(this, true);
                    },

                    unfollow: function()
                    {
                        return changeFollow(this, false);
                    },

                    toggleFollow: function()
                    {
                        changeFollow(this);
                    },

                    share: function()
                    {
                        var model = this;

                        return model.getShareSecret().then(function (shareSecret) {
                            var shareUrl = $window.location.protocol + '//' + $window.location.hostname + '/playlists/' + shareSecret;

                            return shareModal({shareUrl: shareUrl, model: model});
                        });
                    },

                    searchSimilarTracks: function()
                    {
                        var model = this;
                    },

                    getShareSecret: function()
                    {
                        var deferred = $q.defer();

                        if (this.shareSecret !== null)
                        {
                            deferred.resolve(this.shareSecret);
                        }
                        else
                        {
                            var methodUrl = this.$url() + '/share';
                            $http.put(methodUrl).then(function(response) {
                                deferred.resolve(response.data.share_secret);
                            });
                        }

                        return deferred.promise;
                    },

                    download: function(trackIds) {
                        return downloadService.downloadPlaylist(this, trackIds, {});
                    },

                    downloadAs: function (trackIds) {
                        var options = this._getOptions(trackIds, {selectFormat: true});
                        return downloadService.downloadPlaylist(this, trackIds, options);
                    },

                    _getOptions: function(source, defaultOptions) {
                        var options =  angular.extend({},defaultOptions);

                        if(!source) {
                            return options;
                        }

                        if(source instanceof RemotePlayQueue) {
                            source = source.model;
                        }

                        if(angular.isDefined(source.type) && angular.isDefined(source.$pk)) {
                            options.sourceID = source.$pk;
                            if (source.type === 'playlist') {
                                options.source = 'project';
                            } else if(source.type === 'carrier') {
                                options.source = 'carrier';
                            }
                        }
                        return options;
                    },

                    deleteImage: function() {
                        var methodUrl = this.$url() + '/image';
                        return $http.delete(methodUrl);
                    },

                    setRead: function() {
                        var methodUrl = this.$url() + '/set-read';
                        var self = this;
                        return $http.put(methodUrl).then(function(result) {
                            return self.$decode(result.data);
                        });
                    },

                    removeFolder: function(folder)
                    {
                        var self = this;
                        return folder.$destroy().$then(function() {
                            self.folders.$remove(folder);
                            self.folders.$refresh();
                        });
                    },

                    sort: function(newSequences)
                    {
                        return $http.post(this.$url() + '/sort', { ids: newSequences });
                    },

                    sortFolders: function(newSequences)
                    {
                        return $http.post(this.$url() + '/sort-folders', { ids: newSequences });
                    },

                    removeTracks: function(trackIds) {
                        var methodUrl = this.$url() + '/tracks';
                        return $http.delete(methodUrl, { headers: { 'Content-Type': 'application/json' }, data: trackIds });
                    },

                    addTracks: function(trackIds) {
                        var methodUrl = this.$url() + '/tracks';
                        return $http.put(methodUrl, trackIds, { headers: { 'Content-Type': 'application/json' } });
                    }
                }
            }
        });
    });
