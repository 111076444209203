'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.dropbox
 * @description
 * # dropbox
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Dropbox', function ($http, restmod, configuration, growl, gettextCatalog) {
        var growlInstance = null;

        return restmod.model('/dropboxes').mix({
            site: {belongsTo: 'Site', key: 'siteID'},
            $extend: {
                Model: {
                    $searchBySite: function(siteId) {
                        return this.$search({ site_id: siteId });
                    }
                },
                Record: {
                    putTrack: function (trackId) {
                        var self = this;
                        return $http.put(configuration.apiUrlPrefix + '/dropboxes/track/' + trackId + '?format=' + this.format).then(function() {
                            if (growlInstance !== null) {
                                growlInstance.destroy();
                            }
                            growlInstance = growl.info('<i class="icon fa fa-check"></i> ' + gettextCatalog.getString('Track has been added to the {{format}} dropbox!', { format: self.format }), {
                                ttl: 3000,
                                disableIcons: true
                            });
                        });
                    }
                }
            }
        });
    });