(function(angular, undefined) {
    'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.randomSortMix
 * @description
 * # randomSortMix
 * Factory in the musicdirectorApp.
 */
angular.module('restmod').factory('RandomSortModel', ['restmod', function(restmod) {

    return restmod.mixin('PagedModel', function() {
        this.setProperty('pageHeader', 'X-Pagination-Current-Page');
        this.setProperty('pageCountHeader', 'X-Pagination-Page-Count');
    }, function() {
        this.setProperty('sortSeedHeader', 'X-Random-Sort-Seed')
            .on('before-fetch-many', function(_request) {
                // Check for sort on shuffle and request for page 2 or higher.
                if (angular.isDefined(_request.params.sort) &&
                    _request.params.sort === 'shuffle' &&
                    _request.params['sort-seed'] === undefined &&
                    this.$sortSeed !== null &&
                    _request.params.page !== undefined &&
                    _request.params.page > 1)
                {
                    _request.params['sort-seed'] = this.$sortSeed;
                }
            })
            .on('after-fetch-many', function(_response) {
                var sortSeed = _response.headers(this.$type.getProperty('sortSeedHeader'));

                this.$sortSeed = (sortSeed !== undefined ? sortSeed : null);
            });
    });
}]);})(angular);