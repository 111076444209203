'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Track', function ($http, $window, $sce, $filter, $state, restmod, playService, downloadService, appAuthService,
                                Playlist, infoModal, copyrightModal, gettextCatalog, shareModal, playlistEditModal,
                                trackEditModal, growl, $rootScope, PubSub, downloadCreditsModal, Analytics, userService,
                                RemotePlayQueue)
    {

        return restmod.model('/tracks').mix({
            lyrics: {hasOne: 'Lyrics'},
            carrier: { belongsTo: 'Carrier', key : 'carrierID' },
            otherVersions: { hasMany: 'Track' },
            otherStems: { hasMany: 'Track' },
            durationSeconds: { mask: 'CU'},
            composersValues: { mask: 'CU'},
            keywordsValues: { mask: 'CU'},
            $hooks: {
                'after-feed': function() {
                    this.init();
                },
                'before-render': function(record) {
                    record.duration = parseInt(this.durationSeconds) * 100;

                    // Join keyword values
                    if (this.keywordsValues &&
                        this.keywordsValues.length > 0)
                    {
                        record.keywords = '';
                        angular.forEach(this.keywordsValues, function(value, valueKey) {
                            if (valueKey > 0)
                            {
                                record.keywords += ' ';
                            }
                            record.keywords += value.text;
                        }, this);
                    }

                    // Join composer values
                    if (this.composersValues &&
                        this.composersValues.length > 0)
                    {
                        record.composers = '';
                        angular.forEach(this.composersValues, function(value, valueKey) {
                            if (valueKey > 0)
                            {
                                record.composers += ' ';
                            }
                            record.composers += value.text;
                        }, this);
                    }
                }
            },
            $extend: {
                Model: {
                  shareMultiple: function(trackIds) {
                    return shareModal({ model: this, multipleModelIds : trackIds });
                  }
                },

                Record: {
                    init: function() {
                        this.$isPlaying = playService.isTrackPlaying(this);
                        this.$isPaused = playService.isTrackPaused(this);
                        //this.$streamUrl = null;
                        //this.$streamUrlExpires = null;
                        //this.$waveformUrl = null;
                        this.$versionTabActive =  false;
                        this.$stemTabActive =  false;
                        this.$detailsTabActive =  false;
                        this.$durationString = $filter('duration')(this.duration);
                        this.durationSeconds = Math.round(parseInt(this.duration) / 100);

                        // FX site has the title and text fields switched
                        if ($rootScope.currentSite.type === 'fx')
                        {
                            this.title = this.text;
                            this.text = '';
                        }

                        // German site does not show composer splits/societies
                        if ($rootScope.currentSite.$pk === 12) {
                            this.composers = this.composerNames;
                            this.publishers = this.publisherNames;
                        }

                        if (this.text) {
                            this.$text = $filter('split')(this.text, '/[ ,;]/');
                        }

                        if (!this.versionSuffix ||
                            this.versionSuffix.length === 0)
                        {
                            this.versionSuffix = this.versionID === this.ID ? gettextCatalog.getString('Main version') : gettextCatalog.getString('Alternative version') + ' (' + (this.duration / 100) + ' sec)';
                        }
                    },

                    download: function(source) {
                        var self = this;
                        var options = self._getOptions(source, {});

                        var promise = downloadService.downloadTrack(this, options);
                        if (promise !== null) {
                            return promise.then(function(data) {
                                if(angular.isDefined(data.sourceID)){
                                    self._handleDownload(data.format, data.emailCopyrightInfo, data.sourceID, data.source);
                                } else {
                                    self._handleDownload(data.format, data.emailCopyrightInfo, false, false);
                                }
                            });
                        }
                    },

                    setSource: function(source) {
                        this.source = source;
                    },

                    share: function() {
                        var shareUrl;
                        if(window.location.pathname.indexOf('playlists') !== -1){
                            shareUrl = this.sharePublicTrack();
                        }else {
                            shareUrl = $window.location.protocol + '//' + $window.location.hostname + '/track/' + this.$pk;
                        }
                        return shareModal({ shareUrl : shareUrl, model: this });
                    },

                    sharePublicTrack: function() {
                        var pathname;
                        if($window.location.pathname.indexOf('track') !== -1){
                            pathname = $window.location.pathname.substring(0, $window.location.pathname.indexOf('track'));
                        } else {
                            pathname = $window.location.pathname + '/';
                        }
                        return $window.location.protocol + '//' + $window.location.hostname + pathname + 'track/' + this.$pk;
                    },

                    searchSimilarTracks: function() {
                        var track = this;
                        appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to use the MusicMatcher') }}).then(function() {
                            $state.go('root.search-results', {
                                scope: 'tracks',
                                referenceId: track.musimapId
                            }, { inherit: false, reload: 'root.search-results' });
                        });
                    },

                    downloadAs: function(source) {
                        if ($rootScope.currentSite.$pk === 20 && !$rootScope.isSiteAdmin) {
                            return false;
                        }

                        var self = this;
                        var options = self._getOptions(source, {selectFormat: true});

                        return downloadService.downloadTrack(this, options).then(function(data) {
                            if(angular.isDefined(data.sourceID)){
                                self._handleDownload(data.format, data.emailCopyrightInfo, data.sourceID, data.source);
                            } else {
                                self._handleDownload(data.format, data.emailCopyrightInfo, false, false);
                            }
                        });
                    },

                    _getOptions: function(source, defaultOptions) {
                        var options =  angular.extend({},defaultOptions);

                        if(!source) {
                            return options;
                        }

                        if(source instanceof RemotePlayQueue) {
                            source = source.model;
                        }

                        if(angular.isDefined(source.type) && angular.isDefined(source.$pk)) {
                            options.sourceID = source.$pk;
                            if (source.type === 'playlist') {
                                options.source = 'project';
                            } else if(source.type === 'carrier') {
                                options.source = 'carrier';
                            }
                        }
                        return options;
                    },

                    downloadAllVersions: function() {
                        return downloadService.downloadTrackVersionsStems(this, true, false);
                    },

                    downloadAllStems: function() {
                        return downloadService.downloadTrackVersionsStems(this, false, true);
                    },

                    downloadAllVersionsStems: function() {
                        return downloadService.downloadTrackVersionsStems(this, true, true);
                    },

                    _handleDownload: function(format, emailCopyrightInfo, sourceID, source)
                    {
                        var downloadUrl = this.$url() + '/download?format=' + format + '&emailCopyrightInfo=' +
                            (emailCopyrightInfo ? emailCopyrightInfo : false) + '&sourceID=' + sourceID +
                            '&source=' + source;

                        // AIFF downloads can take longer, show message
                        var growlInstance = growl.info('<i class="icon fa fa-spinner fa-spin"></i> ' +
                            gettextCatalog.getString('Preparing download...'), {
                            ttl: -1,
                            disableIcons: true,
                            clickToClose: false
                        });

                        return $http.get(downloadUrl).then(function (response) {
                            $window.location.href = response.data.downloadUrl;
                        }, function(response) {
                            if (response.status === 402) {
                                downloadCreditsModal(response.data);
                            }
                        }).finally(function() {
                            if (growlInstance !== null) {
                                growlInstance.destroy();
                            }
                        });
                    },

                    cut: function(start, duration)
                    {
                        var cutUrl = this.$url() + '/cut?start=' + start + '&stop=' + (start + duration);

                        return appAuthService.requireLogin({ modal : { message : 'Please login to download a track segment' }}).then(function() {
                            return $http.get(cutUrl).then(function (response) {
                                $window.location.href = response.data.downloadUrl;
                            }, function() {
                                infoModal({
                                    title: gettextCatalog.getString('Unable to download'),
                                    message: gettextCatalog.getString('We are currently unable to process your download request.'),
                                    closeButtonText: gettextCatalog.getString('Close')
                                });
                            });
                        });
                    },

                    copyright: function() {
                        var track = this;

                        track.carrier.$resolve().$then(function(carrier) {
                            carrier.label.$resolve().$then(function() {
                                var copyrightUrl = track.$url() + '/copyright';
                                $http.get(copyrightUrl).then(function(result) {
                                    copyrightModal({
                                        track: track,
                                        copyrightData: result.data.text
                                    });
                                });
                            });
                        });
                    },

                    addToPlaylist: function(playlist) {
                        var self = this;
                        var ID = self.ID ? self.ID : self.$pk;
                        return appAuthService.requireLogin({ modal : { message : 'Please login to favorite tracks' }}).then(function() {
                            var favoriteUrl = playlist.$url() + '/tracks/' + ID;
                            return $http.put(favoriteUrl).then(function(result) {
                                // show the message if the track could not be added to the playlist
                                if(result.data.status !== 'ok'){
                                    $window.alert(result.data.message);
                                }
                                // Refresh the queue if it's linked to this playlist
                                playService.refreshQueue(playlist);
                            }, function(/* result */) {
                                self.isFavorite = 0;
                            });
                        });
                    },

                    removeFromPlaylist: function(playlist) {
                        var self = this;
                        return appAuthService.requireLogin({ modal : { message : 'Please login to favorite tracks' }}).then(function() {
                            var favoriteUrl = playlist.$url() + '/tracks/' + self.ID;
                            return $http.delete(favoriteUrl).then(function() {
                                playlist.linkedTracks.$refresh({
                                    expand: 'track,track.carrier,carrier.label,carrier.categories',
                                    perPage: 100,
                                    page: 1
                                });
                                // Refresh the queue if it's linked to this playlist
                                playService.refreshQueue(playlist);

                            });
                        });
                    },

                    toggleFavorite : function(site) {
                        var self = this;
                        return appAuthService.requireLogin({ modal : { message : 'Please login to favorite tracks' }}).then(function() {
                            var favoriteUrl = Playlist.$new('favorite').$url() + '/tracks/' + self.ID + '?site_id=' + site.ID;

                            if (self.isFavorite)
                            {
                                self.isFavorite = 0;
                                return $http.delete(favoriteUrl).then(function() {
                                    PubSub.publish('track.onToggleFavorite', {track: self});
                                }, function(result) {
                                    if (result.status !== 404) {
                                        self.isFavorite = 1;
                                    }
                                });
                            }
                            else
                            {
                                self.isFavorite = 1;
                                return $http.put(favoriteUrl).then(function() {
                                    PubSub.publish('track.onToggleFavorite', {track: self});
                                }, function(result) {
                                    // Disable favorite status for now
                                    self.isFavorite = 0;

                                    // 404 means no favorite project, allow the user to create a new one
                                    if (result.status === 404)
                                    {
                                        var newPlaylist = Playlist.$build({ site : site });
                                        return playlistEditModal({
                                            model: newPlaylist,
                                            message: gettextCatalog.getString('Your account does not have an active playlist. Please enter a name for your new playlist.')
                                        }).then(function(/* playlist */) {
                                                // Add the carrier to the new playlist
                                                self.toggleFavorite(site);
                                            });
                                    }
                                });
                            }
                        });
                    },

                    play: function(scope, options) {
                        if (scope) {
                          this.setSource(scope);
                        }

                        var playTrack = function (scope, options) {
                            options = angular.extend({
                                onlyWhenEmpty: false,
                                startPaused: false
                            }, options);

                            // Check if the track is already loaded in the player
                            if (this.$isPlaying ||
                                this.$isPaused) {
                                // Toggle only when initialized by user
                                if (!options.onlyWhenEmpty) {
                                    playService.togglePlayPause();
                                }
                            } else if (!this.$streamUrl ||
                                // Fetch stream URL if none available or if expired
                                this.$streamUrlExpires < Math.floor(Date.now() / 1000)) {
                                var streamUrl = this.$url() + '/stream';
                                var self = this;

                                return $http.get(streamUrl, {ignoreLoadingBar: true}).then(function (response) {
                                    self.$streamUrl = response.data.streamUrl;
                                    self.$streamUrlExpires = response.data.streamUrlExpires;
                                    self.$waveformUrl = response.data.waveformUrl;
                                    return playService.playTrack(self, scope, options);
                                });
                            } else {
                                return playService.playTrack(this, scope, options);
                            }
                        }.bind(this);

                        if (!$rootScope.userCanPlay && !userService.isAuthenticated()) {
                            return appAuthService.requireLogin({modal: {message: gettextCatalog.getString('Please login to play tracks')}}).then(function () {
                                return playTrack(scope, options);
                            });
                        } else if (!$rootScope.userCanPlay) {
                            infoModal({
                                title: gettextCatalog.getString('Can\'t play this track'),
                                html: '<p>' + gettextCatalog.getString('User doesn\'t have enough rights to play tracks.') + '</p>',
                            });
                        } else {
                            return playTrack(scope, options);
                        }
                    },

                    logPlay: function()
                    {
                        // Track the play
                        Analytics.trackEvent('track', 'play', this.title, this.track);

                        var self = this;
                        var playUrl = this.$url() + '/play';
                        var playOptions = this._getOptions(this.source, { ignoreLoadingBar: true });
                        return $http.post(playUrl, playOptions).then(function(result) {
                            PubSub.publish('track.logPlay', [self, result.data]);
                        });
                    },

                    edit: function()
                    {
                        return trackEditModal({
                            model: this
                        });
                    }
                }
            }
        });
    });
