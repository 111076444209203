'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PasswordResetModalCtrl
 * @description
 * # PasswordResetModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PasswordResetModalCtrl', function ($scope, modalOptions, infoModal, Account, gettextCatalog,
                                                    languageService) {
      $scope.formData = {
        password: null
      };

      this.cancel = $scope.$dismiss;

      this.submit = function () {
        if ($scope.passwordResetForm.$valid) {
          Account.single('/account').resetPassword({
            token: modalOptions.token,
            password: $scope.formData.password,
            site_id: $scope.currentSite.$pk,
            language: languageService.getCurrentLanguage()
          }).then(function (result) {
            if (result.status === 200) {
              $scope.$close();
              infoModal({
                title: gettextCatalog.getString('Password has been changed'),
                message: gettextCatalog.getString('You can now login with your new password.')
              });
            }
          }, function (result) {
            // Format errors for use in info modal
            var errors = [];
            angular.forEach(result.data.errors, function (value, field) {
              angular.forEach(value, function (message) {
                errors.push({message: message, field: field});
              });
            });

            infoModal({
              title: gettextCatalog.getString('Check your input'),
              message: result.data.message,
              errors: errors
            });
          });
        }
      };
    });
