'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('RecentlyDownloaded', ['restmod', function (restmod) {
        return restmod.model('/recentlydownloaded').mix({
            track: { hasOne: 'Track' },
            carrier: { hasOne: 'Carrier' },
            label: { hasOne: 'Label' },
            $config: {
                name: 'recentlyDownloadedTrack',
                plural: 'recentlyDownloadedTracks'
            },
            $extend: {
                Record: {
                    play: function (scope, options) {
                        return this.track.play(scope, options);
                    }
                }
            }
        });
    }]);
