'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.loginModal
 * @description
 * # loginModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('carrierEditModal', function($uibModal) {
      return function(_options) {
        var instance = $uibModal.open({
          animation: false,
          templateUrl: 'views/carrier-edit-modal.html',
          controller: 'CarrierEditModalCtrl',
          controllerAs: 'CarrierEditModalCtrl',
          windowClass: 'carrier-edit-modal',
          backdrop: 'static',
          resolve: {
            options : function() {
              return _options;
            },
            carrier: function() {
              if (angular.isDefined(_options.model)) {
                return _options.model.$refresh({expand: 'categories,thumbnails,label'}).$asPromise();
              } else {
                return false;
              }
            }
          }
        });

        return instance.result;
      };
    });
