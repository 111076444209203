'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:setClassWhenAtTop
 * @description
 * # setClassWhenAtTop
 */
angular.module('musicdirectorApp')
    .directive('searchResults', function () {
        return {
            restrict: 'E',
            scope: {
                enableLoadSave: '=?',
                enableTracks: '=?',
                enableSearchWords: '=?',
                sortNewest: '=?',
                initScope : '=?',
                allAlbums: '=?'
            },
            templateUrl: 'views/search-results.html',
            controller: 'SearchResultsCtrl'
        };
    });
