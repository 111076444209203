'use strict';

/**
 * @ngdoc function
 * @name webappMusicdirectorComApp.controller:NavbarCtrl
 * @description
 * # NavbarCtrl
 * Controller of the webappMusicdirectorComApp
 */
angular.module('musicdirectorApp')
    .controller('NavbarCtrl', function ($rootScope, $scope, $state, PubSub, Playlist, languageService) {
        $scope.activeRoute = $state.current.name;
        $scope.isPlaylistPage = $state.params.url === 'playlists';
        $scope.data = {
            newPlaylistCount: 0,
            currentSite: $rootScope.currentSite,
            languageService: languageService,
        };

        $scope.refreshNewPlaylistCount = function() {
          Playlist.single('/playlists/unread-count').$resolve({'site_id': $rootScope.currentSite.$pk}).$asPromise().then(function (result) {
            $scope.data.newPlaylistCount = result.count;
          });
        };

        PubSub.subscribe('playlist.select', function() {
          $scope.refreshNewPlaylistCount();
        });

        $scope.refreshNewReleases = function()
        {
            PubSub.publish('homeCtrl.refreshNewReleases');
        };

        $scope.resetCatalogueLetterFilter = function()
        {
            if ($state.current.name === 'root.catalogue') {
                PubSub.publish('CatalogueCtrl.resetLetterFilter');
            }
        };

        $rootScope.$on('$stateChangeStart', function(event, toState, toParams) {
            var route = toState;
            $scope.isPlaylistPage = toParams.url === 'playlists';
            while (route.parent.abstract !== true)
            {
                route = route.parent;
            }
            $scope.activeRoute = route.name;
        });

        $rootScope.$on('$viewContentLoaded', function(event) {
            $scope.isPlaylistPage = $state.params.url === 'playlists';
        });
    });
