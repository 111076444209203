'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:newsItem
 * @description
 * # newsItem
 */
angular.module('musicdirectorApp')
  .directive('newsItem', function ($state, videoModal, $window, $location, languageService) {
    return {
      restrict: 'A',
      replace: true,
      scope: {
        item: '=newsItem'
      },
      templateUrl: 'views/news-item.html',
      link: function postLink(scope, element, attrs) {
        scope.options = {
            hideTopBanner: attrs.hideTopBanner ? scope.$eval(attrs.hideTopBanner) : false,
            itemClass: attrs.itemClass ? attrs.itemClass : 'col-md-4'
        };

        scope.handleActionClick = function()
        {
          switch (scope.item.type)
          {
            case 'catalogue':
              if (scope.item.catalogue !== null)
              {
                return $state.go('root.catalogue.detail', {catalogueId: scope.item.catalogue.ID});
              }
              break;

            case 'carrier':
              if (scope.item.carrier !== null)
              {
                scope.item.carrier.play();
                return $state.go('root.carrier', {carrierId: scope.item.carrier.ID});
              }
              break;

            case 'video':
              videoModal({ model: scope.item });
              break;

            default:
                if (scope.item.url.match(/^\//) !== null)
                {
                  $location.path(scope.item.url);
                }
                else
                {
                  $window.location.href = scope.item.url;
                }
                break;
          }
        };

        scope.handleItemClick = function()
        {
          switch (scope.item.type)
          {
            case 'carrier':
              if (scope.item.carrier !== null)
              {
                return $state.go('root.carrier', {carrierId: scope.item.carrier.ID});
              }
              break;

            default:
              return scope.handleActionClick();
          }
        };

        scope.handleTitleClick = function()
        {
            switch (scope.item.category.title)
            {
                case 'New productions':
                case 'Playlist':
                    if (scope.item.carrier !== null)
                    {
                        return $state.go('root.pages', {language: languageService.getCurrentLanguage(), url: 'playlists'});
                    }
                    break;
                default:
                    return scope.handleItemClick();
            }
        };
      }
    };
  });
