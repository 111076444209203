'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:CarrierEditModalCtrl
 * @description
 * # CarrierEditModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('CarrierEditModalCtrl', function ($scope, $state, $window, options, carrier,
                                                Upload, Carrier, lodash, gettextCatalog) {
      $scope.options = options;

      if (carrier) {
        $scope.model = Carrier.$new(carrier.$pk).$extend(carrier);
        this.existingModel = carrier;
      }

      this.convertBoolean = function(){
          $scope.model.visible = + $scope.model.visible;
      };
      this.convertInteger = function(){
          $scope.model.visible = !!+$scope.model.visible;
      };

      this.convertInteger();

      var identifyVersionsMethodLabels = {
          1: gettextCatalog.getString('Automatic'),
          2: gettextCatalog.getString('Brackets (XX)'),
          3: gettextCatalog.getString('Seconds (XX SEC)'),
          4: gettextCatalog.getString('Dashes XX - XX'),
          5: gettextCatalog.getString('Title prefixes')
      };
      var identifyVersionsSortingMethodLabels = {
          1: gettextCatalog.getString('Prefix, Duration'),
          2: gettextCatalog.getString('Prefix, Track number')
      };

      // Get all category titles
      $scope.availableCategories = $scope.currentSite.categories.$refresh({ 'per-page' : -1, fields: 'ID,title', 'include_empty': true });
      $scope.linkedCategories = $scope.model.categories;
      $scope.data = {
        isSaving: false,
        showPrefixLength: false,
        identifyVersionsLabelDefaults: {
            method: carrier.label.defaultIdentifyVersionsMethod,
            methodVerbose: identifyVersionsMethodLabels[carrier.label.defaultIdentifyVersionsMethod],
            prefixLength: carrier.label.defaultIdentifyVersionsPrefixLength,
            sortingMethod: carrier.label.defaultIdentifyVersionsSortingMethod,
            sortingMethodVerbose: identifyVersionsSortingMethodLabels[carrier.label.defaultIdentifyVersionsSortingMethod],
        }
      };

      $scope.findCategories = function(query) {
        return $scope.availableCategories.$asList(function(_categories) {
          return lodash.filter(_categories, function(_category) {
            return _category.title.match(new RegExp(query, 'i'));
          });
        });
      };

      $scope.updateShowPrefixLength = function() {
          // show prefix length field when method is automatic or title prefix
          $scope.data.showPrefixLength = ($scope.model.identifyVersionsMethod === 1 || $scope.model.identifyVersionsMethod === 5)
              || (($scope.model.identifyVersionsMethod === null || $scope.model.identifyVersionsMethod === 0)
                  && ($scope.model.label.defaultIdentifyVersionsMethod === 1 || $scope.model.label.defaultIdentifyVersionsMethod === 5));
      };
      $scope.updateShowPrefixLength();

      this.cancel = function() {
        $scope.$dismiss();
      };


      this.finalize = function() {
        if (this.existingModel !== null) {
          this.existingModel.$extend($scope.model);
        }

          // Close modal
        $scope.$close($scope.model);

        // Reload current state to see carrier changes
        if ($state.current.name === 'root.carrier') {
            $window.location.reload();
        }
      };

      this.submit = function () {
        $scope.data.isSaving = true;

        var self = this;

        this.convertBoolean();

        // Update the existing model
        $scope.model.$save(['title', 'text', 'categoryIds', 'identifyVersionsMethod', 'identifyVersionsPrefixLength', 'identifyVersionsSortingMethod', 'visible']).$then(function() {
          if ($scope.data.newImage) {
            // Save the new image
            Upload.upload({
              url: $scope.model.$url() + '/image',
              file: $scope.data.newImage
            }).success(function (/* data, status, headers, config */) {
              // Reload model to get the thumbnail information
              $scope.model.$refresh();

              self.finalize();
            }).error(function (/* data, status, headers, config */) {
              // @todo Something went wrong!
              $scope.data.isSaving = false;
            });
          }
          else {
            self.finalize();
          }
        }, function() {
          // Save failed, show error!?
          $scope.data.isSaving = false;
        });
      };
    });
