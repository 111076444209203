'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('SearchResult', function (restmod)
    {
        return restmod.model('/search/results').mix({
          track: { hasOne: 'Track' },
          carrier: { hasOne: 'Carrier' }
        });
    });
