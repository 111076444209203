'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.stamps
 * @description
 * # stamps
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('usernameChangeService', function ($http, userService) {

      function getIsChangeRequired() {
          return userService.getAccount().then((account) => {
              // do initial check for legacy username
              if (account.email.length &&
                  (account.username.match(/@/) === null ||
                      account.username.match(/\./) === null)
              ) {
                  return account.checkUpdateLegacyUsername().then((result) => (result?.data?.status === 'ok'));
              }

              // no change required
              return false;
          });
      }

      return {
          getIsChangeRequired,
      }
  });
