'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:track
 * @description
 * # track
 */
angular.module('musicdirectorApp')
    .directive('playlist', function ($state, $location, $timeout) {
        return {
            restrict: 'E',
            replace: true,
            require: '^playlistsBar',
            templateUrl:  function(){
                var myMusic = $location.url() === '/my-music';
                if(myMusic){
                    return 'views/playlist-dnd.html';
                } else {
                    return 'views/playlist.html';
                }
            },
            scope: {
                playlist: '=',
                hideMenu: '@',
                openPlaylistOnClick: '@',
                enableImporting: '@',
            },
            link: function (scope, element, attrs, playlistsBarCtrl) {
                scope.playlistsBarCtrl = playlistsBarCtrl;

                scope.selectPlaylist = function(model) {
                    model = model || this.playlist;

                    if (scope.openPlaylistOnClick)
                    {
                        $state.go('root.playlists.detail', { playlistId: model.$pk });
                    }
                    else
                    {
                        element.addClass('selected').siblings().removeClass('selected');

                        playlistsBarCtrl.selectPlaylist(model);
                    }
                };
            },
            controllerAs: 'PlaylistCtrl',
            controller: 'PlaylistCtrl'
        };
    });
