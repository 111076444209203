'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:RegistrationBundeswehrCtrl
 * @description
 * # RegistrationBundeswehrCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('RegistrationBundeswehrCtrl', function ($rootScope, $scope, $sce, $q, gettextCatalog, jobTitles, infoModal,
                                                   companySelectModal, Country, Account, Company, modalOptions,
                                                   registrationPage, registrationFields, languageService, Analytics,
                                                   privacyPolicyModal, companies)
    {
        $scope.companies = [{ID: 0, title : gettextCatalog.getString('Select company')}];
        angular.forEach(companies, function(value) {
            $scope.companies.push({ ID: value.ID, title: value.company });
        });

        $scope.jobTitles = [{ID: 0, title: gettextCatalog.getString('Select job title')}];
        angular.forEach(jobTitles, function(value) {
            $scope.jobTitles.push({ ID: value.ID, title: value.title });
        });

        $scope.registration = {};
        $scope.registration.jobTitle = ($scope.currentSite.$pk === 18 || $scope.currentSite.$pk === 12) ? 0 : null;
        $scope.registration.companyID = 0;
        $scope.data = {
            registrationPage: registrationPage,
            isLoading: false,
            formHasErrors: false,
            bundeswehrEmailError: false,
            privacyPolicyLinkText: gettextCatalog.getString('I agree to the {{shortName}} {{privacyPolicyLink}}',
              {
                  privacyPolicyLink: '<a href ng-click="showPrivacyPolicy()">' + gettextCatalog.getString('Privacy Policy') + '</a>',
                  shortName: $scope.currentSite.shortName
              }),
            termsAndConditionsLinkText: gettextCatalog.getString('I agree to the {{shortName}} {{termsAndConditionsLink}}',
              {
                  termsAndConditionsLink: '<a href="https://static.bmgproductionmusic.nl/documents/' + $scope.currentSite.$pk + '/termsandconditions.pdf" target="_blank">' + gettextCatalog.getString('Terms and conditions') + '</a>',
                  shortName: $scope.currentSite.shortName
              }),
            siteID : $scope.currentSite.$pk,
            imraPrivacyPolicyText: gettextCatalog.getString(' We use Mailchimp as our marketing automation platform. By ticking the box for newsletter subscription, you acknowledge that the information you provide will be transferred to Mailchimp for processing in accordance with their {{privacyPolicyLink}} and {{termsAndConditionsLink}}.',
            {
                privacyPolicyLink: '<a href="https://mailchimp.com/legal/privacy/" target="_blank">' + gettextCatalog.getString('Privacy Policy') + '</a>',
                termsAndConditionsLink: '<a href="https://mailchimp.com/legal/terms/" target="_blank">' + gettextCatalog.getString('Terms') + '</a>'
            }),
        };
        $scope.registrationFields = {};
        if (registrationFields.data !== undefined && registrationFields.data.registrationFields !== undefined) {
            angular.forEach(registrationFields.data.registrationFields, function (registrationField) {
                $scope.registrationFields[registrationField.attribute] = {required: registrationField.required};
            });
        }

        // Track the completion as pageview
        Analytics.trackPage('/account/register-bundeswehr', 'Register');

        this.cancel = $scope.$dismiss;

        this.showPrivacyPolicy = function() {
          return privacyPolicyModal({
            requireAgreement: false,
            cancelBtnText: gettextCatalog.getString('Close')
          });
        };

        $scope.saveAccount = function() {
            $scope.data.isLoading = true;

            var newAccount = Account.$build($scope.registration);
            newAccount.language = languageService.getCurrentLanguage();
            for (var i = 0, len = companies.length; i < len; i++) {
                if (companies[i].$pk === $scope.registration.companyID) {
                    newAccount.company = companies[i];
                    break;
                }
            }

            newAccount.$save().$then(function(account) {
                infoModal({
                    title: gettextCatalog.getString('Welcome to {{siteName}}!', { siteName: $scope.currentSite.shortName }),
                    message: gettextCatalog.getString('Thank you for registering for access to {{siteName}}, the ultimate music platform for searching music for commercials, radio, television, internet and other audiovisual applications.', { siteName: $scope.currentSite.shortName }) + ' ' +
                        gettextCatalog.getString('Your application will be assessed shortly and you will be emailed when we confirm the account.')
                });
                $scope.$dismiss();

            }, function(model) {
                var errors = [];

                switch (model.$response.status) {
                    case 500:
                        errors.push({ message: model.$response.data.message });
                        break;
                    case 400:
                        model.$response.data.errors.forEach(function(errorField){
                            errorField.forEach(function(error){
                                errors.push({ message: error });
                            });
                        });
                        break;

                    default:
                        errors = model.$response.data;
                        break;
                }

                infoModal({
                    title: gettextCatalog.getString('We could not complete the registration'),
                    errors: errors,
                    message: gettextCatalog.getString('One or more errors prevented the registration:'),
                    closeButtonText: gettextCatalog.getString('OK')
                });
                $scope.data.isLoading = false;
            });
        };

        this.validateBundeswehrEmail = function (email) {
            return email !== undefined &&
                (email.indexOf('@bundeswehr.org') > 0 ||
                    email.indexOf('@bmvg.bund.de') > 0);
        };

        this.submit = function () {
            // Show form validation results
            $scope.$broadcast('show-errors-check-validity');
            if (this.validateBundeswehrEmail($scope.registration.bundeswehr_email) &&
                $scope.registration.privacyPolicy && $scope.registrationForm.$valid) {
                $scope.data.isLoading = true;

                if ($scope.currentSite.requirePpAgreement) {
                    $rootScope.currentSite.latestPrivacyPolicy.$resolve().$asPromise().then(function(results){
                        $scope.registration.ppAgreedTo = results.$pk;
                        $scope.registration.ppAgreedBy = $scope.registration.contactFirstname + ' ' + $scope.registration.contactLastname;
                        $scope.registration.ppDateAgreed = (new Date()).toISOString().split('.')[0] + '+00:00';
                        $scope.saveAccount();
                    });

                }
                else {
                    $scope.saveAccount();
                }
            }
            else
            {
                infoModal({
                    title: gettextCatalog.getString('Please check your input'),
                    message: gettextCatalog.getString('One or more fields contained errors. Please correct them and try again.'),
                    closeButtonText: gettextCatalog.getString('OK')
                });
                $scope.data.isLoading = false;
                $scope.data.formHasErrors = true;
                if (!this.validateBundeswehrEmail($scope.registration.bundeswehr_email)) {
                    $scope.data.bundeswehrEmailError = true;
                } else {
                    $scope.data.bundeswehrEmailError = false;
                }
            }
        };

        // Hide popup on state change
        var stateChangeBind = $rootScope.$on('$stateChangeSuccess', function() {
            $scope.$dismiss();
        });
        $scope.$on('$destroy', stateChangeBind);

    });
