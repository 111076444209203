'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.seoData
 * @description
 * # seoData
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .provider('seoData', function() {
      var defaultData = {
        pageTitle: '',
        pageTitleSuffix: '',
        pageTitlePrefix: '',
        pageImage: '',
        metaDescription: '',
        metaKeywords: [],
        noSuffixWhenTitleIs: [],
      };

      return {
        setDefaultPageTitle: function(title) {
          defaultData.pageTitle = title;
          return this;
        },
        setDefaultPageImage: function(image) {
          defaultData.pageImage = image;
          return this;
        },
        setPageTitlePrefix: function(suffix) {
          defaultData.pageTitlePrefix = suffix;
          return this;
        },
        setPageTitleSuffix: function(suffix) {
            defaultData.pageTitleSuffix = suffix;
            return this;
        },
        setDefaultMetaDescription: function(description) {
          defaultData.metaDescription = description;
          return this;
        },
        setNoSuffixWhenTitleIs: function(noSuffixWhenTitleIs) {
            defaultData.setNoSuffixWhenTitleIs = noSuffixWhenTitleIs;
            return this;
        },
        $get: function() {
          var currentData = {
            pageTitle: '',
            pageImage: '',
            metaDescription: '',
            metaKeywords: []
          };

          return {
            get pageTitle() {
              return currentData.pageTitle.length &&
              defaultData.setNoSuffixWhenTitleIs.indexOf(currentData.pageTitle) === -1 ?
                currentData.pageTitle + defaultData.pageTitleSuffix
                : defaultData.pageTitle;
            },
            get pageImage() {
              return (currentData.pageImage && currentData.pageImage.length) ?  currentData.pageImage : defaultData.pageImage;
            },
            get metaDescription() {
              return (currentData.metaDescription && currentData.metaDescription.length) ?  currentData.metaDescription : defaultData.metaDescription;
            },
            set pageTitle(title) {
              currentData.pageTitle = title;
            },
            set pageImage(image) {
              currentData.pageImage = image;
              if (currentData.pageImage &&
                  currentData.pageImage.match(/^\/\//) !== null)
              {
                currentData.pageImage = 'https:' + currentData.pageImage;
              }
            },
            set metaDescription(description) { currentData.metaDescription = description; },
            revertToDefault: function() { currentData = angular.copy(defaultData); }
          };
        }
      };
    });