'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.dropboxService
 * @description
 * # dropboxService
 * Service in the musicdirectorApp.
*/
angular.module('musicdirectorApp')
    .factory('dropboxService', function (Dropbox, PubSub, $rootScope, userService) {
        var dropboxes = null;

        function refreshDropboxes() {
            if (userService.isAuthenticated()) {
                dropboxes = Dropbox.$searchBySite($rootScope.currentSite.$pk);
            } else {
                dropboxes = null;
            }

            return dropboxes;
        }

        function getDropboxes() {
            if (dropboxes === null) {
                return refreshDropboxes();
            } else {
                return dropboxes;
            }
        }

        // Refresh dropboxes when user changes
        PubSub.subscribe('userService.onCurrentUserChange', function() {
            refreshDropboxes();
        });

        return {
            get dropboxes() {
                return getDropboxes();
            },
            load: function() {
                return refreshDropboxes();
            }
        };
    });
