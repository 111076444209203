'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:stopEvent
 * @description
 * # stopEvent
 */
angular.module('musicdirectorApp')
    .directive('stopEvent', function () {
return {
    restrict: 'A',
    link: function (scope, element, attr) {
        var events = attr.stopEvent ? attr.stopEvent.split(/,/) : ['click'];

        var handler = function (e) {
            e.stopPropagation();
        };

        angular.forEach(events, function(eventName) {
            element.bind(eventName, handler);
        });

        scope.$on('$destroy', function () {
            angular.forEach(events, function(eventName) {
                element.unbind(eventName, handler);
            });
        });
    }
};
});
