'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistEditModalCtrl
 * @description
 * # PlaylistEditModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistEditModalCtrl', function ($scope, $q, Playlist, PlaylistFolder, options, Upload) {
        // Use an existing model for editing or create a new one
        $scope.options = options;
        var mode = 'create';

        if (options.folder)
        {
            mode = 'folder';
        }

        $scope.data = {
            mode : mode,
            update: angular.isDefined(options.model) && angular.isDefined(options.model.$pk),
            isSaving: false,
            imageCleared: false,
            newImage: null,
            addAsActive: angular.isDefined(options.addAsActive) ? options.addAsActive : true
        };
        this.existingModel = null;

        if (mode === 'folder')
        {
            $scope.model = PlaylistFolder.$new(options.model.$pk, options.model.$scope).$extend(options.model);
        }
        else
        {
            $scope.model = Playlist.$new(options.model.$pk).$extend(options.model);
        }
        this.existingModel = options.model;

        this.cancel = function() {
            $scope.$dismiss();
        };

        this.finalize = function() {
            if (this.existingModel !== null) {
                this.existingModel.$extend($scope.model);
            }

            if ($scope.data.addAsActive)
            {
                return $scope.model.toggleFavorite(true).then(function() {
                    $scope.$close($scope.model);
                });
            }
            else
            {
                $scope.$close($scope.model);
            }
        };

        $scope.clearImage = function()
        {
            $scope.image = null;
            $scope.model.thumbnails = null;
            $scope.data.imageCleared = true;
        };

        this.submit = function () {
            $scope.data.isSaving = true;

            // Remove existing image, when cleared
            var imagePromise = $q(function(resolve, reject) {
                if ($scope.data.imageCleared)
                {
                    // Remove the existing image
                    $scope.model.deleteImage().then(function() {
                        resolve();
                    }, function() {
                        $scope.data.isSaving = false;
                        reject();
                    });
                }
                else
                {
                    resolve();
                }
            });

            var self = this;
            imagePromise.then(function() {
                // Update the existing model
                $scope.model.$save().$then(function() {

                    if ($scope.data.newImage) {
                        // Save the new image
                        Upload.upload({
                            url: $scope.model.$url() + '/image',
                            file: $scope.data.newImage
                        }).success(function (/* data, status, headers, config */) {
                            // Reload model to get the thumbnail information
                            $scope.model.$refresh();

                            self.finalize();
                        }).error(function (/* data, status, headers, config */) {
                            // @todo Something went wrong!
                            $scope.data.isSaving = false;
                        });
                    }
                    else {
                        self.finalize();
                    }
                }, function() {
                    // Save failed, show error!?
                    $scope.data.isSaving = false;
                });
            });
        };
    });