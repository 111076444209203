'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistDetailCtrl
 * @description
 * # PlaylistDetailCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistDetailCtrl', function ($scope, playlist, seoData, gettextCatalog, track, $location, $anchorScroll, $rootScope) {
        $scope.data = {
            playlist : playlist,
            coverImage: null,
            pageLimit : 10,
            fetchOptions: [],
            loadMoreDisabled: true,
            loadingResults: true,
            folderPageOptions: [],
            track: track,
            highlightedTrack: [track],
            trackCount: null,
            trackPosition: null,
            sharedTrackText: 'Please wait for the tracks to load',
            noResults: false,
        };
        $anchorScroll.yOffset = 100;

        var fetchOptions = {expand: 'track,track.lyrics,track.carrier,carrier.label,carrier.categories'};
        var folderFetchOptions = {};

        var sharedTrack = function(tracks){
            if($scope.data.track !== null && $scope.data.track.$pk) {
                $scope.data.trackCount = tracks.length;
                var counter = 0;
                angular.forEach(tracks, function (track) {
                    counter++;
                    if ($scope.data.track.$pk === track.track.$pk) {
                        track.track.$isPaused = true;
                        $scope.data.trackPosition = counter;
                        $scope.data.sharedTrackText = 'Go to shared track';
                    } else {
                        track.track.$isPaused = false;
                    }
                });
            }
        };

        // Add share secret when user is not owner of following the playlist
        if (!playlist.isOwner &&
            !playlist.isFollowing &&
            playlist.shareSecret)
        {
            fetchOptions.share_secret = playlist.shareSecret;
            folderFetchOptions.share_secret = playlist.shareSecret;
        }

        if (playlist.thumbnails &&
            playlist.thumbnails['250x250'])
        {
            $scope.data.coverImage = playlist.thumbnails['250x250'];
        }
        if (playlist.trackCount > 0)
        {
            fetchOptions['perPage'] = $scope.data.pageLimit;
            fetchOptions['page'] = 1;
            playlist.linkedTracks.$fetch(fetchOptions).$then(function(tracks) {
                if ($scope.data.coverImage === null)
                {
                    if (tracks[0] &&
                        tracks[0].track)
                    {
                        $scope.data.coverImage = tracks[0].track.carrier.thumbnails['250x250'] + $rootScope.addCacheBuster(tracks[0].track.carrier.timeChange);
                    }
                }
                sharedTrack(tracks);
                $scope.data.loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
                $scope.data.loadingResults = false;
            });
        }
        $scope.data.fetchOptions = fetchOptions;

        // Update SEO data
        seoData.pageTitle = playlist.title + ' ' + gettextCatalog.getString('playlist');
        seoData.metaDescription = playlist.text;
        seoData.pageImage = $scope.data.coverImage;

        // Fetch all folders for this playlist
        playlist.folders.$refresh(folderFetchOptions).$then(function(folders){
            angular.forEach(folders, function(folder) {
                $scope.data.folderPageOptions[folder.$pk] = {
                    loadMoreDisabled : true,
                    loadingResults: false,
                    fetchOptions: fetchOptions,
                };
            });
        });

        if (playlist.trackCount === 0 && playlist.folders.length === 0) {
            $scope.data.loadingResults = false;
            $scope.data.noResults = true;
        }

        $scope.initFolder = function(folder)
        {
            if (folder.trackCount > 0)
            {
              $scope.data.folderPageOptions[folder.$pk].loadMoreDisabled = true;
              $scope.data.folderPageOptions[folder.$pk].loadingResults = true;
                folder.linkedTracks.$resolve(angular.extend($scope.data.folderPageOptions[folder.$pk].fetchOptions, {page: 1})).$then(function(tracks){
                    $scope.data.folderPageOptions[folder.$pk].loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
                    $scope.data.folderPageOptions[folder.$pk].loadingResults = false;
                });
            }
        };

        $scope.loadMoreTracks = function()
        {
            $scope.data.loadMoreDisabled = true;
            $scope.data.loadingResults = true;
            $scope.data.fetchOptions['perPage'] = $scope.data.pageLimit;
            $scope.data.fetchOptions['page'] = playlist.linkedTracks.$metadata.currentPage + 1;
            playlist.linkedTracks.$fetch($scope.data.fetchOptions).$then(function(tracks) {
                $scope.data.loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
                $scope.data.loadingResults = false;

                // track is being shared
                if(track){
                    sharedTrack(tracks);
                    if($scope.data.trackPosition === null){
                        $scope.loadMoreTracks();
                    }
                }
            });
        };

        $scope.loadMoreFolderTracks = function(folder)
        {
            if (folder.trackCount > 0) {
              $scope.data.folderPageOptions[folder.$pk].loadMoreDisabled = true;
              $scope.data.folderPageOptions[folder.$pk].loadingResults = true;
              folder.linkedTracks.$fetch(angular.extend($scope.data.folderPageOptions[folder.$pk].fetchOptions, {page: folder.linkedTracks.$metadata.currentPage + 1})).$then(function () {
                  $scope.data.folderPageOptions[folder.$pk].loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
                  $scope.data.folderPageOptions[folder.$pk].loadingResults = false;
              });
            }
        };

        if(track){
            // Laadt de shared track in de player
            track.play(false,{startPaused: true});
        } else {
            // Laadt de basistracks in de player
            playlist.play({startPaused: true, onlyWhenEmpty: true});
        }

        $scope.goToSharedTrack = function(x){
            var newHash = 'track-row-' + x;
            if ($location.hash() !== newHash) {
                $location.hash('track-row-' + x);
            } else {
                $anchorScroll();
            }
        };

        $scope.download = function(event, playlist){
            event.stopPropagation();
            event.preventDefault();
            playlist.download();
        };
    });
