'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Label', function ($rootScope, $http, $window, restmod, playService, RemotePlayQueue, infoModal,
                                userService, appAuthService, gettextCatalog, uploadAudioModal, $stateParams) {
        return restmod.model('/labels').mix('PagedModel', function() {
            this.setProperty('pageHeader', 'X-Pagination-Current-Page');
            this.setProperty('pageCountHeader', 'X-Pagination-Page-Count');
        }, {
            carriers: { hasMany: 'Carrier' },
            rating: { hasOne: 'LabelRating'},
            relatedLabels: { hasMany: 'Label' },
            allRelatedLabels: { hasMany: 'Label' },
            randomRelatedLabels: { hasMany: 'Label' },
            $hooks: {
                'after-feed': function () {
                    this.$searchKey = this.title.toLowerCase();
                    this.isSystemAdmin = userService.isSystemAdmin();
                }
            },
            $extend: {
                Record: {
                    play: function (options) {
                        options = angular.extend({ onlyWhenEmpty: false, startPaused : false }, options);

                        var playTrack = function(options, _model) {
                          // Use the click event to play a silent MP3, to circumvent iOS restrictions
                          playService.playSilentFirst();

                          new RemotePlayQueue(_model).load().then(function (queue) {
                            playService.playQueue(queue, options);
                          });
                        };
                        if (!$rootScope.userCanPlay && !userService.isAuthenticated()) {
                            return appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to play tracks') }}).then(function() {
                                $rootScope.uploadAudioModalInstance = uploadAudioModal({
                                    mode: 'audioUploader',
                                    url: $stateParams.url
                                });
                            }).finally(function() {
                                return playTrack(options, this);
                            });
                        }
                        else if (!$rootScope.userCanPlay) {
                            infoModal({
                                title: gettextCatalog.getString('Can\'t play this track'),
                                html: '<p>' + gettextCatalog.getString('User doesn\'t have enough rights to play tracks.') + '</p>',
                            });
                        }
                        else {
                            return playTrack(options, this);
                        }
                    },

                    generateTrackWaveforms: function()
                    {
                        return $http.post(this.$url() + '/generate-track-waveforms');
                    },

                    updateTrackDurations: function()
                    {
                        return $http.post(this.$url() + '/update-track-durations');
                    },

                    identifyVersions: function()
                    {
                        return $http.post(this.$url() + '/identify-versions').then(function(response) {
                            $window.location.reload();
                        });
                    },
                }
            }
        });
    });
