'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:trackDetails
 * @description
 * # trackDetails
 */
angular.module('musicdirectorApp')
    .directive('trackDetails', function ($state, $uibModal, gettextCatalog) {
        return {
            restrict: 'A',
            replace: true,
            require: '^trackList',
            scope: {
                trackContainer: '=trackDetails',
                playScope: '=',
            },
            templateUrl: 'views/track-details.html',
            controller: function($scope)
            {
                this.getCurrentSite = function() {
                    return $scope.currentSite;
                };
                this.getScopeAttribute = function(attr) {
                    return $scope[attr];
                };
            },
            link: function(scope, element, attrs, trackListCtrl) {
                // Get scope variables form list controller
                angular.forEach(['listViewOptions', 'currentSite'], function(value) {
                    scope[value] = trackListCtrl.getScopeAttribute(value);
                });

                scope.tabs = [
                    {active: true},
                    {active: false},
                    {active: false},
                    {active: false}
                ];

                scope.track = angular.isObject(scope.trackContainer.track) ? scope.trackContainer.track : scope.trackContainer;

                // Get a reference to the carrier, from the parent (trackList), track or the linked carrier
                if (scope.$parent.carrier)
                {
                    scope.carrier = scope.$parent.carrier;
                }
                else if (angular.isDefined(scope.trackContainer.carrier) && scope.trackContainer.carrier.$pk)
                {
                    scope.carrier = scope.trackContainer.carrier;
                }
                else if (angular.isDefined(scope.track.carrier))
                {
                    scope.carrier = scope.track.carrier;
                }

                // Get a reference to the label, from the parent (trackList), track or the linked carrier
                if (scope.$parent.label)
                {
                    scope.label = scope.$parent.label;
                }
                else if (angular.isDefined(scope.trackContainer.label) && scope.trackContainer.label.$pk)
                {
                    scope.label = scope.trackContainer.label;
                }
                else if (angular.isDefined(scope.track.label))
                {
                    scope.label = scope.track.label;
                }
                else if (scope.carrier !== null)
                {
                    scope.label = scope.carrier.label;
                }
                else
                {
                    scope.label = {title: ''};
                }

                scope.trackListCtrl = trackListCtrl;

                scope.detailsOpen = false;

                scope.data = {
                    loadingVersions: false,
                    loadingLyrics: false,
                    loadingStems: false
                };

                scope.toggleDetails = function(state) {
                    element.toggleClass('ng-hide', !state);
                    scope.detailsOpen = state;
                };

                scope.onVersionTabSelect = function() {
                    scope.data.loadingVersions = true;
                    scope.track.otherVersions.$resolve().$then(function() {
                        scope.data.loadingVersions = false;
                    });
                };

                scope.onLyricsTabSelect = function() {
                    scope.data.loadingLyrics = true;
                    scope.track.lyrics.$resolve().$then(function() {
                        scope.data.loadingLyrics = false;
                    });
                };

                scope.openLyricsModal = function(lyrics)
                {
                    return $uibModal.open({
                        templateUrl: 'views/lyrics-modal.html',
                        controller: 'LyricsModalCtrl',
                        windowClass: 'copyright-modal',
                        backdrop: 'static',
                        resolve : {
                            modalOptions : function() {
                                return angular.extend({
                                    title: gettextCatalog.getString('Lyrics information'),
                                    content: lyrics,
                                    closeText: gettextCatalog.getString('Close')
                                });
                            }
                        }
                    }).result;
                };

                scope.onStemTabSelect = function() {
                    scope.data.loadingStems = true;
                    scope.track.otherStems.$resolve().$then(function() {
                        scope.data.loadingStems = false;
                    });
                };

                // Handle the toggle all details call
                scope.$on('toggleAllDetails', function(event, state) {
                    scope.toggleDetails(state);
                });

                scope.$on('toggleDetails', function(event, args) {
                    var newState = angular.isDefined(args.state) ? args.state :  !scope.detailsOpen;

                    if (args.trackId === scope.track.$pk) {
                        var tab = angular.isDefined(args.tab) ? args.tab : 0;

                        scope.toggleDetails(newState);
                        for (var iTab = 0; iTab < scope.tabs.length; iTab++)
                        {
                            scope.tabs[iTab].active = tab === iTab;
                        }
                    } else {
                        // Hide details when no match and not toggling all tracks
                        scope.toggleDetails(false);
                    }
                });

                angular.forEach({ showTrackNumber: false }, function(value, valueKey) {
                    if (angular.isUndefined(scope[valueKey]))
                    {
                        scope[valueKey] = value;
                    }
                    else
                    {
                        scope[valueKey] = scope[valueKey] === 'true' || scope[valueKey] === 'yes' || scope[valueKey] === '1';
                    }
                    scope[valueKey] = scope[valueKey] ? 'true' : 'false';
                });
            }
        };
    });
