'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:LyricsModalCtrl
 * @description
 * # LyricsModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('LyricsModalCtrl', function ($scope, modalOptions, $uibModalInstance){
        $scope.modalOptions = this.modalOptions = modalOptions;
        $scope.close = this.close = function(){
            $uibModalInstance.close();
        };
});
