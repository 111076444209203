'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.infoModal
 * @description
 * # infoModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('infoModal', function($uibModal, $sce, gettextCatalog) {
        return function(_options) {
            var templateUrl = _options.templateUrl ? _options.templateUrl : 'views/info-modal.html';
            var controller = _options.controller ? _options.controller : 'InfoModalCtrl';

            var instance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: controller,
                windowClass: 'info-modal',
                resolve : {
                    modalOptions : function() {
                        return angular.extend({
                            title: gettextCatalog.getString('Information'),
                            html: null,
                            message : null,
                            errors: null,
                            closeButtonText: gettextCatalog.getString('Close'),
                            buttonContainerClass: 'pull-right'
                        }, _options);
                    }
                }
            });

            return instance.result;
        };
    });
