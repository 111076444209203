'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlayerCtrl
 * @description
 * # PlayerCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('PlayerCtrl', function ($scope, $rootScope, $state, $window, PubSub, playService,
                                      localStorageService, shareModal, userService, $uibModal, gettextCatalog) {
        var scrubApi = null;
        var playOptions = {};
        var isLogged = false;
        var playerInitialized = false;
        var loopingSelection = null;
        var currentSelection = null;

        // Initialize initial selection from URL, for example from a share url
        var initialSelection = null;
        if ($state.params.select &&
            $state.params.select.match(/^[0-9\.,]+$/))
        {
            var initialSelectionParts = $state.params.select.split(/,/);
            loopingSelection = initialSelection = {
                start: parseFloat(initialSelectionParts[0]),
                duration: parseFloat(initialSelectionParts[1])
            };
        }

        $scope.data = {
            isQueueReady: false,
            trackPlaying: null,
            showDetails: localStorageService.get('playerShowDetails'),
            playService: playService,
            waveformImage: {},
            shuffle: localStorageService.get('playerShuffle'),
            queue: null,
            loadingLyrics: false
        };
        var defaultVolume = localStorageService.get('playerVolume');
        if (defaultVolume === null ||
            defaultVolume < 0 ||
            defaultVolume > 100)
        {
            defaultVolume = 60;
        }
        $scope.volumeSlider = {
            value: defaultVolume,
            options: {
                floor: 0,
                ceil: 100,
                step: 5,
                hideLimitLabels: true,
                showSelectionBar: true,
                onChange: function() {
                    localStorageService.set('playerVolume', $scope.volumeSlider.value);
                    playService.setVolume($scope.volumeSlider.value / 100);
                }
            }
        };
        $rootScope.playerDetails = $scope.data.showDetails;

        $scope.playerConfig = playService.playerConfig;

        $scope.downloadSelection = function()
        {
            if (currentSelection !== null)
            {
                $scope.data.trackPlaying.cut(currentSelection.start, currentSelection.duration);
            }
        };

        $scope.shareSelection = function()
        {
            var shareUrl = $window.location.protocol + '//' + $window.location.hostname + '/track/' + $scope.data.trackPlaying.$pk + '?select=' + currentSelection.start + ',' + currentSelection.duration;
            return shareModal({
                shareUrl : shareUrl,
                model: $scope.data.trackPlaying,
                selection: {
                    start:currentSelection.start,
                    duration: currentSelection.duration
                }
            });
        };

        $scope.loopSelection = function()
        {
            // Update selection to loop
            loopingSelection = currentSelection;

            $scope.startLoop();
        };

        $scope.onScrubAndSelectSelectionChange = function(selection)
        {
            currentSelection = selection;

            if (loopingSelection !== null)
            {
                $scope.stopLoop();
            }
        };

        $scope.startLoop = function()
        {
            // Jump to start of selection
            playService.seekTime(loopingSelection.start);

            if (playService.getState() !== 'play')
            {
                playService.play();
            }
        };

        $scope.stopLoop = function()
        {
            if (loopingSelection &&
                playService.getState() === 'play')
            {
                playService.pause();
            }

            loopingSelection = null;
        };

        $scope.onPlayerUpdateTime = function(currentTime)
        {
            // Only interested in time when looping
            if (loopingSelection !== null)
            {
                // Check if we reached the end of the loop
                if (currentTime >= (loopingSelection.start + loopingSelection.duration))
                {
                    $scope.startLoop();
                }
            }
        };

        $scope.onPlayerCanPlay = function()
        {
            if (initialSelection !== null &&
                scrubApi !== null)
            {
                scrubApi.setSelection(initialSelection);
                initialSelection = null;
            }
        };

        $scope.onPlayerChangeSource = function(/* source */)
        {
            // Reset logged status
            isLogged = false;

            // Reset selection
            if (scrubApi !== null) {
                scrubApi.clearSelection();
            }
            currentSelection = null;

            // Handle player event in the playService
            playService.onPlayerChangeSource();
        };

        $scope.onPlayerReady = function(API) {
            playService.setPlayer(API);

            // Initialize player after loading
            if (!playerInitialized)
            {
                playerInitialized = true;

                var trackPlaying = playService.getTrackPlaying();
                if (trackPlaying !== null)
                {
                    playOptions = { onlyWhenEmpty: true, startPaused: true };
                    handleTrackPlaying(trackPlaying);
                    if (loopingSelection !== null)
                    {
                      playService.seekTime(loopingSelection.start);
                    }
                }
            }
        };

        $scope.onScrubAndSelectBarReady = function(API)
        {
            scrubApi = API;
        };

        $scope.onPlayerComplete = function()
        {
            playService.next({ startPaused: playOptions.startPaused });
        };

        $scope.onPlayerUpdateState = function(state)
        {
            playService.onPlayerUpdateState(state);
        };

        $scope.togglePlayerDetails = function()
        {
            $scope.data.showDetails = !$scope.data.showDetails;
            $rootScope.playerDetails = $scope.data.showDetails;

            // Load categories for carrier when showing details
            if ($scope.data.showDetails &&
                $scope.data.trackPlaying !== null)
            {
                $scope.data.trackPlaying.carrier.$resolve({expand: 'thumbnails,label,categories'}).$then(function(carrier) {
                    carrier.categories.$resolve();
                });
            }
            localStorageService.set('playerShowDetails', $scope.data.showDetails);
        };

        $scope.toggleShuffle = function()
        {
            $scope.data.shuffle = !$scope.data.shuffle;
            localStorageService.set('playerShuffle', $scope.data.shuffle);
            playService.setShuffle($scope.data.shuffle);
        };

        // Register event subscriptions
        var subscriptions = [];
        // Handle external download requests
        subscriptions.push(PubSub.subscribe('player.downloadSelection', function() {
            if (currentSelection) {
                $scope.downloadSelection();
            }
        }));

        var handleTrackPlaying = function(track) {
            // Load alternative versions
            track.otherVersions.$refresh();
            track.otherStems.$refresh();

            $scope.data.trackPlaying = track;
            $scope.data.waveformImage['background-image'] = 'url(' + $scope.data.trackPlaying.$waveformUrl + ')';

            track.$resolve().$then(function(track) {
                track.carrier.$refresh({expand: 'thumbnails,label,categories'});
                $scope.data.loadingLyrics = true;
                track.lyrics.$resolve().$then(function() {
                    $scope.data.loadingLyrics = false;
                });
            });
        };

        subscriptions.push(PubSub.subscribe('playService.playTrack', function(args) {
            playOptions = args.options;
            handleTrackPlaying(args.track);
        }));

      subscriptions.push(PubSub.subscribe('playService.setQueue', function(args) {
          $scope.data.queue = args.queue;
          $scope.data.isQueueReady = $scope.data.queue !== null;
      }));

      subscriptions.push(PubSub.subscribe('userService.onCurrentUserChange', function(args) {
          // When user logs out, stop playing if the site does not support public play
          if (args === null &&
              !$scope.currentSite.publicCanPlay
          ) {
              playService.stop();
          }
      }));

      // Remove subscriptions when the controller is destroyed
        $scope.$on('$destroy', function() {
            for (var iSubscription = 0; iSubscription < subscriptions.length; iSubscription++)
            {
                PubSub.unsubscribe(subscriptions[iSubscription]);
            }
        });

      // Lyrics code
      $scope.onLyricsTabSelect = function() {
          $scope.data.loadingLyrics = true;
          $scope.data.trackPlaying.lyrics.$resolve().$then(function() {
              $scope.data.loadingLyrics = false;
          });
      };

      $scope.openLyricsModal = function(lyrics)
      {
          return $uibModal.open({
              templateUrl: 'views/lyrics-modal.html',
              controller: 'LyricsModalCtrl',
              windowClass: 'copyright-modal',
              backdrop: 'static',
              resolve : {
                  modalOptions : function() {
                      return angular.extend({
                          title: gettextCatalog.getString('Lyrics information'),
                          content: lyrics,
                          closeText: gettextCatalog.getString('Close')
                      });
                  }
              }
          }).result;
      };
    });
