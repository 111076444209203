import '../app';

/**
 * @ngdoc service
 * @name musicdirectorApp.accountValidationModal
 * @description
 * # accountValidationModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('accountValidationModal', function ($uibModal) {
      return function(_options) {
        var instance = $uibModal.open({
          animation: false,
          backdrop: 'static',
          templateUrl: 'views/account-validation-modal.html',
          controller: 'AccountValidationModalCtrl',
          controllerAs: 'AccountValidationModalCtrl',
          windowClass: 'account-validation-modal',
          resolve : {
            modalOptions : function() {
              return _options;
            }
          }
        });

        return instance.result;
      };
    });
