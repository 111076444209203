'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('LabelRating', function (restmod) {
        return restmod.model().mix({
            label: { belongsTo: 'Label', key: 'labelID' }
        });
    });
