'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:focusMe
 * @description
 * # focusMe
 */
angular.module('musicdirectorApp')
    .directive('focusMe', function ($timeout) {
        return {
            link: function (scope, element, attr) {
                attr.$observe('focusMe', function (value) {
                    if (value === 'true') {
                        $timeout(function () {
                            element[0].focus();
                        });
                    }
                });
            }
        };
    });