'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:DownloadmodalCtrl
 * @description
 * # DownloadmodalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('VideoModalCtrl', function ($scope, modalOptions, $sce) {

        var player = null;

        $scope.options = modalOptions;
        if (modalOptions.model.$deeplink) {
          if (modalOptions.baseUrl) {
            $scope.options.deeplink = modalOptions.baseUrl + '/' + modalOptions.model.$deeplink;
          } else {
            $scope.options.deeplink = modalOptions.model.$deeplink;
          }
        }
        $scope.videoModel = modalOptions.model;
        $scope.playerConfig = {
            autoPlay: true,
            sources: [{src: $sce.trustAsResourceUrl(modalOptions.model.video), type: 'video/mp4'}]
        };

        $scope.onPlayerReady = function (API) {
            player = API;
        };

        this.close = function () {
            player.stop();

            return $scope.$close();
        };
    });

