'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:playlists
 * @description
 * # playlists
 */
angular.module('musicdirectorApp')
    .directive('playlistsBar', function () {
        return {
            restrict: 'E',
            templateUrl: 'views/playlists-bar.html',
            controller: 'PlaylistsBarCtrl',
            controllerAs: 'PlaylistsBarCtrl',
            scope: {
                playlists: '=',
                currentSite: '=',
                hideMenu: '@',
                disableFavoriting: '@',
                enableImporting: '@',
                openPlaylistOnClick: '@',
                onSelectPlaylist: '&',
                activePlaylist: '='
            },
            link: function(scope, element) {
                var scrollContainer = element.find('.scroll');

                scope.scrollPlaylistsToTop = function()
                {
                    scrollContainer.scrollTop(0);
                };
            }
        };
    });
