'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:MainMenuCtrl
 * @description
 * # MainMenuCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('MainMenuCtrl', function ($scope, $state, $http, $window, languageService, configuration, PubSub,
                                          appAuthService, userService, infoModal, gettextCatalog, $httpParamSerializerJQLike) {

        var cuesheetWindow = null;

        $scope.data = {
            pages: $scope.currentSite.pages.$resolve({language: languageService.getCurrentLanguage()}),
            languageService: languageService
        };

        $scope.openPage = function(event, page){
            var isOpen = event.target.attributes['aria-expanded'].value;
            if(isOpen === 'false' && page.externalUrl.length > 1){
                $window.location.href = page.externalUrl;
            }
        };

        function openPopup(menuItem)
        {
            // New window parameters
            var features = {
                width : '1008px', /* 960px body-width + 2x 14px body-padding + 20px scrollbar */
                height : '600px',
                toolbar : 'no',
                menubar : 'no',
                resizable : 'yes',
                status : 'no',
                scrollbars : 'yes',
                top : 100,
                left: ($window.innerWidth - 988) / 2
            };

            // Build parameter string
            var featuresParam = '';
            for (var feature in features)
            {
                if (featuresParam.length > 0)
                {
                    featuresParam += ',';
                }
                featuresParam += feature + '=' + features[feature];
            }

            // Open the new window
            cuesheetWindow = $window.open('', 'cuesheet', featuresParam);
            cuesheetWindow.document.writeln('Loading Cuesheet Application...');

            return redirectToCuesheets(menuItem);
        }

        function loginToCuesheets()
        {
            return $http.post(configuration.cuesheetsUrlPrefix + '/musicdirector/login',
                $httpParamSerializerJQLike({language: languageService.getCurrentLanguage()}),
                {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    withCredentials: true
                }
            );
        }

        function redirectToCuesheets(menuItem)
        {
            return loginToCuesheets(menuItem).then(function () {
                // Switch to URL
                cuesheetWindow.location = configuration.cuesheetsUrlPrefix + '/' + menuItem.externalUrl;
            });
        }

        $scope.getStyle = function(menuItem) {
            if (menuItem.hexColor) {
                return { color: menuItem.hexColor };
            }
            return null;
        };

        $scope.openCuesheetPopup = function(menuItem)
        {
            // Check if we are already authenticated
            if (userService.isAuthenticated())
            {
                openPopup(menuItem);
            }
            else {
                return appAuthService.requireLogin({modal: {message: gettextCatalog.getString('Please login to view and create cuesheets.')}}).then(function () {
                    infoModal({
                        title: gettextCatalog.getString('You are now logged in'),
                        html: '<p>' + gettextCatalog.getString('Click the button below to open the Cuesheet system.') + '</p>',
                        closeButtonText: gettextCatalog.getString('Open cuesheets')
                    }).then(function() {
                        openPopup(menuItem);
                    });
                });
            }
        };

        $scope.getTemplateUrl = function() {
            return 'views/' + $scope.currentSite.template + '/main-menu.html';
        };

        var subscriptions = [];
        subscriptions.push(PubSub.subscribe('userService.onCurrentUserChange', function (tokenData) {
            $scope.data.pages.$refresh({ language: languageService.getCurrentLanguage() });
        }));

        $scope.$on('$destroy', function () {
            subscriptions.forEach(function (subscription) {
                PubSub.unsubscribe(subscription);
            });
        });
    });
