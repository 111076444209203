'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.siteInterceptorService
 * @description
 * # siteInterceptorService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('siteInterceptorService', function (PubSub, $rootScope) {
        function request(config) {
            if ($rootScope.currentSite) {
                config.headers['X-MD-SiteID'] = $rootScope.currentSite.$pk;
            }
            return config;
        }

        return {
            request: request
        };
    });
