'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.userService
 * @description
 * # userService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('userService', function ($rootScope, $http, $q, PubSub, OAuth, OAuthToken, Account, configuration, Analytics) {
        var tokenData = null;

        if (OAuth.isAuthenticated()) {
            tokenData = OAuthToken.token;
        }

        function getCurrentUser() {
            return tokenData;
        }

        function setCurrentUser(data) {
            tokenData = data;
            PubSub.publish('userService.onCurrentUserChange', tokenData);
        }

        function logout() {
            setCurrentUser(null);
            OAuthToken.removeToken();

            // Track the play
            Analytics.trackEvent('user', 'logout');

            // Logout from cuesheet system
            if ($rootScope.currentSite &&
                $rootScope.currentSite.hasCuesheets)
            {
                $http.post(configuration.cuesheetsUrlPrefix + '/auth/logout?ajax=1');
            }
        }

        function isAuthenticated()
        {
            return OAuth.isAuthenticated();
        }

        function isSystemAdmin()
        {
            return getAccount().then(function(account) {
                if (account !== null) {
                    return account.isSystemAdmin;
                }
                return false;
            });
        }

        function canPlay()
        {
          return getAccount().then(function(account) {
            if (account !== null) {
              return account.canPlay;
            }
            return false;
          });
        }

        function canDownload()
        {
          return getAccount().then(function(account) {
            if (account !== null) {
              return account.canDownload;
            }
            return false;
          });
        }

        function viewDownloadButton()
        {
          return getAccount().then(function(account) {
            if (account !== null) {
              return account.viewDownloadButton;
            }
            return false;
          });
        }

        function getAccount(forceReload) {
            if (angular.isUndefined(forceReload)) {
                forceReload = false;
            }
            if (isAuthenticated())
            {
                var reloadMethod = forceReload ? '$fetch' : '$resolve';
                return Account.$new('~')[reloadMethod]().$then(function(result) {
                    return result;
                }, function(result) {
                    return result;
                }).$asPromise();
            }
            else
            {
                var deferred = $q.defer();
                deferred.resolve(null);
                return deferred.promise;
            }
        }

        function updateAnalytics() {
          // Set authenticated dimension
          Analytics.set('dimension1', isAuthenticated());

          // Update company dimension
          if (isAuthenticated()) {
            // Fetch account information
            getAccount().then(function(result) {
              if (result &&
                result.linkedCompany &&
                result.linkedCompany.company
              ) {
                Analytics.set('dimension3', result.linkedCompany.company);
              } else {
                // Clear company
                Analytics.set('dimension3', null);
              }
            });
          } else {
            // Clear company
            Analytics.set('dimension3', null);
          }
        }

        return {
            getCurrentUser: getCurrentUser,
            setCurrentUser: setCurrentUser,
            isAuthenticated: isAuthenticated,
            isSystemAdmin: isSystemAdmin,
            canPlay: canPlay,
            canDownload: canDownload,
            viewDownloadButton: viewDownloadButton,
            getAccount: getAccount,
            logout: logout,
            updateAnalytics: updateAnalytics,
        };
    });
