'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.siteNewsCategory
 * @description
 * # siteNewsCategory
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('SiteNewsCategory', function (restmod) {
        return restmod.model().mix({
            site: { hasOne: 'Site'}
        });
    });
