'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.registerModal
 * @description
 * # registerModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('registrationModal', function($uibModal, $rootScope, Page, gettextCatalog, BusinessSector) {
        return function(_options) {
            var instance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                templateUrl: '../../views/registration-modal.html',
                controller: 'RegistrationModalCtrl',
                controllerAs: 'RegistrationModalCtrl',
                windowClass: 'registration-modal',
                resolve : {
                    modalOptions : function() {
                        return angular.extend({
                            message : ''
                        }, _options);
                    },
                    companyTypes: function() {
                        return $rootScope.currentSite.companyTypes.$resolve().$asPromise();
                    },
                    businessSectors: function(){
                        return BusinessSector.$search();
                    },
                    jobTitles: function() {
                        return $rootScope.currentSite.jobTitles.$resolve().$asPromise();
                    },
                    registrationFields: function() {
                        return $rootScope.currentSite.registrationFields.$resolve().$asPromise();
                    },
                    registrationPage: function() {
                        return Page.$find('by_type', {
                            type: 'registration',
                            url: 'registration',
                            site_id: $rootScope.currentSite.$pk,
                            language: gettextCatalog.getCurrentLanguage()
                        }).$asPromise();
                    },
                    registrationErrorTextChina: function() {
                        return Page.$find('by_type', {
                            type: 'registration',
                            url: 'registration-error-cn',
                            site_id: $rootScope.currentSite.$pk,
                            language: gettextCatalog.getCurrentLanguage()
                        }).$asPromise();
                    }
                }
            });

            return instance.result;
        };
    });
