'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.companyType
 * @description
 * # companyType
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('JobTitle', function (restmod) {
        return restmod.model().mix({
            site: { belongsTo: 'Site' },
            $config: {
                name: 'jobTitle',
                plural: 'jobTitles'
            }
        });
    });