'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:CataloguedetailCtrl
 * @description
 * # CataloguedetailCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('CatalogueDetailCtrl', function ($scope, $stateParams, $state, label, gettextCatalog, seoData, userService) {
        $scope.data = {
            label : label,
            loadMoreDisabled: label.carriers.$page >= label.carriers.$pageCount,
            showRelatedLabels: true,
            sort: $stateParams.sort,
            tooltips : {
                1: gettextCatalog.getString('fine'),
                2: gettextCatalog.getString('good'),
                3: gettextCatalog.getString('better'),
                4: gettextCatalog.getString('best'),
                5: gettextCatalog.getString('superb')
            }
        };

        // Update SEO data
        seoData.pageTitle = gettextCatalog.getString('Music from') + ' ' + label.title;
        seoData.metaDescription = label.text;
        seoData.pageImage = label.thumbnails ? label.thumbnails['350x250'] : null;

        $scope.isSort = function(sort) {
            return $scope.data.sort === sort;
        };

        $scope.toggleRelatedLabels = function(isOpen){
            $scope.data.showRelatedLabels = !isOpen;
        };

        $scope.setRating = function(rating) {
            if (label.rating === null)
            {
                label.rating.$create({ rating: rating });
            }
            else
            {
                label.rating.rating = rating;
                label.rating.$save();
            }
        };

        $scope.changeSort = function(sort) {
            $scope.data.sort = sort;
            $scope.data.label.carriers.$refresh({ expand: 'thumbnails', 'per-page' : 42, page: 1, sort : sort }).$then(function() {
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;

                // Sync sort options to URL
                var urlParams = {
                    catalogueId: label.$pk,
                    sort: sort,
                    sortSeed: angular.isDefined(this.$sortSeed) ? this.$sortSeed : null
                };
                $state.transitionTo($state.current.name, urlParams, { notify: false });
            });
        };

        $scope.loadMore = function()
        {
            $scope.data.loadMoreDisabled = true;
            $scope.data.label.carriers.$fetch({ expand: 'thumbnails', sort: $scope.data.sort, 'per-page' : 42, page: $scope.data.label.carriers.$page + 1 }).$then(function() {
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;
            });
        };

        if ($scope.userCanPlay)
        {
          // Start with a playlist based on the full catalogue
          label.play({startPaused: true, onlyWhenEmpty: true});
        }
    });
