'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.remotePlayQueue
 * @description
 * # remotePlayQueue
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('RemotePlayQueue', function ($http, $injector, $timeout) {
        var defaultTimeout = 500;
        var range = 40; // Defines the number of tracks before and after the current track (10 means 5 before and 5 after)

        function RemotePlayQueue(_model, _modelScope, _httpMethod) {
            if (angular.isUndefined(_modelScope))
            {
                _modelScope = {};
            }

            this.model = _model;
            this.modelScope = _modelScope;
            this.allowShuffle = true;
            this.wantedRemoteQueuePosition = 0; // Wanted remote queue position
            this.remoteQueuePosition = 0; // Remote queue position fropm the last refresh
            this.localQueuePosition = 0; // Local queue position inside last returned remote queue
            this.totalTracks = null;
            this.shuffle = false;
            this.shuffleSeed = null;
            this.currentTrack = null; // Based on remote queue position
            this.positionByTrack = null;
            this.previousTracks = null;
            this.nextTracks = null;
            this.lastCarrier = null;
            this.loadTimeout = null;
            this.httpMethod = 'get';
            this.isReady = false;

            if (angular.isDefined(_httpMethod)) {
                this.httpMethod = _httpMethod;
            }
        }

        RemotePlayQueue.prototype.getCurrentTrack = function () {
            if (this.totalTracks > 0) {
                var track = null;
                if (this.localQueuePosition < 0)
                {
                    track = this.previousTracks[this.previousTracks.length + this.localQueuePosition];
                }
                else if (this.localQueuePosition === 0)
                {
                    track = this.currentTrack;
                }
                else if (this.nextTracks !== null &&
                    this.nextTracks.length >= this.localQueuePosition)
                {
                    track = this.nextTracks[this.localQueuePosition - 1];
                }

                return this.buildTrackModel(track);
            }
            return null;
        };

        RemotePlayQueue.prototype.buildTrackModel = function(data) {
            var Track = $injector.get('Track');
            var Carrier = $injector.get('Carrier');

            var buildTrack = Track.$new(data.ID).$extend({
                title: data.title,
                track: data.track,
                carrierID: data.carrierID
            });
            buildTrack.$streamUrl = data.streamUrl;
            buildTrack.$streamUrlExpires = data.streamUrlExpires;
            buildTrack.$waveformUrl = data.waveformUrl;

            if (this.lastCarrier !== null &&
                this.lastCarrier.$pk === buildTrack.carrierID)
            {
                buildTrack.carrier = this.lastCarrier;
            }
            else
            {
                this.lastCarrier = buildTrack.carrier = Carrier.$find(buildTrack.carrierID);
            }

            return buildTrack;
        };

        RemotePlayQueue.prototype.setPositionByTrack = function(trackId) {
          this.positionByTrack = trackId;
        };

        RemotePlayQueue.prototype.load = function () {
            // Load the queue from the API
            var queueUrl = this.model.$url() + '/play_queue';
            var self = this;

            var requestParams;
            if (this.httpMethod === 'get') {
                requestParams = angular.extend(this.modelScope, {shuffle: this.shuffle, shuffleSeed: this.shuffleSeed, range: range});
            } else if (this.httpMethod === 'post') {
                requestParams = {shuffle: this.shuffle, shuffleSeed: this.shuffleSeed, range: range};
            }

            // Store current wanted position
            var beforeWantedRemoteQueuePosition;

            // Use position by track ID, when available
            if (this.positionByTrack !== null)
            {
                beforeWantedRemoteQueuePosition = 0;
                requestParams.positionByTrack = this.positionByTrack;
            }
            else
            {
                requestParams.positionByTrack = null;
                requestParams.position = beforeWantedRemoteQueuePosition = this.wantedRemoteQueuePosition;
            }

            var loadCallback = function (result) {
                if (result.status === 200) {
                    // Initialize parameters
                    self.totalTracks = result.data.totalTracks;
                    self.currentTrack = result.data.currentTrack;
                    self.previousTracks = result.data.previousTracks;
                    self.nextTracks = result.data.nextTracks;
                    self.remoteQueuePosition = result.data.position;
                    self.isReady = true;
                    if (result.data.trackFound) {
                        self.positionByTrack = self.currentTrack.ID;
                    }

                    // Reset local queue position to 'center' of queue, but based on the latest remote position
                    self.localQueuePosition = self.wantedRemoteQueuePosition - beforeWantedRemoteQueuePosition;

                    // Initialize shuffle settings
                    if (self.shuffle &&
                        result.data.shuffleSeed) {
                        self.shuffleSeed = result.data.shuffleSeed;
                    }
                    else {
                        self.shuffleSeed = null;
                    }

                    return self;
                }

                self.isReady = false;
                return false;
            };

            if (this.httpMethod === 'get') {
                return $http.get(queueUrl, {
                    ignoreLoadingBar: true,
                    params: requestParams
                }).then(loadCallback);
            } else if (this.httpMethod === 'post') {
                return $http.post(queueUrl, this.modelScope, {
                    ignoreLoadingBar: true,
                    params: requestParams
                }).then(loadCallback);
            }
        };

        RemotePlayQueue.prototype.refreshByTrackId = function(trackId, timeout) {
            if (this.loadTimeout) {
                $timeout.cancel(this.loadTimeout);
            }
            if (angular.isUndefined(timeout)) {
                timeout = defaultTimeout;
            }

            // If the requested track is our current track, only revert local position
            if (trackId === this.positionByTrack) {
                this.localQueuePosition = 0;
            } else if (this.currentTrack !== null &&
                this.currentTrack.ID === trackId
            ) {
                this.localQueuePosition = 0;
            } else {
                // Update position value
                this.positionByTrack = trackId;

                // Check inside next and previous tracks for a matching ID
                var trackInCache = false;
                var iTrack = 0;

                // Search for tracks in list with previous tracks
                if (this.previousTracks !== null) {
                    for (iTrack = 0; iTrack < this.previousTracks.length; iTrack++) {
                        if (this.previousTracks[iTrack].ID === trackId) {
                            this.localQueuePosition = -this.previousTracks.length + iTrack;
                            trackInCache = true;
                            break;
                        }
                    }
                }

                // When not found, check for tracks in list with next tracks
                if (this.nextTracks !== null &&
                    !trackInCache
                ) {
                    for (iTrack = 0; iTrack < this.nextTracks.length; iTrack++) {
                        if (this.nextTracks[iTrack].ID === trackId) {
                            this.localQueuePosition = iTrack + 1;
                            trackInCache = true;
                            break;
                        }
                    }
                }

                // When in cache, return immediatly
                if (trackInCache) {
                    return true;
                } else {
                    // Try to find position 'online'
                    this.loadTimeout = $timeout(this.load.bind(this), timeout);
                    return this.loadTimeout;
                }

            }
        };

        RemotePlayQueue.prototype.refresh = function(timeout) {
            if (this.loadTimeout) {
                $timeout.cancel(this.loadTimeout);
            }
            if (angular.isUndefined(timeout)) {
                timeout = defaultTimeout;
            }
            this.loadTimeout = $timeout(this.load.bind(this), timeout);

            return this.loadTimeout;
        };

        RemotePlayQueue.prototype.next = function () {
            if (this.wantedRemoteQueuePosition !== -1 &&
                (
                    (this.nextTracks !== null && this.localQueuePosition >= 0 && this.localQueuePosition < this.nextTracks.length) ||
                    (this.previousTracks !== null && this.localQueuePosition < 0 && Math.abs(this.localQueuePosition) < this.previousTracks.length)
                ))
            {
                this.localQueuePosition++;
                this.positionByTrack = null;

                var nextTrack = this.getCurrentTrack();

                if (!nextTrack)
                {
                    return;
                }

                // Refresh queue
                this.wantedRemoteQueuePosition = (this.remoteQueuePosition + this.localQueuePosition) % this.totalTracks;
                this.refresh();

                return nextTrack;
            }
        };

        RemotePlayQueue.prototype.prev = function () {
            var maxPreviousTrackIndex = 0;
            if (this.previousTracks)
            {
                maxPreviousTrackIndex = this.previousTracks.length;
            }

            if (this.wantedRemoteQueuePosition !== -1 &&
                (this.wantedRemoteQueuePosition + this.localQueuePosition) > -maxPreviousTrackIndex)
            {
                this.localQueuePosition--;
                this.positionByTrack = null;

                var previousTrack = this.getCurrentTrack();

                if (!previousTrack)
                {
                    return;
                }

                // Refresh queue
                this.wantedRemoteQueuePosition = this.remoteQueuePosition + this.localQueuePosition;
                this.refresh();

                return previousTrack;
            }
        };

        RemotePlayQueue.prototype.setShuffle = function (state) {
            if (this.shuffle !== state) {
                this.shuffle = state;

                // Refresh queue
                this.refresh(0);
            }
        };

        RemotePlayQueue.prototype.setAllowShuffle = function(state) {
            this.shuffle = false;
            this.allowShuffle = state;
        };

        RemotePlayQueue.prototype.isForModel = function (_model) {
            return _model === this.model;
        };

        return RemotePlayQueue;
    });
