'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:matchPasswords
 * @description
 * # matchPasswords
 */
angular.module('musicdirectorApp')
    .directive('matchPassword', function () {
      return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
          var firstPassword = '#' + attrs.matchPassword;
          elem.add(firstPassword).on('keyup', function () {
            scope.$apply(function () {
              var v = elem.val() === angular.element(firstPassword).val();
              ctrl.$setValidity('matchPassword', v);
            });
          });
        }
      };
    });