'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.authStore
 * @description
 * # authStore
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('authStore', function ($http, configuration, $location) {
        function getAuthData() {
            return $http.get(configuration.apiUrlPrefix + '/auth-store').then(function(response) {
                if (response.data.status === 'ok') {
                    return response.data.auth_data;
                } else {
                    return null;
                }
            });
        }

        function setAuthData(data) {
            var cookieDomain = '';

            if (!$location.host().match(/^(bmg|music)/)) {
                var hostnameParts = $location.host().split('.');
                cookieDomain = '.' + hostnameParts.slice(1).join('.');
            } else {
                cookieDomain = '.' +  $location.host();
            }

            return $http.post(configuration.apiUrlPrefix + '/auth-store', {'auth_data' : data, 'cookie_domain' : cookieDomain});
        }

        return {
            get data() {
                return getAuthData();
            },
            set data(value) {
                return setAuthData(value);
            },
            clear: function() {
                return $http.delete(configuration.apiUrlPrefix + '/auth-store');
            }
        };
  });
