'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistImportModalCtrl
 * @description
 * # PlaylistImportModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistImportModalCtrl', function ($scope, Upload, Playlist, gettextCatalog, playlistID) {
        this.close = $scope.$close;

        $scope.data = {
            phase: 'start',
            errors: null
        };

        // upload on file select or drop
        $scope.upload = function (file) {
            $scope.data.errors = null;
            $scope.data.phase = 'uploading';

            var intoPlaylist = playlistID !== undefined ? '&playlistID=' + playlistID : '';

            Upload.upload({
                url: Playlist.single('/playlists/import').$url() + '?site_id=' + $scope.currentSite.$pk + intoPlaylist,
                file: file
            }).success(function (/* data, status, headers, config */) {
                $scope.data.errors = null;
                $scope.data.phase = 'success';
            }).error(function (data /* , status, headers, config */) {
                $scope.data.errors = (data && data.errors) || [ gettextCatalog.getString('Upload failed.')];
                $scope.data.phase = 'start';
            });
        };
    });