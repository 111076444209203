'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.playService
 * @description
 * # playService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('playService', function ($rootScope, $window, $document, $sce, PubSub, localStorageService) {
        var trackPlaying = null;
        var queue = null;
        var shuffle = localStorageService.get('playerShuffle');
        var hasPlayed = false;
        var trackHasPlayed = false;

        var playerApi = null;
        var playOptions = null;
        var playerConfig = {};

        // The user player state is different from the real player state. The real player pauses after each track,
        // but the user might want to continue with the next one
        var userPlayerState = 'stop';

        function playTrack(track, queue, options)
        {
            playOptions = angular.extend({ onlyWhenEmpty: false, startPaused : false, scrollToTrack : false, scrollDirection: 'down' }, options);

            // Only load track when player is empty
            if (playOptions.onlyWhenEmpty &&
                playerApi !== null)
            {
                return;
            }

            if (trackPlaying !== null)
            {
                // Active track is already playing, toggle state
                if (trackPlaying.$pk === track.$pk)
                {
                    togglePlayPause();
                }
                else
                {
                    // Stop current track
                    trackPlaying.$isPlaying = trackPlaying.$isPaused = false;

                    // Pause the player
                    if (playerApi &&
                        hasPlayed)
                    {
                        playerApi.pause();
                    }
                }
            }
            trackPlaying = track;
            trackPlaying.$isPaused = true;

            // Highlight active track, if available in current view
            if (!playOptions.onlyWhenEmpty &&
                playOptions.scrollToTrack)
            {
                var trackElement = angular.element(document.getElementById('track-row-' + track.$pk));

                if (trackElement.length &&
                    !jQuery('#track-row-' + track.$pk).isInViewport({ tolerance: -100, direction: 'vertical' }))
                {
                    var firstSibling = jQuery(trackElement[0]).siblings('tr').first();
                    if (playOptions.scrollDirection === 'up') {
                        // Align track to top of viewport
                        $document.scrollTo(0, jQuery(trackElement[0]).position().top + trackElement.height(), 250);
                    } else {
                        // Align track to bottom of viewport
                        $document.scrollTo(0, jQuery(trackElement[0]).position().top - firstSibling.position().top, 250);
                    }
                }
            }

            // If we specified a queue, we change the current playlist queue
            if (queue) {
                queue.refreshByTrackId(trackPlaying.$pk);
            // disable the player from going to the previous or next track in the queue
            } else if(queue === null || queue === undefined || queue === false) {
                queue = null;
            }
            setQueue(queue);

            // Only load track when player is empty
            if (playOptions.onlyWhenEmpty &&
                playerApi !== null)
            {
                return;
            }

            // Publish a play track event. This updates the player control
            PubSub.publish('playService.playTrack', {track: trackPlaying, options: playOptions});

            if (playerApi !== null)
            {
                loadTrackInPlayer();
            }
        }

        function loadTrackInPlayer()
        {
            if (playerApi !== null) {
                var sources = [{src: $sce.trustAsResourceUrl(trackPlaying.$streamUrl), type: 'audio/mp3'}];
                playerApi.mediaElement.attr('src', sources[0].src);
                playerApi.mediaElement.attr('type', sources[0].type);
                playerApi.changeSource(sources);
            }
        }

        function playQueue(queue, options)
        {
            playOptions = angular.extend({ onlyWhenEmpty: false, startPaused : false }, options);

            // Only load track when player is empty
            if (playOptions.onlyWhenEmpty &&
                playerApi !== null
            ) {
                return;
            }

            // Only play track if user is allowed to play tracks
            if (!$rootScope.userCanPlay) {
                return;
            }
            setQueue(queue);

            // Start with first track
            var track = queue.getCurrentTrack();
            if (track) {
                track.play(queue, playOptions);
            }
        }

        function play()
        {
            if (playerApi)
            {
                playerApi.play();
                PubSub.publish('playService.play', { track: trackPlaying });
            }
        }

        function stop()
        {
            trackPlaying = null;
            if (playerApi)
            {
                playerApi.stop();
                PubSub.publish('playService.stop');
            }
        }

        function pause()
        {
            if (playerApi)
            {
                playerApi.pause();
                PubSub.publish('playService.pause', { track: trackPlaying });
            }
        }

        function seekToPercentage(percentage)
        {
            if (playerApi)
            {
                playerApi.seekTime(percentage, true);
                PubSub.publish('playService.seekToPercentage', { track: trackPlaying, percentage: percentage });
            }
        }

        function seekPercentage(percentage)
        {
            // Seek to percentage plus/minus offset (minimum = 0, maximum = 100)
            if (playerApi) {
                // Relative seek, get current position
                var currentPercentage = Math.round((playerApi.currentTime / playerApi.totalTime) * 100);

                playerApi.seekTime(Math.max(0, Math.min(100, currentPercentage + percentage)), true);
                PubSub.publish('playService.seekPercentage', { track: trackPlaying, percentage: percentage });
            }
        }

        function togglePlayPause()
        {
            if (playerApi) {
                if (playerApi.currentState === 'play') {
                    pause();
                }
                else if (playerApi.currentState === 'pause' ||
                    playerApi.currentState === 'stop') {
                    play();
                }
            }
        }

        function isTrackPlaying(track)
        {
            if (angular.isUndefined(track))
            {
                return false;
            }

            return angular.isDefined(trackPlaying) &&
                trackPlaying !== null &&
                trackPlaying.$pk === track.$pk &&
                playerApi &&
                playerApi.currentState === 'play';
        }

        function setQueue(_queue)
        {
            queue = _queue;

            PubSub.publish('playService.setQueue', {queue: queue});

            if(queue !== null){
                // Sync shuffle state with queue
                queue.setShuffle(shuffle);
            }
        }

        function onPlayerUpdateState(state)
        {
            userPlayerState = state;

            switch (state)
            {
                case 'play':
                    hasPlayed = true;
                    if (trackPlaying !== null)
                    {
                        if (!trackHasPlayed && playOptions.startPaused)
                        {
                            trackPlaying.logPlay();
                        }
                        trackPlaying.$isPlaying = true;
                        trackPlaying.$isPaused = false;
                        trackHasPlayed = true;
                    }
                    break;

                case 'pause':
                    if (trackPlaying !== null)
                    {
                        trackPlaying.$isPlaying = false;
                        trackPlaying.$isPaused = true;
                    }
                    break;
            }

            // Send out event to process new state across application
            PubSub.publish('playService.setState', { track: trackPlaying, state: state });
        }

        function setShuffle(state)
        {
            shuffle = state;
            if (queue) {
                queue.setShuffle(state);
            }
        }

        function next(options)
        {
            var track = queue.next();
            if (track) {
                trackHasPlayed = false;
                track.play(queue, angular.extend({ startPaused : userPlayerState !== 'play' }, options));
            }
        }

        function prev(options)
        {
            var track = queue.prev();
            if (track) {
                trackHasPlayed = false;
                track.play(queue, angular.extend({ startPaused : userPlayerState !== 'play' }, options));
            }
        }

        function setPlayer(_player)
        {
            playerApi = _player;

            // Initialize already loaded track into player
            if (trackPlaying)
            {
                loadTrackInPlayer();
            }
        }

        function seekTime(_time)
        {
            playerApi.seekTime(_time);
        }

        function setVolume(_volume)
        {
            playerApi.setVolume(_volume);
        }

        function onPlayerChangeSource()
        {
            // Auto-play the track after loading
            if (!playOptions.startPaused)
            {
                play();

                // Log the play action
                trackPlaying.logPlay();
            }
        }

        function playSilentFirst() {
            // This only works if the player is currently loaded
            if (!hasPlayed &&
                playerApi !== null)
            {
                var sources = [{src: $sce.trustAsResourceUrl('https://static.bmgproductionmusic.nl/audio/silent.mp3'), type: 'audio/mp3'}];
                playerApi.mediaElement.attr('src', sources[0].src);
                playerApi.mediaElement.attr('type', sources[0].type);
                playerApi.play();
            }
        }

        function refreshQueue(_model) {
            if (queue &&
                queue.isForModel(_model))
            {
                queue.refresh(0);
            }
        }

        return {
            playerConfig: playerConfig,
            setPlayer: setPlayer,
            playTrack: playTrack,
            playQueue: playQueue,
            stop: stop,
            prev: prev,
            next: next,
            setQueue: setQueue,
            setShuffle: setShuffle,
            getState: function() { return playerApi && playerApi.currentState; },
            pause: pause,
            play: play,
            togglePlayPause: togglePlayPause,
            seekTime: seekTime,
            setVolume: setVolume,
            seekToPercentage: seekToPercentage,
            seekPercentage: seekPercentage,
            getTrackPlaying: function() { return trackPlaying; },
            isPlaying: function() { return trackPlaying !== null; },
            isTrackPlaying: isTrackPlaying,
            isTrackPaused: function(track) {
                return trackPlaying !== null &&
                    trackPlaying.$pk === track.$pk &&
                    playerApi &&
                    playerApi.currentState === 'pause';
            },
            onPlayerChangeSource: onPlayerChangeSource,
            onPlayerUpdateState: onPlayerUpdateState,
            playSilentFirst: playSilentFirst,
            refreshQueue: refreshQueue
        };
    });
