'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistResequenceModalCtrl
 * @description
 * # PlaylistResequenceModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistResequenceModalCtrl', function ($scope, options, tracks, playService, $rootScope) {
        $scope.options = options;
        $scope.data = {
            selected: null,
            list: tracks,
            loadMoreDisabled: false,
            loadingResults: false
        };

        this.loadMore = function(){
          $scope.data.loadMoreDisabled = true;
          $scope.data.loadingResults = true;
          $scope.options.loadMore().$then(function(_tracks){
              $scope.data.loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
              $scope.data.loadingResults = false;
              tracks = [];
              angular.forEach(_tracks, function(value) {
                  tracks.push({
                      ID: value.track.ID,
                      trackTitle: value.track.title,
                      carrierCode: value.track.carrier.code,
                      carrierTitle: value.track.carrier.title,
                      carrierCover: value.track.carrier.thumbnails['85x85'] + $rootScope.addCacheBuster(value.track.carrier.timeChange),
                      labelTitle: value.track.carrier.label.title
                  });
              });
              $scope.data.list = tracks;
          });
        };

        this.cancel = $scope.$dismiss;

        this.save = function() {
            // Convert objects to sequence object that we can post
            var newOrder = [];
            angular.forEach($scope.data.list, function(value) {
                newOrder.push(value.ID);
            });

            // Save changes
            $scope.options.model.sort(newOrder).then(function() {
                // Refresh the queue if it's linked to this playlist
                playService.refreshQueue($scope.options.model);

                $scope.$close();
            });
        };
    });
