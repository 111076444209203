'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:readMore
 * @description
 * # readMore
 */
angular.module('musicdirectorApp')
  .directive('readMore', function () {
      return {
          restrict: 'A',
          transclude: true,
          replace: true,
          template: '<p></p>',
          scope: {
              moreText: '@',
              lessText: '@',
              words: '@',
              ellipsis: '@',
              char: '@',
              limit: '@',
              content: '@',
              onToggle: '='
          },
          link: function(scope, elem, attr, ctrl, transclude) {
              var moreText = angular.isUndefined(scope.moreText) ? ' <a class="read-more">Read More...</a>' : ' <a class="read-more">' + scope.moreText + '</a>',
                  lessText = angular.isUndefined(scope.lessText) ? ' <a class="read-less">Less ^</a>' : ' <a class="read-less">' + scope.lessText + '</a>',
                  ellipsis = angular.isUndefined(scope.ellipsis) ? '...' : scope.ellipsis,
                  limit = angular.isUndefined(scope.limit) ? 150 : scope.limit;

              attr.$observe('content', function(str) {
                  readmore(str);
              });

              transclude(scope.$parent, function(clone /*, scope */) {
                  readmore(clone.text().trim());
              });

              function readmore(text) {

                  var orig = text,
                      regex = /\s+/gi,
                      charCount = text.length,
                      wordCount = text.trim().replace(regex, ' ').split(' ').length,
                      countBy = 'char',
                      count = charCount,
                      foundWords = [],
                      markup = text,
                      more = '';

                  if (!angular.isUndefined(attr.words)) {
                      countBy = 'words';
                      count = wordCount;
                  }

                  var ellipsisElem = '<span class="more-text-ellipsis">' + ellipsis + '</span>';
                  if (countBy === 'words') { // Count words

                      foundWords = text.split(/\s+/);

                      if (foundWords.length > limit) {
                          text = foundWords.slice(0, limit).join(' ') + ellipsisElem;
                          more = foundWords.slice(limit, count).join(' ');
                          markup = text + moreText + '<span class="more-text">' + more + lessText + '</span>';
                      }

                  } else { // Count characters

                      if (count > limit) {
                          text = orig.slice(0, limit) + ellipsisElem;
                          markup = '<span class="intro">' + text + moreText + '</span><span class="more-text">' + orig + lessText + '</span>';
                      }

                  }

                  elem.append(markup);
                  elem.find('.read-more').on('click', function() {
                      jQuery(this).hide();
                      elem.find('.more-text').addClass('show').slideDown();
                      elem.find('.intro').hide();

                      if (!angular.isUndefined(attr.onToggle)) {
                          scope.$apply(function() {
                              scope.onToggle(true);
                          });
                      }
                  });
                  elem.find('.read-less').on('click', function() {
                      elem.find('.read-more').show();
                      elem.find('.intro').show();
                      elem.find('.more-text').hide().removeClass('show');

                      if (!angular.isUndefined(attr.onToggle)) {
                          scope.$apply(function() {
                              scope.onToggle(false);
                          });
                      }
                  });
              }
          }
      };
  });
