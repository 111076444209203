'use strict';

/**
 * @ngdoc function
 * @name webappMusicdirectorComApp.controller:NewReleasesCtrl
 * @description
 * # NewReleasesCtrl
 * Controller of the webappMusicdirectorComApp
 */
angular.module('musicdirectorApp')
  .controller('NewReleasesCtrl', function ($rootScope, $scope, $state, $interval, $window, Category,
                                     languageService, PubSub, seoData, userService) {
    var interval = null;
    var homepageCategory = Category.$new($rootScope.currentSite.homepageCategoryId);
    function refreshNewReleases(ignoreLoadingBar)
    {
        if (typeof ignoreLoadingBar === 'undefined') {
        ignoreLoadingBar = false;
        }

        // Exception is made for Latin America
        var carrierLimit = ($rootScope.currentSite.template === 'bmg' ||  $rootScope.currentSite.$pk === 15) ?  25 : 11;
        return homepageCategory.carriers.$refresh({ expand : 'thumbnails', 'per-page' : carrierLimit, sort : 'shuffle', _req: { ignoreLoadingBar: ignoreLoadingBar }});
    }


      refreshNewReleases().$then(function(result) {
          // Load track of first random album
          if (result[0] &&
              result[0].tracks
          ) {
              result[0].tracks.$resolve().$then(function (tracks) {
                  // Start play queue from the first track of the first random album
                  homepageCategory.play({
                      onlyWhenEmpty: true,
                      startPaused: true,
                      startAtTrack: tracks[0].$pk
                  });
              });
          }
      });
      var newsItems = $rootScope.currentSite.news.$search({ language: languageService.getCurrentLanguage(), 'per-page' : 3 });

      // Update seo data
      seoData.revertToDefault();

      $scope.data = {
          'homepageCategory' : homepageCategory,
          'newsItems' : newsItems,
          'homepageContent' : $rootScope.currentSite.homepage.$resolve({ language: languageService.getCurrentLanguage() }),
          'isSystemAdmin': userService.isSystemAdmin()
      };

      $scope.startInterval = function()
      {
          $scope.stopInterval();

          // Refresh new releases every 15 seconds
          interval = $interval(function () {
              refreshNewReleases();
          }, 15000);
      };

      $scope.stopInterval = function()
      {
          if (interval)
          {
              $interval.cancel(interval);
              interval = undefined;
          }
      };

      // Start and stop the reload interval when our controller is visible
      $scope.$on('$destroy', function() {
          $scope.stopInterval();
      });
      $scope.$on('$viewContentLoaded', function() {
          if($rootScope.currentSite.$pk !== 18){
              $scope.startInterval();
              $scope.data.currentLanguage = languageService.getCurrentLanguage();
          }
      });

      PubSub.subscribe('newReleasesCtrl.refreshNewReleases', function() {
          refreshNewReleases();
      });
});
