'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:SearchResultsCtrl
 * @description
 * # SearchResultsCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('SearchResultsCtrl', function ($scope, $rootScope, $state, $stateParams, Search, playService,
                                               gettextCatalog, searchHistory, RemotePlayQueue, saveSearchModal,
                                               uploadAudioModal, appAuthService, loadSearchModal, PubSub, Ratings,
                                               lodash, $location) {
        $scope.query = {
            site_id: $rootScope.currentSite.$pk,
            keywords: null,
            fields: null,
            sort: 'best-match',
            sort_seed: null,
            page: 1,
            expand_facets: [],
            duration: null,
            releaseyear: null,
            bpm: null,
            label: null,
            category: null,
            referenceId: null,
            resultId: null,
            startTime: null,
            endTime: null,
            rating: null,
            sub_query_keywords: [],
            lyrics_keywords: [],
            logSearch: false,
            isStem: false
        };

        $scope.data = {
            filterColumnVisible: true,
            allDetails: false,
            spellCheck: {},
            initialLoading: {
                tracks: true,
                carriers: true
            },
            loadMoreTracksDisabled: true,
            loadMoreCarriersDisabled: true,
            activeScope: 'tracks',
            totalNumberOfResults: {
                tracks: null,
                carriers: null
            },
            startPage: {
                tracks: null,
                carriers: null
            },
            pagePerScope: {
                tracks: 1,
                carriers: 1
            },
            hasMoreResults: {
                tracks: false,
                carriers: false
            },
            collapsedFilters: {
                category: true,
                label: true,
                facet_keywords: true,
                instrumentation: true,
                genre: true,
                mood: true,
                duration: true,
                releaseyear: true,
                bpm: true,
                rating: true,
                isStem: false
            },
            playlistsLoaded: false,
            emptyQuery: false,
            errors: {},
            tracksTabActive: true,
            carriersTabActive: false,
            stemFilter: false
        };
        $scope.search = Search;
        $scope.facets = [];
        $scope.playScope = null;
        $scope.musicMatcherTags = [];
        $scope.startWithQuote = false;

        // START: set all all-albums params to the default state
        if (typeof $scope.enableLoadSave === 'undefined') {
            $scope.enableLoadSave = true;
        }
        if (typeof $scope.enableTracks === 'undefined') {
            $scope.enableTracks = true;
        }
        if(typeof $scope.enableSearchWords === 'undefined'){
            $scope.enableSearchWords = true;
        }
        if (typeof $scope.sortNewest === 'undefined') {
            $scope.sortNewest = false;
        }
        if (typeof $scope.initScope === 'undefined') {
            $scope.initScope = false;
        }
        if (typeof $scope.allAlbums === 'undefined') {
            $scope.allAlbums = false;
        }
        // END: set all all-albums variables to the default state

        var latestResults = {};
        var latestFacets = {};

        if (typeof $rootScope.uploadAudioModalInstance === 'undefined') {
            $rootScope.uploadAudioModalInstance = null;
        }

        var getExpand = function() {
          var expand;

          if ($scope.data.activeScope === 'tracks') {
            expand = 'track,track.label,track.carrier,carrier.categories';
          } else if ($scope.data.activeScope === 'carriers') {
            expand = 'carrier,carrier.categories,carrier.label';
          }

          if ($rootScope.currentSite.$pk === 21) {
            expand += ',track.artistsNames,track.extra';
          }

          return expand;
        };

        var parseKeywords = function(query)
        {
            // Always use lower case strings except for youtube and soundcloud url
            if (!$scope.isYoutubeLink(query) && !$scope.isSoundcloudLink(query)) {
                query = query.replace(/,/, ' ').toLowerCase();
            }

            var isOpen = false;
            var keywords = [];
            var keywordStack = [];

            // If the search query start with double quote or single quote search in the track title
            $scope.startWithQuote = lodash.startsWith(query, '"') || lodash.startsWith(query, "'") || lodash.startsWith(query, "`");

            // Only split on single quote if there are an equal number of single quotes and no equal number of double quotes
            var splitOn = new RegExp('([",\\s])');
            if ((query.match(/'/g) || []).length % 2 === 0 && ( ( (query.match(/"/g) || []).length > 0 && (query.match(/"/g) || []).length % 2 !== 0)  || (query.match(/"/g) || []).length === 0) ) {
                splitOn = new RegExp('([\',\\s])');
            }
            // Only split on accent grave if there are an equal number of single quotes and no equal number of double quotes
            if ((query.match(/`/g) || []).length % 2 === 0 && ( ( (query.match(/'/g) || []).length > 0 && (query.match(/'/g) || []).length % 2 !== 0)  || (query.match(/'/g) || []).length === 0) && ( ( (query.match(/"/g) || []).length > 0 && (query.match(/"/g) || []).length % 2 !== 0)  || (query.match(/"/g) || []).length === 0) ) {
                splitOn = new RegExp('([`,\\s])');
            }

            angular.forEach(query.split(splitOn), function(keyword) {
                if (keyword === '"' || keyword === "'" || keyword === "`")
                {
                    isOpen = !isOpen;
                    if (!isOpen)
                    {
                        keywords.push('"' + keywordStack.join(' ') + '"');
                        keywordStack = [];
                    }
                }
                else if (keyword !== ' ' &&
                    keyword.length)
                {
                    if (isOpen)
                    {
                        keywordStack.push(keyword);
                    }
                    else if ($scope.startWithQuote)
                    {
                        $scope.query.sub_query_keywords.push(keyword);
                    }
                    else
                    {
                      keywords.push(keyword);
                    }
                }
            });

            if (keywordStack.length)
            {
                keywords.push('"' + keywordStack.join(' ') + '"');
            }

            return keywords;
        };

        var initScope = function()
        {
            enableLoadMore();
        };

        var buildUrlParams = function()
        {
            return angular.extend({}, $location.search(),{
                query: $scope.query.keywords !== null ? $scope.query.keywords.join(' ') : null,
                subQuery: $scope.query.sub_query_keywords !== null ? $scope.query.sub_query_keywords.join(' ') : null,
                genre: $scope.query.category,
                fields: $scope.query.fields ? $scope.query.fields.join(',') : null,
                catalogue: $scope.query.label,
                sort: $scope.query.sort,
                page: $scope.data.pagePerScope[$scope.data.activeScope].toString(),
                scope: $scope.data.activeScope,
                duration: $scope.query.duration,
                releaseyear: $scope.query.releaseyear,
                bpm: $scope.query.bpm,
                rating: $scope.query.rating,
                referenceId: $scope.query.referenceId,
                resultId: $scope.query.resultId,
                startTime: $scope.query.startTime,
                endTime: $scope.query.endTime,
                logSearch: $scope.query.logSearch,
                isStem: $scope.query.isStem
            });
        };

        var syncQueryToUrl = function()
        {
            $state.transitionTo($state.current.name, buildUrlParams(), { notify: false });
        };

        var updateQuery = function()
        {
            angular.forEach($scope.facets, function(filter, filterKey) {
                var values = [];
                angular.forEach(filter.values, function(value) {
                    if (value.selected)
                    {
                        values.push(value.key);
                    }
                });
                $scope.query[filterKey] = values;
            });

            // Set correct page per scope
            $scope.query.page = $scope.data.pagePerScope[$scope.data.activeScope];
        };

        var disableLoadMore = function()
        {
            $scope.data.loadMoreTracksDisabled = true;
            $scope.data.loadMoreCarriersDisabled = true;
        };

        var enableLoadMore = function()
        {
            switch ($scope.data.activeScope)
            {
                case 'tracks':
                    $scope.data.loadMoreTracksDisabled = false;
                    $scope.data.loadMoreCarriersDisabled = true;
                    break;

                case 'carriers':
                    $scope.data.loadMoreTracksDisabled = true;
                    $scope.data.loadMoreCarriersDisabled = false;
                    break;

                case 'file':
                    $scope.data.loadMoreTracksDisabled = false;
                    $scope.data.loadMoreCarriersDisabled = true;
                    break;
            }
        };

        $scope.openAudioUploader = function(){
            appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to use the MusicMatcher') }}).then(function() {
                $rootScope.uploadAudioModalInstance = uploadAudioModal({'mode': 'audioUploader'});
            });
        };

        $scope.loadMoreResults = function()
        {
            // Jump to next page
            $scope.data.pagePerScope[$scope.data.activeScope]++;

            // Disable user query search logging
            $scope.query.logSearch = false;

            updateQuery();

            syncQueryToUrl();

            disableLoadMore();

            Search[$scope.data.activeScope].$cancel().$fetch(angular.extend({
                expand: getExpand(),
                'per-page': 30
            }, $scope.query)).$then(handleResults);
        };

        $scope.addSubQueryKeyword = function(keyword)
        {
            var keywordsAdded = false;
            var keywords = parseKeywords(angular.isDefined(keyword) ? keyword : $scope.data.addKeyword, true);

            // Keywords should be an array when filled
            if ($scope.query.sub_query_keywords === null)
            {
                $scope.query.sub_query_keywords = [];
            }

            angular.forEach(keywords, function (keyword) {
                if ($scope.query.sub_query_keywords.indexOf(keyword) === -1) {
                    $scope.query.sub_query_keywords.push(keyword);
                    keywordsAdded = true;
                }
            });

            // Clear keyword field
            $scope.data.addKeyword = '';

            if (keywordsAdded)
            {
                $scope.refresh();
            }
        };

        $scope.addLyricsKeyword = function(lyricsKeyword)
        {
            var keywordsAdded = false;
            var keywords = parseKeywords(angular.isDefined(lyricsKeyword) ? lyricsKeyword : $scope.data.addLyricsKeyword, true);

            // Keywords should be an array when filled
            if ($scope.query.lyrics_keywords === null)
            {
                $scope.query.lyrics_keywords = [];
            }

            angular.forEach(keywords, function (keyword) {
                if ($scope.query.lyrics_keywords.indexOf(keyword) === -1) {
                    $scope.query.lyrics_keywords.push(keyword);
                    keywordsAdded = true;
                }
            });

            // Clear keyword field
            $scope.data.addLyricsKeyword = '';

            if (keywordsAdded)
            {
                $scope.refresh();
            }
        };

        $scope.toggleAllDetails = function() {
            $scope.data.allDetails = !$scope.data.allDetails;
            latestResults.tracks.toggleDetails($scope.data.allDetails);
        };

        function handleResults(results) {
            if (results.$metadata.currentPage > 1)
            {
                latestResults.meta = results.$metadata;
            }
            else
            {
                latestResults = results;
            }
            if (results.$response)
            {
                $scope.results.links = results.$response.data._links;
                latestFacets = results.$response.data.facets;
                $scope.musicMatcherTags = results.$response.data.musicMatcherTags;

                for (var facetKey in latestFacets)
                {
                    if (latestFacets.hasOwnProperty(facetKey)) {
                        latestFacets[facetKey].name = facetKey;

                        for (var facetValue in latestFacets[facetKey].values) {
                            if (latestFacets[facetKey].values.hasOwnProperty(facetValue) &&
                                latestFacets[facetKey].values[facetValue].key
                            ) {
                                switch (facetKey) {
                                    case 'rating':
                                        // set label to verbose rating name
                                        var ratingIndex = String(latestFacets[facetKey].values[facetValue].key).toUpperCase();
                                        if (Ratings.hasOwnProperty(ratingIndex)) {
                                            latestFacets[facetKey].values[facetValue].label = Ratings[ratingIndex] + ' (' + ratingIndex + ')';
                                        } else {
                                            latestFacets[facetKey].values[facetValue].label = ratingIndex;
                                        }
                                        break;

                                    default:
                                        latestFacets[facetKey].values[facetValue].label = latestFacets[facetKey].values[facetValue].label ? String(latestFacets[facetKey].values[facetValue].label).toLowerCase() : String(latestFacets[facetKey].values[facetValue].key).toLowerCase();
                                        break;
                                }
                            }
                        }
                    }
                }

                $scope.facets = latestFacets;
            }

            $scope.query.sort_seed = results.$metadata.sortSeed;
            $scope.data.initialLoading[$scope.data.activeScope] = false;
            $scope.data.totalNumberOfResults[$scope.data.activeScope] = results.$metadata.totalCount;
            $scope.data.hasMoreResults[$scope.data.activeScope] = results.$metadata.currentPage < results.$metadata.pageCount;
            $scope.data.spellCheck = results.$response.data.spellCheck;
            $scope.data.emptyQuery = results.$metadata.emptyQuery;
            $scope.data.errors = results.$metadata.errors;

            if ($scope.data.hasMoreResults[$scope.data.activeScope])
            {
                enableLoadMore();
            }

            // initialize player
            switch ($scope.data.activeScope)
            {
                case 'tracks':
                case 'file':
                    // Open current results as play queue in player
                    if (results.length)
                    {
                        // Initialize play queue for current search query
                        $scope.playScope = new RemotePlayQueue(Search, $scope.query, 'post');
                        $scope.playScope.setAllowShuffle(false);
                        $scope.playScope.load().then(function() {
                            playService.playQueue($scope.playScope, {startPaused: true, onlyWhenEmpty: true});
                        });
                    }
                    break;
            }

            // Add the page index
            var index = 1;
            for (var i in results) {
                if (results[i] && typeof results[i] === 'object' && results[i].pageIndex === undefined) {
                    results[i].pageIndex = parseInt(results.$metadata.currentPage);
                    results[i].index = index++;
                }
            }
            if ($rootScope.uploadAudioModalInstance) {
                $rootScope.$emit('audioModalClose');
                $rootScope.uploadAudioModalInstance.close();
            }

            PubSub.publish('searchResults.updated');
        }

        function stemFilter(){
            if($scope.query.keywords && $scope.query.keywords.includes('stem')){
                $scope.query.isStem = true;
                return;
            }
            if($scope.query.sub_query_keywords && $scope.query.sub_query_keywords.includes('stem')){
                $scope.query.isStem = true;
                return;
            }
            if($scope.data.stemFilter){
                $scope.query.isStem = true;
                return;
            }
            if($scope.query.fields && $scope.query.fields.includes('is_stem')){
                $scope.query.isStem = true;
                return;
            }
            $scope.query.isStem = false;
        }

        $scope.refresh = function(options) {
            // Enable user query search logging
            $scope.query.logSearch = true;

            stemFilter();

            options = angular.extend({
                startPage: 1,
                resetInitialLoading: false,
                syncUrl: true
            }, options);

            // Initialize the start page
            $scope.data.startPage[$scope.data.activeScope] =
                $scope.data.pagePerScope[$scope.data.activeScope] = Math.max(1, options.startPage);

            // Initial loading, show loaders and hide results
            if (options.resetInitialLoading) {
                $scope.data.initialLoading[$scope.data.activeScope] = true;
            }

            updateQuery();

            // Sync searching query to URL
            if (options.syncUrl)
            {
                syncQueryToUrl();
            }

            disableLoadMore();

            Search[$scope.data.activeScope].$cancel().$refresh(angular.extend({
                expand: getExpand(),
                'per-page': 30
            }, $scope.query)).$then(handleResults);
        };

        $scope.stemRefresh = function(){
            if(!$scope.data.stemFilter){
                // remove is_stem from the fields
                if($scope.query.fields && $scope.query.fields.includes('is_stem')){
                    var index = $scope.query.fields.indexOf('is_stem');
                    $scope.query.fields.splice(index, 1);
                }
            }
            $scope.refresh();
        }

        $scope.setSearchScope = function(scope)
        {
            if ($scope.data.activeScope !== scope)
            {
                $scope.data.activeScope = scope;
                $scope.data.tracksTabActive = scope === 'tracks';
                $scope.data.carriersTabActive = scope === 'carriers';
                initScope();
                $scope.refresh();
            }
        };

        $scope.toggleColumn = function()
        {
            $scope.data.filterColumnVisible = !$scope.data.filterColumnVisible;
        };

        $scope.setSort = function(sort)
        {
            $scope.query.sort = sort;

            // Always reset random sort seed
            $scope.query.sort_seed = null;

            $scope.refresh();
        };

        $scope.removeKeyword = function(index)
        {
            $scope.query.keywords.splice(index, 1);

            $scope.refresh();
        };

        $scope.removeSubQueryKeyword = function(index)
        {
            $scope.query.sub_query_keywords.splice(index, 1);

            $scope.refresh();
        };

        $scope.removeLyricsKeyword = function(index)
        {
            $scope.query.lyrics_keywords.splice(index, 1);

            $scope.refresh();
        };

        $scope.isSort = function(sort)
        {
            return $scope.query.sort === sort;
        };

        $scope.expandFilter = function(filterName)
        {
            $scope.query.expand_facets.push(filterName);
            $scope.refresh();
        };

        $scope.contractFilter = function(filterName)
        {
            var index = $scope.query.expand_facets.indexOf(filterName);
            if (index > -1)
            {
                $scope.query.expand_facets.splice(index, 1);
            }
            $scope.refresh();
        };

        $scope.toggleFilterCollapse = function(filter)
        {
            if (typeof filter === 'string') {
                filter = { name: filter };
            }

            if (filter.name in $scope.data.collapsedFilters) {
                $scope.data.collapsedFilters[filter.name] = !$scope.data.collapsedFilters[filter.name];
            } else {
                $scope.data.collapsedFilters[filter.name] = true;
            }
        };

        $scope.handleDurationChange = function(form)
        {
            if (!form.$valid)
            {
                return;
            }
            var currentDurationQuery = $scope.query.duration;

            if ($scope.data.durationFrom &&
                $scope.data.durationThrough)
            {
                $scope.query.duration = $scope.data.durationFrom + '-' + $scope.data.durationThrough;
            }
            else if ($scope.data.durationFrom)
            {
                $scope.query.duration = $scope.data.durationFrom + '-';
            }
            else if ($scope.data.durationThrough)
            {
                $scope.query.duration = '-' + $scope.data.durationThrough;
            }
            else
            {
                $scope.query.duration = null;
            }

            // Refresh results on changed value
            if (currentDurationQuery !== $scope.query.duration)
            {
                $scope.refresh();
            }
        };

        $scope.handleReleaseYearChange = function(form)
        {
            if (!form.$valid)
            {
                return;
            }

            var currentReleaseyearQuery = $scope.query.releaseyear;

            if ($scope.data.releaseYearStart &&
                $scope.data.releaseYearEnd)
            {
                $scope.query.releaseyear = $scope.data.releaseYearStart + '-' + $scope.data.releaseYearEnd;
            }
            else if ($scope.data.releaseYearStart)
            {
                $scope.query.releaseyear = $scope.data.releaseYearStart + '-';
            }
            else if ($scope.data.releaseYearEnd)
            {
                $scope.query.releaseyear = '-' + $scope.data.releaseYearEnd;
            }
            else
            {
                $scope.query.releaseyear = null;
            }

            // Refresh results on changed value
            if ($scope.query.releaseyear !== currentReleaseyearQuery)
            {
                $scope.refresh();
            }
        };

        $scope.handleBpmChange = function(form)
        {
            if (!form.$valid)
            {
              return;
            }
            var currentBpmQuery = $scope.query.bpm;

            if ($scope.data.bpmFrom &&
              $scope.data.bpmThrough)
            {
              $scope.query.bpm = $scope.data.bpmFrom + '-' + $scope.data.bpmThrough;
            }
            else if ($scope.data.bpmFrom)
            {
              $scope.query.bpm = $scope.data.bpmFrom + '-';
            }
            else if ($scope.data.bpmThrough)
            {
              $scope.query.bpm = '-' + $scope.data.bpmThrough;
            }
            else
            {
              $scope.query.bpm = null;
            }

            // Refresh results on changed value
            if (currentBpmQuery !== $scope.query.bpm)
            {
              $scope.refresh();
            }
        };

        $rootScope.getPageQuery = function(page)
        {
            $scope.data.pagePerScope[$scope.data.activeScope] = page;
            var defaultFields = {
                query: '',
                subQuery: '',
                fields: null,
                genre: null,
                catalogue: null,
                duration: null,
                releaseyear: null,
                bpm: null,
                rating: null,
                sort: 'best-match',
                page: '1',
                referenceId: '',
                resultId: '',
                startTime: null,
                endTime: null,
                logSearch: false
            };
            var params = '';
            var pageQuery = buildUrlParams();
            for (var key in defaultFields)
            {
                if (pageQuery[key] !== null && pageQuery[key].length > 0) {
                    params += (params ? '&' : '') + key + '=' + pageQuery[key];
                }
                else if (defaultFields[key] !== null) {
                    params += (params ? '&' : '') + key + '=' + defaultFields[key];
                }
            }
            return params;
        };

        function initQueryFromStateParams()
        {
            $scope.query.keywords = $stateParams.query ? parseKeywords($stateParams.query) : null;
            $scope.query.sub_query_keywords = $scope.query.sub_query_keywords.concat($stateParams.subQuery ? parseKeywords($stateParams.subQuery) : []);
            if ($scope.startWithQuote) {
                $scope.query.fields = ['track_title'];
            }
            else {
                $scope.query.fields = $stateParams.fields ? $stateParams.fields.split(/,/) : null;
            }

            if ($stateParams.scope === 'tracks' ||
                $stateParams.scope === 'carriers')
            {
                $scope.data.activeScope = $stateParams.scope;
                $scope.data.tracksTabActive = $stateParams.scope === 'tracks';
                $scope.data.carriersTabActive = $stateParams.scope === 'carriers';
            }
            if ($stateParams.catalogue !== null) {
                $scope.query.label = angular.isArray($stateParams.catalogue) ? $stateParams.catalogue : [$stateParams.catalogue];
            }
            if ($stateParams.genre !== null) {
                $scope.query.category = angular.isArray($stateParams.genre) ? $stateParams.genre : [$stateParams.genre];
            }
            if ($stateParams.rating !== null) {
                $scope.query.rating = angular.isArray($stateParams.rating) ? $stateParams.rating : [$stateParams.rating];
            }
            $scope.query.sort = $stateParams.sort;
            $scope.query.page = $scope.data.pagePerScope[$scope.data.activeScope] = parseInt($stateParams.page);

            var matches;
            if ($stateParams.duration &&
                (matches = $stateParams.duration.match(/^([0-9]*)\-([0-9]*)$/)) !== null)
            {
                $scope.query.duration = $stateParams.duration;
                $scope.data.durationFrom = parseInt(matches[1]);
                $scope.data.durationThrough = parseInt(matches[2]);
            }
            if ($stateParams.releaseyear &&
                (matches = $stateParams.releaseyear.match(/^([0-9]*)\-([0-9]*)$/)) !== null)
            {
                $scope.query.releaseyear = $stateParams.releaseyear;
                $scope.data.releaseYearStart = parseInt(matches[1]);
                $scope.data.releaseYearEnd = parseInt(matches[2]);
            }
            if ($stateParams.bpm &&
            (matches = $stateParams.bpm.match(/^([0-9]*)\-([0-9]*)$/)) !== null)
            {
                $scope.query.bpm = $stateParams.bpm;
                $scope.data.bpmFrom = parseInt(matches[1]);
                $scope.data.bpmThrough = parseInt(matches[2]);
            }
            if ($stateParams.referenceId)
            {
                $scope.query.referenceId = $stateParams.referenceId;
            }
            if ($stateParams.resultId)
            {
                $scope.query.resultId = $stateParams.resultId;
            }
            if ($stateParams.startTime)
            {
                $scope.query.startTime = $stateParams.startTime;
            }
            if ($stateParams.endTime)
            {
                $scope.query.endTime = $stateParams.endTime;
            }
            if($stateParams.logSearch)
            {
                $scope.query.logSearch = $stateParams.logSearch;
            }
        }

        $scope.activatePlaylistsTab = function() {
            $scope.data.playlistsLoaded = true;
        };

        $scope.saveSearch = function()
        {
            var urlParamsString = '';
            var urlParams = buildUrlParams();
            angular.forEach(urlParams, function(urlParamValue, urlParamKey) {
                if (urlParamsString !== '') {
                    urlParamsString += '&';
                }
                urlParamsString += urlParamKey + '=' + encodeURIComponent(urlParamValue);
            });

            saveSearchModal({
                urlParamsString: urlParamsString
            });
        };
        $scope.isYoutubeLink = function (url) {
            var regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var matchYoutube = url.match(regExpYoutube);
            return matchYoutube && matchYoutube[2].length === 11;
        };

        $scope.isSoundcloudLink = function (url) {
            var regExpSoundcloud = /^.*?soundcloud\.com\/([\w\-.]+)\/([\w\-.]+)/;
            var matchSoundcloud = url.match(regExpSoundcloud);
            return matchSoundcloud && matchSoundcloud[2];
        };

        $scope.hasYoutubeLink = function (keywords) {
            for (var index in keywords) {
                if ($scope.isYoutubeLink(keywords[index])) {
                    return true;
                }
            }
            return false;
        };

        $scope.hasSoundcloudLink = function (keywords) {
            for (var index in keywords) {
                if ($scope.isSoundcloudLink(keywords[index])) {
                    return true;
                }
            }
            return false;
        };

        $scope.hasStreamingLink = function(keywords) {
            for (var index in keywords) {
                if ($scope.isYoutubeLink(keywords[index])) {
                    return true;
                }
                if ($scope.isSoundcloudLink(keywords[index])) {
                    return true;
                }
            }
            return false;
        };

        function handleStateChange(event, toState, toParams, fromState, fromParams)
        {
            // We're going from the search results to a new state
            if (fromState.name === 'root.search-results' &&
                toState.name !== 'root.search-results')
            {
                searchHistory.showGrowl({ params: fromParams });
            }
        }

        $scope.loadSearch = function()
        {
            return appAuthService.requireLogin({modal: {message: gettextCatalog.getString('Please login to load searches.')}}).then(function () {
                loadSearchModal();
            });
        };

        // Handle outgoing links to other pages
        var stateChangeBind = $rootScope.$on('$stateChangeSuccess', handleStateChange);
        $scope.$on('$destroy', stateChangeBind);

        var contentLoaded = function(){
            if($rootScope.referenceId) {
                $scope.query.referenceId = $rootScope.referenceId;
                $scope.query.searchType = 'audioFile';
                $rootScope.referenceId = null;
            }
            if($rootScope.startTime) {
                $scope.query.startTime = $rootScope.startTime;
                $rootScope.startTime = null;
            }
            if($rootScope.endTime) {
                $scope.query.endTime = $rootScope.endTime;
                $rootScope.endTime = null;
            }
            $scope.data.initialLoading = { tracks: true, carriers: true };
            $scope.results = {};

            // Check for streaming links as keyword
            if ($stateParams.length && $scope.hasStreamingLink([$stateParams.query])) {
                return $state.go('root.music-matcher', {
                    url: $stateParams.query
                });
            }

            initQueryFromStateParams();
            initScope();

            $scope.data.stemFilter = ($scope.query.fields && $scope.query.fields.includes('is_stem'));

            // Hide 'return to results' growl, if present
            searchHistory.hideGrowl();

            $scope.refresh({ startPage: $scope.query.page, syncUrl: false });
        };

        $scope.$on('$viewContentLoaded', function() {
            contentLoaded();
        });


        if ($rootScope.retrieveSimilarByFileId2Init !== true) {
            $rootScope.$on('retrieveSimilarByFileId2', function (data, referenceId) {
                $rootScope.referenceId = referenceId;
            });
        }
        $rootScope.retrieveSimilarByFileId2Init = true;
        if (!$rootScope.referenceId) {
            $rootScope.referenceId = null;
        }

        // Initiate search request not from the searchbar
        if ($scope.initScope) {
            $scope.results = {};
            $scope.data.filterColumnVisible = false;
            delete $scope.data.collapsedFilters.duration;
            delete $scope.data.collapsedFilters.bpm;
            delete $scope.data.collapsedFilters.isStem;
            if($scope.sortNewest){
                $scope.query.sort = 'newest';
            }
            $scope.setSearchScope('carriers');
        }

        $scope.showFilter = function(filterName){
            return $scope.data.collapsedFilters.hasOwnProperty(filterName);
        };
    });
