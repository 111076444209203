'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.confirmModal
 * @description
 * # confirmModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('confirmModal', function ($uibModal, gettextCatalog) {
        return function(_options) {
            var instance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                templateUrl: 'views/confirm-modal.html',
                controller: 'ConfirmModalCtrl',
                controllerAs: 'ConfirmModalCtrl',
                windowClass: 'confirm-modal',
                resolve: {
                    modalOptions : function() {
                        return angular.extend({
                            title: gettextCatalog.getString('Confirm action'),
                            yesText: gettextCatalog.getString('Yes'),
                            noText: gettextCatalog.getString('No')
                        }, _options);
                    }
                }
            });

            return instance.result;
        };
    });
