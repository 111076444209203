'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:LoadSearchModalCtrl
 * @description
 * # LoadSearchModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('LoadSearchModalCtrl', function ($scope, $state, options, searchQueries) {
        // Use an existing model for editing or create a new one
        $scope.options = options;

        $scope.formData = {
            selectedSearchQuery: null
        };
        $scope.data = {
            searchQueries: searchQueries,
            hasSearchQueries: searchQueries.length > 0
        };

        $scope.removeSearchQuery = function(searchQuery) {
            searchQuery.$destroy().$then(function() {
                $scope.data.hasSearchQueries = $scope.data.searchQueries.length > 0;
            });
        };

        this.cancel = function() {
            $scope.$dismiss();
        };

        var parseQueryString = function(queryString) {
            var params = {}, queries, temp, i, l;

            // Split into key/value pairs
            queries = queryString.split('&');

            // Convert the array of strings into an object
            for ( i = 0, l = queries.length; i < l; i++ ) {
                temp = queries[i].split('=');
                if (temp[1] !== 'null' &&
                    temp[1] !== null)
                {
                    params[temp[0]] = decodeURIComponent(temp[1]);
                }
            }

            return params;
        };

        this.submit = function () {
            if ($scope.loadSearchForm.$valid) {
                // Switch to new state
                var params = parseQueryString($scope.formData.selectedSearchQuery.searchurl);
                if (params.genre)
                {
                    params.genre = params.genre.split(/,/);
                }
                if (params.catalogue)
                {
                    params.catalogue = params.catalogue.split(/,/);
                }
                $state.go('root.search-results', params);

                // Close overlay
                $scope.$close();
            }
        };
    });
