'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:setClassWhenAtTop
 * @description
 * # setClassWhenAtTop
 */
angular.module('musicdirectorApp')
    .directive('languageSwitch', function () {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'views/bmg/language-switch.html',
            controllerAs: 'LanguageSwitchCtrl',
            link: function(scope, element){
                // Close menu when click outside the menu
                jQuery(document).bind('click', function(event){
                    if (scope.data.open) {
                        var isClickedElementChildOfPopup = element
                            .find(event.target)
                            .length > 0;

                        if (isClickedElementChildOfPopup) {
                            return;
                        }

                        scope.$apply(function () {
                            scope.data.open = false;
                        });
                    }
                });
            },
            controller: function LanguageSwitchCtrl($scope, $state, Analytics, languageService, localStorageService, tmhDynamicLocale) {
                $scope.data = {
                    currentLanguage: languageService.getCurrentLanguage(),
                    availableLanguages: languageService.getAvailableLanguages(),
                    visible: languageService.getAvailableLanguages().length > 1,
                    open: false
                };

                this.toggle = function() {
                    $scope.data.open = !$scope.data.open;
                };

                this.switchLanguage = function(lang) {
                    // Track the language switch
                    Analytics.trackEvent('user', 'switch-language', lang);
                    tmhDynamicLocale.set(lang);
                    localStorageService.set('language', lang);
                    $state.go('root.index', {}, { reload : true });
                };
            }
        };
    });
