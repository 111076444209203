'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.loginModal
 * @description
 * # loginModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('loadSearchModal', function($uibModal, SearchQuery) {
        return function(_options) {
            var instance = $uibModal.open({
                animation: false,
                templateUrl: 'views/load-search-modal.html',
                controller: 'LoadSearchModalCtrl',
                controllerAs: 'LoadSearchModalCtrl',
                windowClass: 'load-search-modal',
                resolve: {
                    options : function() {
                        return _options;
                    },
                    searchQueries: function() {
                        return SearchQuery.$search().$asPromise();
                    }
                }
            });

            return instance.result;
        };
    });
