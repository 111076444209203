'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:setClassWhenAtTop
 * @description
 * # setClassWhenAtTop
 */
angular.module('musicdirectorApp')
    .directive('siteSwitch', function () {
        return {
            restrict: 'E',
            scope: {},
            template: '<div ng-include="templateUrl"></div>',
            controllerAs: 'SiteSwitchCtrl',
            controller: function SiteSwitchCtrl($scope, PubSub, Site, $window) {
                $scope.data = {
                    currentSiteType: $scope.$root.currentSite.type,
                    availableSites: {},
                    open: false
                };

                // find 'first' sites for Music and FX types
                this.initSites = function() {
                    Site.$search().$then(function (sites) {
                        var sortedSites = sites.sort(function(a, b) {
                            return a.$pk - b.$pk;
                        });
                        var fxSites = sortedSites.filter(function (site) {
                            return site.type === 'fx';
                        });
                        var musicSites = sortedSites.filter(function (site) {
                            return site.type === 'music';
                        });

                        var musicSite = $scope.$root.currentSite.type === 'music' ? angular.copy($scope.$root.currentSite) :
                            (musicSites ? musicSites[0] : null);
                        if (musicSite) {
                            musicSite.title = 'Music';
                        }

                        var fxSite = $scope.$root.currentSite.type === 'fx' ? angular.copy($scope.$root.currentSite) :
                            (fxSites ? fxSites[0] : null);
                        if (fxSite) {
                            fxSite.title = 'FX';
                        }

                        $scope.data.availableSites = {
                            music: musicSite,
                            fx: fxSite
                        };
                    });
                };
                this.initSites();

                this.toggle = function() {
                    $scope.data.open = !$scope.data.open;
                };

                this.switchSite = function (site) {
                    $window.location.href = (site.useSsl ? 'https' : 'http') + '://' + site.primaryDomain;
                };

                PubSub.subscribe('userService.onCurrentUserChange', function () {
                    this.initSites();
                }.bind(this));

                this.getTemplateUrl = function() {
                    if ($scope.$root.currentSite.template === 'bmg') {
                        return 'views/' + $scope.$root.currentSite.template + '/site-switch.html';
                    }
                    return 'views/site-switch.html';
                };
            },
            link: function(scope, element, attrs, controller){
                scope.templateUrl = controller.getTemplateUrl();

                // Close menu when click outside the menu
                jQuery(document).bind('click', function(event){
                    if (scope.data.open) {
                        var isClickedElementChildOfPopup = element
                            .find(event.target)
                            .length > 0;

                        if (isClickedElementChildOfPopup) {
                            return;
                        }

                        scope.$apply(function () {
                            scope.data.open = false;
                        });
                    }
                });
            },
        };
    });
