import StickySidebar from '@musicdirector/sticky-sidebar';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:stickySidebar
 * @description
 * # stopEvent
 */
angular.module('musicdirectorApp')
    .directive('stickySidebar', function (PubSub) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                var options = {
                    innerWrapperSelector: '.sidebar__inner',
                    containerSelector: '.container.search-results'
                };
                if (attr.topSpacing) {
                    options.topSpacing = attr.topSpacing;
                }
                if (attr.bottomSpacing) {
                    options.bottomSpacing = attr.bottomSpacing;
                }

                const sideBar = new StickySidebar(element[0], options);

                PubSub.subscribe('searchResults.updated', function() {
                    sideBar.updateSticky();
                });
            }
        };
    });
