'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistCtrl
 * @description
 * # PlaylistCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistCtrl', function ($scope, $window, playlistEditModal, playlistEditorModal, playlistExportModal, shareModal, debounce,
                                          playlistImportModal, Track, confirmModal, gettextCatalog) {
        this.dragDropDebounce = null;

        $scope.droppedItem = {};
        $scope.dndDragItem = {};

        $scope.jqOptions = { hoverClass: 'drop-hover', tolerance: 'pointer' };
        $scope.jqDroppable = { onDrop: 'addItemToPlaylist', onOver: 'onDragDropOver', onOut: 'onDragDropOut' };
        $scope.template = {
            playlistContent: 'views/playlist-content.html',
            folderContent: 'views/playlist-folder-content.html'
        };

        // DND add track to playlist list
        $scope.trackToPlaylistDND = {
            allowedTypes: ['track'],
            droplist: [],
        };

        // DND subfolders
        $scope.foldersDND = {
            allowedTypes: ['folder'+$scope.playlist.$pk],
            list: [],
            sequence: []
        };

        $scope.resequenceFolders = function(targetIndex, item, sourceIndex){
            // remove item from list
            $scope.foldersDND.sequence.splice(sourceIndex, 1);

            // taking the removed item into account
            if(targetIndex > sourceIndex) {
                targetIndex--;
            }

            // adding the item to the list
            $scope.foldersDND.sequence.splice(targetIndex, 0, item);

            // setup new order for the sequence function
            var newOrder = [];
            angular.forEach($scope.foldersDND.sequence, function(value){
                newOrder.push(value.ID);
            });

            // update backend with new order
            $scope.playlist.sortFolders(newOrder);

            // returning true so the default adding functionality doesn't trigger
            return true;
        };

        $scope.$watch('playlist.folders.length', function(){
            // empty sequence variables to rebuild the new list
            $scope.foldersDND.list = [];
            $scope.foldersDND.sequence = [];

            angular.forEach($scope.playlist.folders, function(folder){
                var key = 'fol' + folder.$pk.toString();
                var ID = folder.$pk;
                var title = folder.title;
                $scope.foldersDND.sequence.push({
                    key: key,
                    ID: ID,
                    title: title,
                    type: $scope.foldersDND.allowedTypes[0]
                });
                $scope.foldersDND.list[key] = folder;
            });
        });

        $scope.setAsFavorite = function (target) {
            $scope.playlistsBarCtrl.toggleFavorite(target);
        };

        $scope.edit = function (model) {
            model = model || this.playlist;
            return playlistEditModal({model: model, folder: (model.reference !== null)});
        };

        $scope.editor = function (model) {
            model = model || this.playlist;
            return playlistEditorModal({model: model, folder: (model.reference !== null)});
        };

        $scope.share = function () {
            return this.playlist.share();
        };

        $scope.shareSubfolder = function(folder) {
            return folder.share();
        };

        $scope.clone = function () {
            $scope.playlistsBarCtrl.clonePlaylist(this.playlist);
        };

        $scope.export = function (model, format) {
            model = model || this.playlist;
            model.export(format);
        };

        $scope.toggleFolders = function (state) {
            if (!$scope.playlist.$isOpen ||
                state === true) {
                $scope.playlist.folders.$resolve().$then(function () {
                    $scope.playlist.$isOpen = true;
                });
            }
            else {
                $scope.playlist.$isOpen = false;
            }
        };

        $scope.createFolder = function () {
            var newFolder = this.playlist.folders.$build();
            var self = this;
            return playlistEditModal({model: newFolder, folder: true}).then(function () {
                self.playlist.folders.$refresh();
                self.playlist.$refresh();
            });
        };

        function addTrackToPlaylist(track, dndScope){
            if (dndScope.folder) {
                track.addToPlaylist(dndScope.folder);
            } else {
                track.addToPlaylist(dndScope.playlist);
            }
        }

        $scope.addItemToPlaylist = function (event) {
            // check if client has right to alter/add tracks to playlist
            if(!this.playlist.isOwner && !this.playlist.isEditor) {
                return false;
            }
            var dndScope = angular.element(event.target).scope();
            addTrackToPlaylist(dndScope.dndDragItem, dndScope);
            return true;
        };

        $scope.addDNDItemToPlaylist = function(event, item){
            // check if we have a track or folder
            if(item.type === 'folder'){
                return false;
            }

            // check if client has right to alter/add tracks to playlist
            if(!this.playlist.isOwner && !this.playlist.isEditor) {
                return false;
            }
            var dndScope = angular.element(event.target).scope();
            var track = Track.$find(item.ID);
            addTrackToPlaylist(track, dndScope);
            return true;
        };

        $scope.unfollow = function () {
            this.playlist.unfollow().then(function (playlist) {
                // Remove the playlist from the playlist collection
                playlist.$scope.$remove(playlist);
            });
        };

        $scope.delete = function (playlist) {
            confirmModal({
                title: gettextCatalog.getString('Confirm'),
                message: gettextCatalog.getString('Are you sure you want to remove this playlist? This action cannot be undone.'),
                additionalCheckboxValue: false,
                additionalCheckboxText: gettextCatalog.getString('I understand that this will also remove all folders inside the project.'),
                additionalCheckbox: playlist.folderCount > 0
            }).then(function() {
                if (playlist.reference !== null) {
                    if (playlist.$isSelected) {
                        $scope.playlistsBarCtrl.selectPlaylist(playlist.reference);
                    }
                    if (playlist.isFavorite) {
                        $scope.playlistsBarCtrl.toggleFavorite(playlist.reference);
                    }
                    playlist.reference.removeFolder(playlist);
                } else {
                    if (playlist.$isSelected) {
                        $scope.playlistsBarCtrl.selectPlaylist(null);
                    }
                    if (playlist.isFavorite) {
                        $scope.playlistsBarCtrl.toggleFavorite(null);
                    }
                    playlist.$destroy();
                }
            });
        };

        $scope.onDragDropOver = function () {
            if ($scope.playlist.hasFolders) {
                this.dragDropDebounce = debounce(1500, function () {
                    $scope.toggleFolders(true);
                });
            }
        };

        $scope.onDragDropOut = function () {
            if (this.dragDropDebounce) {
                this.dragDropDebounce.cancel();
            }
        };

        $scope.importPlaylistIntoPlaylist = function(playlistID)
        {
            return playlistImportModal(playlistID).then(function(/* playlist */) {
                // Add the newly imported playlist, fetch from page 1
                $scope.playlistsBarCtrl.refreshPlaylists();
            });
        };
    });
