'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:intoViewOnPlay
 * @description
 * # intoViewOnPlay
 */
angular.module('musicdirectorApp')
  .directive('intoViewOnPlay', function () {
    return {
      template: '<div></div>',
      restrict: 'E',
      link: function postLink(scope, element /*, attrs */) {
        element.text('this is the intoViewOnPlay directive');
      }
    };
  });
