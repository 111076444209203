'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:autoScaleSearchfield
 * @description
 * # autoScaleSearchfield
 */
angular.module('musicdirectorApp')
    .directive('autoScaleSearchfield', function ($rootScope, $timeout, $window, PubSub) {
      return {
        restrict: 'A',
        link: function postLink(scope, element) {
          scope.onResize = function () {
            $timeout(function() {
              var parentWidth = element.parent().width();
              var siblings = element.siblings();
              var siblingsWidth = 0;
              angular.forEach(siblings, function(sibling) {
                siblingsWidth += angular.element(sibling).width();
              });
              element.css('width', (parentWidth - siblingsWidth - 2) + 'px');
            }, 100);
          };
          $timeout(function() {
              scope.onResize();
          }, 0);

          angular.element($window).bind('resize', scope.onResize);

          var subscription = PubSub.subscribe('languageService.onLanguageChange', function() {
            scope.onResize();
          });

          scope.$on('$destroy', function() {
            angular.element($window).unbind('resize', scope.onResize);
            PubSub.unsubscribe(subscription);
          });
        }
      };
    });
