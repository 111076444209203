'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.audioService
 * @description
 * # audioService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('audioService', ['$document', function($document) {
        var audio = $document[0].createElement('audio');
        return audio;
    }]);
