'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('PlaylistTrack', function (restmod) {
        return restmod.model().mix({
            playlist: { belongsTo: 'Playlist'},
            track: { hasOne: 'Track' },
            $config: {
                'name': 'playlistTrack',
                'plural': 'playlistTracks'
            },
            $extend: {
                Record: {
                    play: function (scope, options) {
                        return this.track.play(scope, options);
                    }
                }
            }
        });
    });