'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:RegistrationModalCtrl
 * @description
 * # RegistrationModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('RegistrationModalCtrl', function ($rootScope, $scope, $sce, $q, gettextCatalog, companyTypes, jobTitles, infoModal,
                                                   companySelectModal, Country, Account, Company, modalOptions,
                                                   registrationPage, registrationErrorTextChina, registrationFields, languageService, Analytics,
                                                   privacyPolicyModal, businessSectors, BusinessType, BusinessSector)
    {
        $scope.companyTypes = [{ID: 0, title : gettextCatalog.getString('Select company type')}];
        $scope.businessSectors = [];
        $scope.businessTypes = [];
        $scope.otherBusinessSectorId = -1;
        $scope.otherBusinessTypeId = -1;

        angular.forEach(companyTypes, function(value) {
            $scope.companyTypes.push({ ID: value.ID, title: value.title });
        });

        angular.forEach(businessSectors, function(value) {
            $scope.businessSectors.push({ ID: value.id, title: value.title });
            if (value.title.toLowerCase() === 'other') {
                $scope.otherBusinessSectorId = value.id;
            }
        });

        var mapBusinessTypes = function(businessTypes){
            angular.forEach(businessTypes, function(value) {
                $scope.businessTypes.push({ ID: value.id, title: value.title });
                if (value.title.toLowerCase() === 'other') {
                    $scope.otherBusinessTypeId = value.id;
                }
            });
        };

        $scope.updateBusinessTypes = function() {
            $scope.businessTypes = [];
            BusinessSector.$find($scope.companyData.businessSector+'/business-types').$then(function(businessSector){
                mapBusinessTypes(businessSector.businessTypes);
            });
        };

        $scope.jobTitles = [];
        angular.forEach(jobTitles, function(value) {
            $scope.jobTitles.push({ ID: value.ID, title: value.title });
        });

        $scope.registration = angular.isDefined(modalOptions.initialData) ? modalOptions.initialData : {};
        $scope.registration.jobTitle = ($scope.currentSite.$pk === 18 || $scope.currentSite.$pk === 12) ? 0 : null;
        $scope.registration.jobTitleDescription = null;
        $scope.socialRegistrationData = angular.isDefined(modalOptions.socialRegistrationData) ? modalOptions.socialRegistrationData : {};
        $scope.companyData = {
            country: $scope.currentSite.defaultCountryID,
            type: 0,
            businessSector: null,
            businessType: null,
            formControl: null,
            businessSectorDescription: null,
            businessTypeDescription: null
        };
        $scope.data = {
            isSocialRegistration: $scope.socialRegistrationData !== null && !angular.equals({}, $scope.socialRegistrationData),
            registrationPage: registrationPage,
            showJobTitle: [1, 12, 18, 19].indexOf($scope.currentSite.$pk) !== -1,
            isLoading: false,
            formHasErrors: false,
            showHouseNo: $scope.currentSite.$pk === 20,
            newsletterInfoText: gettextCatalog.getString('We use ClickDimensions as our marketing automation platform. By ticking the box for newsletter subscription, you acknowledge that the information you provide will be transferred to ClickDimensions for processing in accordance with their {{privacyPolicyLink}} and {{termsAndConditionsLink}}.',
                {
                    privacyPolicyLink: '<a href="https://clickdimensions.com/about/privacy-policy/" target="_blank">' + gettextCatalog.getString('Privacy Policy') + '</a>',
                    termsAndConditionsLink: '<a href="https://clickdimensions.com/about/terms/" target="_blank">' + gettextCatalog.getString('Terms') + '</a>'
                }),
            privacyPolicyLinkText: gettextCatalog.getString('I agree to the {{shortName}} {{privacyPolicyLink}}',
              {
                  privacyPolicyLink: '<a href ng-click="RegistrationModalCtrl.showPrivacyPolicy()">' + gettextCatalog.getString('Privacy Policy') + '</a>',
                  shortName: $scope.currentSite.shortName
              }),
            termsAndConditionsLinkText: gettextCatalog.getString('I agree to the {{shortName}} {{termsAndConditionsLink}}',
              {
                  termsAndConditionsLink: '<a href="https://static.bmgproductionmusic.nl/documents/' + $scope.currentSite.$pk + '/termsandconditions.pdf" target="_blank">' + gettextCatalog.getString('Terms and conditions') + '</a>',
                  shortName: $scope.currentSite.shortName
              }),
            siteID : $scope.currentSite.$pk,
            imraPrivacyPolicyText: gettextCatalog.getString(' We use Mailchimp as our marketing automation platform. By ticking the box for newsletter subscription, you acknowledge that the information you provide will be transferred to Mailchimp for processing in accordance with their {{privacyPolicyLink}} and {{termsAndConditionsLink}}.',
            {
                privacyPolicyLink: '<a href="https://mailchimp.com/legal/privacy/" target="_blank">' + gettextCatalog.getString('Privacy Policy') + '</a>',
                termsAndConditionsLink: '<a href="https://mailchimp.com/legal/terms/" target="_blank">' + gettextCatalog.getString('Terms') + '</a>'
            }),
            notFromUKLink: '<a href="https://www.bmgproductionmusic.co.uk/uk/global-contacts" target="_blank">' + gettextCatalog.getString('Not from UK?') + '</a>',
            notFromDELink: '<a href="https://www.bmgproductionmusic.co.uk/uk/global-contacts" target="_blank">' + gettextCatalog.getString('Not from Germany/Switzerland/Austria?') + '</a>',
            notFromFRLink: '<a href="https://www.bmgproductionmusic.co.uk/uk/global-contacts" target="_blank">' + gettextCatalog.getString('Not from France?') + '</a>',
            notFromBELink: '<a href="https://www.bmgproductionmusic.co.uk/uk/global-contacts" target="_blank">' + gettextCatalog.getString('Not from Belgium?') + '</a>',
            notFromNLLink: '<a href="https://www.bmgproductionmusic.co.uk/uk/global-contacts" target="_blank">' + gettextCatalog.getString('Not from Netherlands/Luxembourg?') + '</a>'
        };
        $scope.registrationFields = {};
        angular.forEach(registrationFields, function(registrationField) {
            $scope.registrationFields[registrationField.$pk] = { required: registrationField.required };
        });

        $scope.selectedCompany = null;

        $scope.countries = Country.$search();

        $scope.changeCompany = function() {
            $scope.selectedCompany = null;
        };

        var createNewCompany = function(data)
        {
            var newCompany = Company.$build(data);
            newCompany.site = $scope.currentSite;

            // Store company on server
            return newCompany.$save().$asPromise().catch(function(model) {
                infoModal({
                    title: gettextCatalog.getString('We could not complete the registration'),
                    message: gettextCatalog.getString('One or more errors prevented the registration:'),
                    errors: model.$response.data,
                    closeButtonText: gettextCatalog.getString('OK')
                });
                throw new Error('Unable to create company');
            });
        };

        var resolveCompany = function()
        {
            var deferred = $q.defer();

            if ($scope.selectedCompany !== null)
            {
                deferred.resolve($scope.selectedCompany);
            }
            else
            {
                Company.$searchByData(angular.extend($scope.companyData, { expand: 'linkedCountry', site_id: $scope.currentSite.$pk })).$then(function(companies) {
                    // Check if we got matching companies
                    if (companies.length > 0) {
                        companySelectModal({models: companies}).then(function (company) {
                            // Create new company
                            if (company === null) {
                                createNewCompany($scope.companyData).then(function(company) {
                                    $scope.selectedCompany = company;
                                    deferred.resolve(company);
                                }, function(error) {
                                    $scope.selectedCompany = null;
                                    deferred.reject();
                                });
                            }
                            else {
                                $scope.selectedCompany = company;
                                deferred.resolve(company);
                            }
                        }, function () {
                            deferred.reject();
                        });
                    } else {
                        // Create new company
                        createNewCompany($scope.companyData).then(function(company) {
                            $scope.selectedCompany = company;
                            deferred.resolve(company);
                        }, function(error) {
                            $scope.selectedCompany = null;
                            deferred.reject();
                        });
                    }
                }, function() {
                    deferred.reject();
                });
            }

            return deferred.promise;
        };

        // Track the completion as pageview
        Analytics.trackPage('/account/register', 'Register');

        this.cancel = $scope.$dismiss;

        this.showPrivacyPolicy = function() {
          return privacyPolicyModal({
            requireAgreement: false,
            cancelBtnText: gettextCatalog.getString('Close')
          });
        };

        this.saveAccount = function(company) {
            $scope.data.isLoading = true;

            var newAccount = Account.$build($scope.registration);
            newAccount.company = company;
            newAccount.social_data = $scope.socialRegistrationData;
            newAccount.language = languageService.getCurrentLanguage();

            newAccount.$save().$then(function(account) {
                $scope.$close(account);

                if (account.requiresModeration) {
                    infoModal({
                        title: gettextCatalog.getString('Welcome to {{siteName}}!', { siteName: $scope.currentSite.shortName }),
                        message: gettextCatalog.getString('Thank you for registering for access to {{siteName}}, the ultimate music platform for searching music for commercials, radio, television, internet and other audiovisual applications.', { siteName: $scope.currentSite.shortName }) + ' ' +
                        gettextCatalog.getString('Your application will be assessed shortly and you will be emailed when we confirm the account.')
                    });
                } else {
                    infoModal({
                        title: gettextCatalog.getString('Welcome to {{siteName}}!', { siteName: $scope.currentSite.shortName }),
                        message: gettextCatalog.getString('We have send a validation code to your email address.') + ' ' +
                        gettextCatalog.getString('Please click on the code in the mail to confirm your registration.') + ' ' +
                        gettextCatalog.getString('Check your spambox if you haven\'t received the email in a few minutes. Please contact us when this problem persists.')
                    });
                }

            }, function(model) {
                var errors = [];

                if (model.$response.data.message === 'We could not process your registration automatically in your country. Please contact us for more information.') {
                    infoModal({
                        title: gettextCatalog.getString('We could not complete the registration'),
                        message: registrationErrorTextChina.content.replace(/(<([^>]+)>)/ig, ''),
                        closeButtonText: gettextCatalog.getString('OK')
                    });
                    $scope.data.isLoading = false;
                } else {

                    switch (model.$response.status) {
                        case 500:
                            errors.push({message: model.$response.data.message});
                            break;

                        default:
                            errors = model.$response.data.errors;
                            break;
                    }

                    infoModal({
                        title: gettextCatalog.getString('We could not complete the registration'),
                        errors: errors,
                        message: gettextCatalog.getString('One or more errors prevented the registration:'),
                        closeButtonText: gettextCatalog.getString('OK')
                    });
                    $scope.data.isLoading = false;
                }
            });
        };

        this.submit = function () {
            // Show form validation results
            $scope.$broadcast('show-errors-check-validity');

            var self = this;
            if ($scope.registrationForm.$valid) {
                $scope.data.isLoading = true;

                // Resolve company
                resolveCompany().then(function(company) {
                    $scope.data.isLoading = false;
                    // If we do not require agreement of the privacy policy, save the account
                    //if (!$scope.currentSite.requirePpAgreement) {
                    if ($scope.currentSite.requirePpAgreement) {
                      $rootScope.currentSite.latestPrivacyPolicy.$resolve().$asPromise().then(function(results){
                        $scope.registration.ppAgreedTo = results.$pk;
                        $scope.registration.ppAgreedBy = $scope.registration.contactFirstname + ' ' + $scope.registration.contactLastname;
                        $scope.registration.ppDateAgreed = (new Date()).toISOString().split('.')[0] + '+00:00';
                        self.saveAccount(company);
                      });

                    }
                    else {
                      self.saveAccount(company);
                    }
                    /*} else {
                        // Show the privacy policy
                        $scope.data.isLoading = true;
                        privacyPolicyModal({
                            agreedBy: $scope.registration.contactFirstname + ' ' + $scope.registration.contactLastname
                        }).then(function(result) {
                            $scope.registration.ppAgreedTo = result.agreedTo;
                            $scope.registration.ppAgreedBy = result.agreedBy;
                            $scope.registration.ppDateAgreed = (new Date()).toISOString().split('.')[0] + '+00:00';

                            self.saveAccount(company);
                        }, function() {
                            $scope.data.isLoading = false;
                            infoModal({
                                title: gettextCatalog.getString('Unable to continue'),
                                message: gettextCatalog.getString('You cannot register without agreeing to the privacy policy.'),
                                closeButtonText: gettextCatalog.getString('Close')
                            });
                        });
                    }*/
                }).catch(function() {
                    $scope.data.isLoading = false;
                });
            }
            else
            {
                infoModal({
                    title: gettextCatalog.getString('Please check your input'),
                    message: gettextCatalog.getString('One or more fields contained errors. Please correct them and try again.'),
                    closeButtonText: gettextCatalog.getString('OK')
                });
                $scope.data.isLoading = false;
                $scope.data.formHasErrors = true;
            }
        };

        // Hide popup on state change
        var stateChangeBind = $rootScope.$on('$stateChangeSuccess', function() {
            $scope.$dismiss();
        });
        $scope.$on('$destroy', stateChangeBind);

    });
