'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:SiteSwitchCtrl
 * @description
 * # SiteSwitchCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('SiteSwitchCtrl', function ($scope, $rootScope, PubSub, Site, $window) {
      $scope.data = {
        availableSites: Site.$search()
      };

      $scope.switchSite = function (site) {
        var newUrl = (site.useSsl ? 'https' : 'http') + '://' + site.primaryDomain;
        $window.location.href = newUrl;
      };

      PubSub.subscribe('userService.onCurrentUserChange', function () {
        $scope.data.availableSites.$refresh();
      });
    });
