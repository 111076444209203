'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:HeaderCtrl
 * @description
 * # HeaderCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('HeaderBarCtrl', function ($scope, $state, $http, Account, loginModal, registrationModal, OAuth,
                                           OAuthToken, userService, PubSub, currentSite, configuration, $interpolate,
                                           $rootScope, languageService, seoData, Analytics, localStorageService,
                                           registrationBundeswehrModal, $cookies)
    {
        $scope.activeRoute = $state.current.name;

        $scope.data = {
            languageService: languageService,
            currentLanguage: languageService.getCurrentLanguage(),
            availableLanguages: languageService.getAvailableLanguages(),
            availableOtherLanguages: [],
            showAccountDropdown: false,
            homepageUrl: currentSite.homepageUrl
        };
        $scope.seoData = seoData;

        // BE site uses multiple languages
        if (currentSite.$pk === 13) {
            $scope.data.homepageUrl = currentSite.homepageUrl.replace(/\/$/, '') + '/' + $scope.data.currentLanguage + '/';
        }

        function initAvailableOtherLanguages()
        {
            $scope.data.availableOtherLanguages = [];
            angular.forEach($scope.data.availableLanguages, function(value) {
                if (value !== $scope.data.currentLanguage)
                {
                    $scope.data.availableOtherLanguages.push(value);
                }
            });
        }

        $scope.switchLanguage = function(site, lang) {
          // Track the language switch
          Analytics.trackEvent('user', 'switch-language', lang);

          localStorageService.set('language', lang);
          $state.go('root.index', {}, { reload : true });
        };

        PubSub.subscribe('languageService.onLanguageChange', function(data) {
            $scope.data.currentLanguage = data.language;
            initAvailableOtherLanguages();
        });
        PubSub.subscribe('languageService.onAvailableLanguagesChange', function(data) {
            $scope.data.availableLanguages = data.languages;
            initAvailableOtherLanguages();
        });

        initAvailableOtherLanguages();

        $scope.login = function($event) {
			if ($event) {
                $event.preventDefault();
			}
            loginModal()
                .then(function () {
                    return $state.go($state.current.name, $state.current.params, {notify: false});
                });
        };

        $scope.register = function($event) {
            if ($event) {
                $event.preventDefault();
            }
            if ($cookies.bundeswehr) {
                registrationBundeswehrModal();
            } else {
                registrationModal();
            }
        };

        $scope.logout = function($event) {
            if ($event) {
                $event.preventDefault();
            }
            userService.logout();
            $scope.toggleDropdown();
            // Always jump to homepage, to prevent a login popup on secured pages
            return $state.go('root.index', {reload: true});
        };

        $scope.getTemplateUrl = function(template) {
            return 'views/' + $scope.currentSite.template + '/' + template + '.html';
        };

        function initHeader() {
            $scope.data.isAuthenticated = OAuth.isAuthenticated();
            $scope.data.showAccountDropdown = false;

            if (OAuth.isAuthenticated()) {
                userService.getAccount().then(function (account) {
                    if (currentSite.headlineHeaderPrivate !== null) {
                        $scope.headline = $interpolate(currentSite.headlineHeaderPrivate)({firstname: account.contactFirstname});
                    }
                });
            }
            else {
                if (currentSite.headlineHeaderPublic !== null) {
                    $scope.headline = $interpolate(currentSite.headlineHeaderPublic)({ siteTitle:  currentSite.title });
                }
            }
        }

        initHeader();

        PubSub.subscribe('userService.onCurrentUserChange', function() {
            initHeader();
        });

        $rootScope.$on('$stateChangeStart', function(event, toState) {
            var route = toState;
            while (route.parent.abstract !== true)
            {
                route = route.parent;
            }
            $scope.activeRoute = route.name;
        });

        $scope.toggleDropdown = function() {
            $scope.data.showAccountDropdown = !$scope.data.showAccountDropdown;
        };

        $scope.account = function($event) {
            if ($event) {
                $event.preventDefault();
            }

            // Always jump to homepage, to prevent a login popup on secured pages
            return $state.go('root.index.account.settings', {reload: true});
        };
    });
