'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:TrackEditModalCtrl
 * @description
 * # TrackEditModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('TrackEditModalCtrl', function ($scope, $state, options, Track, $filter) {
      $scope.options = options;

      if (angular.isDefined(options.model))
      {
        $scope.model = Track.$new(options.model.$pk).$extend(options.model);
        $scope.model.init();

        // Split composers into 'tags'
        if ($scope.model.composers) {
          var composers = $filter('split')($scope.model.composers, '/\s\/\s/');
          $scope.model.composersValues = [];
          angular.forEach(composers, function(value) {
            $scope.model.composersValues.push({ text: value });
          });
        }

        // Split keywords into 'tags'
        if ($scope.model.keywords) {
          var keywords = $filter('split')($scope.model.keywords.trim(), '/[ ,;]/').filter(function(n) { return n.length; });
          $scope.model.keywordsValues = [];
          angular.forEach(keywords, function(value, key) {
            $scope.model.keywordsValues.push({
                id: key + ':' + value,
                text: value
            });
          });
        }

        this.existingModel = options.model;
      }

      $scope.data = {
        isSaving: false,
        originalActive: options.model ? options.model.active : null
      };

      this.cancel = function() {
        $scope.$dismiss();
      };

      this.finalize = function() {
        if (this.existingModel !== null) {
          this.existingModel.$extend($scope.model);
        }

        // Close modal
        $scope.$close($scope.model);

        // Reload current state to see track changes
        if ($state.current.name === 'root.carrier') {
          $state.go($state.current, {}, {reload: true});
        }
      };

      this.submit = function () {
        $scope.data.isSaving = true;

        var self = this;
        // Update the existing model
        $scope.model.$save(['title', 'text', 'keywords', 'duration', 'composers', 'rating', 'active', 'include_versions']).$then(function() {
          $scope.model.$refresh();
          self.finalize();
        }, function() {
          // Save failed, show error!?
          $scope.data.isSaving = false;
        });
      };
    });
