/**
 * @ngdoc function
 * @name webappMusicdirectorComApp.controller:HomeCtrl
 * @description
 * # HomeCtrl
 * Controller of the webappMusicdirectorComApp
 */
angular.module('musicdirectorApp')
  .controller('HomeCtrl', function ($rootScope, $scope, $state, $interval, $window, currentSite, Category,
                                     languageService, PubSub, seoData, userService) {

      function refreshNewReleases(ignoreLoadingBar)
      {
        if (typeof ignoreLoadingBar === 'undefined') {
          ignoreLoadingBar = false;
        }

        // Exception is made for Latin America
        var carrierLimit = (currentSite.template === 'bmg' ||  currentSite.$pk === 15) ?  25 : 11;
        return homepageCategory.carriers.$refresh({ expand : 'thumbnails', 'per-page' : carrierLimit, sort : 'shuffle', _req: { ignoreLoadingBar: ignoreLoadingBar }});
      }

      var interval = null;
      var homepageCategory = Category.$new(currentSite?.homepageCategoryId);
      refreshNewReleases().$then(function(result) {
          // Load track of first random album
          if (result[0] &&
              result[0].tracks
          ) {
              result[0].tracks.$resolve().$then(function (tracks) {
                  // Start play queue from the first track of the first random album
                  homepageCategory.play({
                      onlyWhenEmpty: true,
                      startPaused: true,
                      startAtTrack: tracks[0].$pk
                  });
              });
          }
      });
      var newsItems = currentSite.news.$search({ language: languageService.getCurrentLanguage(), 'per-page' : 3 });

      // Update seo data
      seoData.revertToDefault();

      $scope.data = {
          'homepageCategory' : homepageCategory,
          'newsItems' : newsItems,
          'homepageContent' : currentSite.homepage.$resolve({ language: languageService.getCurrentLanguage() }),
          'isSystemAdmin': userService.isSystemAdmin()
      };

      $scope.startInterval = function()
      {
          $scope.stopInterval();

          // Refresh new releases every 15 seconds
          interval = $interval(function () {
              refreshNewReleases();
          }, 15000);
      };

      $scope.stopInterval = function()
      {
          if (interval)
          {
              $interval.cancel(interval);
              interval = undefined;
          }
      };

      var useNewReleases = true;

      $scope.getTemplateUrl = function(template) {
        var finalTemplate = template;
        if (currentSite.$pk !== 1 &&
          currentSite.$pk !== 13 &&
          currentSite.$pk !== 18 &&
          currentSite.$pk !== 19 &&
          currentSite.$pk !== 21 &&
          currentSite.template === 'bmg' &&
          template === 'index'
        ) {
          useNewReleases = false;
          finalTemplate = 'all-albums';
          return 'views/' + currentSite.template + '/' + finalTemplate + '.html';
        }
        return 'views/' + finalTemplate + '.html';
      };

      // Start and stop the reload interval when our controller is visible
      $scope.$on('$destroy', function() {
          $scope.stopInterval();
      });
      $scope.$on('$viewContentLoaded', function() {
          if (useNewReleases) {
            $scope.startInterval();
            $scope.data.currentLanguage = languageService.getCurrentLanguage();
          }
      });

      PubSub.subscribe('homeCtrl.refreshNewReleases', function() {
        if (useNewReleases) {
          refreshNewReleases();
        }
      });
  });
