'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.appAuthService
 * @description
 * # appAuthService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('appAuthService', function ($q, userService, loginModal) {
    function requireLogin(_options)
    {
        var options = angular.extend({ modal : {} }, _options);
        var deferred = $q.defer();
        var user = userService.getCurrentUser();

        // We require a valid user object
        if (user === null)
        {
            loginModal(options.modal).then(function(user) {
                deferred.resolve(user);
            }, function() {
                deferred.reject();
            });
        }
        else
        {
            deferred.resolve(user);
        }

        return deferred.promise;
    }

    return {
        requireLogin : requireLogin
    };
  });
