'use strict';

/**
 * @ngdoc filter
 * @name musicdirectorApp.filter:split
 * @function
 * @description
 * # split
 * Filter in the musicdirectorApp.
 */
angular.module('musicdirectorApp.filters')
    .filter('split', function() {
        return function(input, splitChar, splitIndex) {
            if (splitChar[0] === '/' &&
                splitChar[splitChar.length - 1] === '/')
            {
                splitChar = new RegExp(splitChar.substring(1, splitChar.length - 1));
            }

            // do some bounds checking here to ensure it has that index
            var parts = input.split(splitChar);
            if (splitIndex &&
                splitIndex < parts.length)
            {
                return parts[splitIndex];
            }
            else
            {
                angular.forEach(parts, function(part, index) {
                    parts[index] = part.replace(/^\s+|\s+$/gm, '');
                });
                return parts;
            }
        };
    });