'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.PrivacyPolicy
 * @description
 * # companyType
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('PrivacyPolicy', function (restmod, languageService) {
        return restmod.model().mix({
            $config: {
                primaryKey: 'ID',
                name: 'privacyPolicy',
                plural: 'privacyPolicies'
            },
            $hooks: {
                'before-fetch': function (_request) {
                    _request.params = {
                        language: languageService.getCurrentLanguage()
                    };
                }
            }
        });
    });