'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.languageService
 * @description
 * # languageService
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .factory('languageService', function ($state, gettextCatalog, configuration, PubSub, localStorageService, Analytics) {
        var availableLanguages = [];

        function switchLanguage(site, lang) {
            // Track the language switch
            Analytics.trackEvent('user', 'switch-language', lang);

            localStorageService.set('language', lang);
            $state.go('root.index', {}, { reload : true });
        }

        function setCurrentLanguage(site, lang) {
            localStorageService.set('language', lang);
            gettextCatalog.setCurrentLanguage(lang);

            // Lazy load translations
            if (lang !== 'en') {
                gettextCatalog.loadRemote('/languages/' + lang + '.json').then(function() {
                    // Notify application of language change
                    PubSub.publish('languageService.onLanguageChange', { language: lang });
                });
            } else {
                // Notify application of language change
                PubSub.publish('languageService.onLanguageChange', { language: lang });
            }

        }

        function getCurrentLanguage() {
            return gettextCatalog.getCurrentLanguage();
        }

        function setAvailableLanguages(langs) {
            availableLanguages = langs;

            PubSub.publish('languageService.onAvailableLanguagesChange', { languages: availableLanguages });
        }

        function getAvailableLanguages() {
            return availableLanguages;
        }

        return {
            switchLanguage: switchLanguage,
            setCurrentLanguage : setCurrentLanguage,
            getCurrentLanguage : getCurrentLanguage,
            setAvailableLanguages: setAvailableLanguages,
            getAvailableLanguages: getAvailableLanguages
        };
    });
