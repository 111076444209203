'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.searchHistory
 * @description
 * # searchHistory
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('searchHistory', function ($state, $location, growl, gettextCatalog) {
      var growlInstance = null;

      return {
        showGrowl: function (options) {
          var url = $state.href('root.search-results', options.params);

          growlInstance = growl.info('<a href="' + url + '"><i class="fa fa-undo"></i></a> <a href="' + url + '">' + gettextCatalog.getString('Return to search results') + '</a>', {
            ttl: -1,
            disableIcons: true,
            clickToClose: false
          });
        },
        hideGrowl: function () {
          if (growlInstance !== null && growlInstance !== undefined) {
            growlInstance.destroy();
            growlInstance = null;
          }
        }
      };
    });
