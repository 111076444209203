'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:PrsMusicLink
 * @description
 * # olark
 */
angular.module('musicdirectorApp')
  .directive('prsMusicLink', function ($window) {

      var titleToUrl = function(title) {
          return title.replace(/\s*\(.+\)\s*$/g, '');
      };

      var getComposerLastName = function(track) {
          var matches;
          if ((matches = track.composers.match(/^([^,]+),/)) !== null) {
              return matches[1];
          }
          return '';
      };

      return {
      template: '<button type="button" class="btn btn-transparent" uib-tooltip="{{ ::\'PRS Licence Manager\' | translate }}"><i class="icon fa fa-cart-plus"></i></button>',
      restrict: 'E',
      replace: true,

      link: function(scope, element) {

        function openPrsWindow() {
            var trackTitle = titleToUrl(scope.track.title);
            var composerLastName = getComposerLastName(scope.track);
            var url = 'https://musicshop.prsformusic.com/LMGR/inc/landing.aspx?' +
                'newapp=True&' +
                'thirdPartyApp=True&' +
                'catalogueNumber1=&' +
                'composerLastName1=' + composerLastName + '&' +
                'workTitle1=' + trackTitle + '&' +
                'musicLibraryIPINumber1=';
            $window.open(url);
        }

        element.on('click', openPrsWindow);
      }
    };
  });
