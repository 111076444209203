'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:SaveSearchModalCtrl
 * @description
 * # SaveSearchModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('SaveSearchModalCtrl', function ($scope, options, SearchQuery) {
      $scope.options = options;

      $scope.formData = {
        name: null
      };

      this.cancel = function() {
        $scope.$dismiss();
      };

      this.submit = function () {
        if ($scope.saveSearchForm.$valid) {
          SearchQuery.$create({
            name: $scope.formData.name,
            searchurl: $scope.options.urlParamsString
          }).$then(function () {
            $scope.$close();
          });
        }
      };
    });