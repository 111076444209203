'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:CompanySelectModalCtrl
 * @description
 * # CompanySelectModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('ForgotPasswordModalCtrl', function ($scope, modalOptions, infoModal, Account, gettextCatalog,
                                                     languageService, Analytics) {
        $scope.formData = {
            email: null,
            username: null,
            hasMultipleUsers: false
        };

        // Track the modal opening as pageview
        Analytics.trackPage('/account/forgot-password', 'Forgot password');

        this.cancel = function() {
            // Track the completion as pageview
            Analytics.trackEvent('user', 'forgot-password-dismissed');

            $scope.$dismiss();
        };

        this.submit = function () {
            if ($scope.forgotPasswordForm.$valid)
            {
                var data = {
                    email: $scope.formData.email,
                    // @fixme this should not be hardcoded
                    site_id : $scope.currentSite?.$pk || 2,
                    language: languageService.getCurrentLanguage()
                };
                if ($scope.hasMultipleUsers) {
                    data.username = $scope.formData.username;
                }

                return Account.single('/account').requestPasswordReset(data).then(function(result) {
                    if (result.status === 200) {
                        // Track the completion as pageview
                        Analytics.trackPage('/user/forgot-password/success', 'Requested new password');

                        // $scope.$close();
                        return infoModal({
                            title: gettextCatalog.getString('Password reset'),
                            message: gettextCatalog.getString('We have mailed you the details for changing your account password. Please also check your spam folder.')
                        }).then(function() {
                            $scope.$close();
                        });
                    }
                }, function(result) {
                    // Format errors for use in info modal
                    var errors = [];
                    angular.forEach(result.data.errors, function(value, field) {
                        angular.forEach(value, function(message) {
                            errors.push({ message: message, field: field });
                        });
                    });

                    if (result.data.type === 1) {
                        $scope.hasMultipleUsers = true;
                    }

                    return infoModal({
                        title: gettextCatalog.getString('Check your input'),
                        message: result.data.message,
                        errors: errors
                    });
                });
            }
        };
    });
