'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PrivacyPolicyModalCtrl
 * @description
 * # CopyrightModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('PrivacyPolicyModalCtrl', function ($scope, modalOptions, privacyPolicy) {
    $scope.modalOptions = this.modalOptions = modalOptions;
    $scope.privacyPolicy = privacyPolicy;

    this.cancel = $scope.$dismiss;
    this.agree = function() {
        $scope.$close({ agreedTo: privacyPolicy.$pk, agreedBy: $scope.modalOptions.agreedBy });
    };
  });
