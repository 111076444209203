'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:EdlToolModalCtrl
 * @description
 * # EdlToolModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('EdlToolModalCtrl', function ($scope, configuration) {
        $scope.apiUrl = configuration.apiUrlPrefix + '/edl-tool?x-md-siteid=' + $scope.currentSite.$pk;
        this.cancel = $scope.$dismiss;
    });
