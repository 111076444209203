'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistexportmodalCtrl
 * @description
 * # PlaylistexportmodalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('PlaylistExportModalCtrl', function ($scope) {

        $scope.format = 'XML';

        this.cancel = $scope.$dismiss;

        this.submit = function (format) {
            $scope.$close({ format: format });
        };
  });
