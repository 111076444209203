'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.playlistImportModal
 * @description
 * # playlistImportModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('playlistImportModal', function ($uibModal) {
      return function(playlistID) {
        var instance = $uibModal.open({
          animation: false,
          backdrop: 'static',
          templateUrl: 'views/playlist-import-modal.html',
          controller: 'PlaylistImportModalCtrl',
          controllerAs: 'PlaylistImportModalCtrl',
          windowClass: 'playlist-import-modal',
          resolve: {
            playlistID : function() {
              return playlistID;
            }
          }
        });

        return instance.result;
      };
    });
