'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Page', function ($window, restmod, slug) {
        return restmod.model('/pages').mix({
            site: { belongsTo: 'Site' },
            $hooks: {
                'after-fetch': function() {
                    if (this.type === 'references') {
                        // Set deeplink for all references
                        angular.forEach(this.references, function(reference) {
                            reference.$deeplink = reference.ID + '/' + (reference.title ? slug(reference.title) : 'no-title');
                        });
                    }
                }
            }
        });
    });
