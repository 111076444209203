'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:AccountSettingsCtrl
 * @description
 * # AccountSettingsCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('AccountSettingsCtrl', function ($rootScope, $scope, userService, infoModal,
                                                 gettextCatalog, $state, confirmModal, localStorageService) {
        $scope.data = {
            user: null,
            company: null,
            successMessageShow: false,
            showJobTitle: [1, 12, 18, 19].indexOf($scope.currentSite.$pk) !== -1,
        };

      // collect job titles
      $scope.jobTitles = [];
      $rootScope.currentSite.jobTitles.$resolve().$asPromise().then(function (jobTitles) {
        angular.forEach(jobTitles, function(value) {
          $scope.jobTitles.push({ ID: value.ID, title: value.title });
        });
      });

      userService.getAccount().then(function(account) {
            $scope.data.user = account;
            $scope.data.company = account.linkedCompany;
        });

        $scope.submit = function() {
            $scope.data.user.new_password = $scope.data.user.password;
            if($scope.data.user.settings.downloadPreference !== null){
                localStorageService.set('downloadFormat',$scope.data.user.settings.downloadPreference);
            }
            $scope.data.user.$save(['contactFirstname', 'contactLastname', 'new_password', 'settings', 'jobTitle']).$then(function() {
                infoModal({
                    title: gettextCatalog.getString('Succes'),
                    html: '<p>' + gettextCatalog.getString('Your account has been succesfully updated.') + '</p>'
                });
            }, function(result){
                var message = '';
                if(result.$response && result.$response.data){
                    jQuery.each(result.$response.data, function(index, data){
                        message = message + data.message + '<br/>';
                    });
                    return infoModal({
                        title: gettextCatalog.getString('Something went wrong'),
                        html: '<p>' + message + '</p>'
                    });
                }
                infoModal({
                    title: gettextCatalog.getString('Something went wrong'),
                    html: '<p>' + gettextCatalog.getString('For unknown reasons we were not able to update ' +
                        'your account. Please try again') + '</p>'
                });
            });
        };

        $scope.cancelAccount = function() {
            confirmModal({
                title: gettextCatalog.getString('Remove Account'),
                message: gettextCatalog.getString('You are about to remove your account. ' +
                    'This can not be undone! ' +
                    'Your account will be permanently removed after 30 days.'),
                yesText: gettextCatalog.getString('Confirm'),
                noText: gettextCatalog.getString('Cancel'),
                placeholder: gettextCatalog.getString('TYPE "I CONFIRM"'),
                compareValue: gettextCatalog.getString('I CONFIRM'),
                inputValue: null,
                expectInput: true
            }).then(function() {
                $scope.data.user.cancel($scope.data.user)
                    .then(function() {
                            infoModal({
                                title: gettextCatalog.getString('Succesfully cancelled account'),
                                html: '<p>' + gettextCatalog.getString('You will now be send to the home page.') + '</p>'
                            }).then(function() {
                                userService.logout();
                                // Redirect to homepage
                                $state.go('root.index');
                            });
                        }, function (result) {
                            infoModal({
                                title: gettextCatalog.getString('Something went wrong'),
                                html: '<p>' + gettextCatalog.getString(result.data.message) + '</p>'
                            });
                        }
                    );
            });
        };

        $scope.logoutAllSessions = function (){
            $scope.data.user.logoutAllSessions($scope.data.user).then(function(){
                userService.logout();
                $state.go('root.index');
            });
        };
    }
);
