'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistCloneModalCtrl
 * @description
 * # PlaylistCloneModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistCloneModalCtrl', function ($scope, Playlist, options) {
        // Use an existing model for editing or create a new one
        $scope.options = options;

        // Create a new model object
        $scope.model = Playlist.$build({ title : options.model.title, site : options.model.site });

        this.cancel = function() {
            $scope.$dismiss();
        };

        this.submit = function () {
            // Update the existing model
            return $scope.options.model.clone($scope.model.title).then(function(result) {
                $scope.$close(Playlist.$buildRaw(result.data));
            }, function() {
                // Clone failed, show error!?
            });
        };
    });
