'use strict';

/**
 * @ngdoc function
 * @name webappMusicdirectorComApp.controller:CarrierCtrl
 * @description
 * # CarrierCtrl
 * Controller of the webappMusicdirectorComApp
 */
angular.module('musicdirectorApp')
    .controller('CarrierCtrl', function ($state, $scope, $stateParams, carrier, track, localStorageService,
                                         userService, seoData, gettextCatalog, RemotePlayQueue, $rootScope) {
        $scope.data = {
            carrier : carrier,
            sideBarVisible : localStorageService.get('sideBarVisible') && userService.isAuthenticated(),
            isSystemAdmin: userService.isSystemAdmin(),
            track: null
        };

        // Update seo data
        seoData.pageTitle = carrier.title + ' ' + gettextCatalog.getString('from') + ' ' + carrier.label.title;
        seoData.metaDescription = carrier.text;
        seoData.pageImage = carrier.thumbnails ? carrier.thumbnails['250x250'] + $rootScope.addCacheBuster(carrier.timeChange) : null;
        $scope.data.track = track ? [track] : null;

        $scope.toggleSidebar = function()
        {
            $scope.data.sideBarVisible = !$scope.data.sideBarVisible;

            // Save setting in local storage
            localStorageService.set('sideBarVisible', $scope.data.sideBarVisible);
        };

        $scope.searchByKeyword = function(keyword) {
            // replace special chars in the search function
            keyword = keyword.replace(/[?!.\-:]/g, '');

            $state.go('root.search-results', { scope: 'tracks', query: keyword});
        };

        if ($scope.userCanPlay) {
          // Load the specified initial track, if available
          if (track !== null) {
            var foundTrack = null;
            var trackIndex = carrier.tracks.$indexOf(function (_obj) {
              return _obj.$pk === track.$pk;
            });
            if (trackIndex !== -1) {
              foundTrack = carrier.tracks[trackIndex];
            } else {
              carrier.$refresh({expand: 'categories,label,thumbnails,tracks,versionTracks' +
                  ($rootScope.currentSite.$pk === 21 ? ',track.artistsNames' : '')}
                ).$then(function(carrier){
                    trackIndex = carrier.versionTracks.$indexOf(function (_obj) {
                        return _obj.$pk === track.$pk;
                    });
                    if (trackIndex !== -1) {
                        foundTrack = carrier.versionTracks[trackIndex];
                    }
                    if (foundTrack !== null) {
                        // load de remote play queue in
                        new RemotePlayQueue(carrier).load().then(function (queue) {
                          foundTrack.play(queue, {startPaused: true, onlyWhenEmpty: true});
                        });
                    }
              });
            }
            if (foundTrack !== null) {
              // load de remote play queue in
              new RemotePlayQueue(carrier).load().then(function (queue) {
                foundTrack.play(queue, {startPaused: true, onlyWhenEmpty: true});
              });
            }
          }
          // Load this carrier in the player, but only if the player is empty (i.e. after a reload)
          else {
            carrier.play({startPaused: true, onlyWhenEmpty: true});
          }
        }
    });
