'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('MainCtrl', function ($scope, $rootScope, $state, $window, $location, languageService, playService,
                                      Site, PubSub, seoData)
    {
        $scope.data = {
            languageService: languageService,
            currentLanguage: languageService.getCurrentLanguage(),
            availableLanguages: languageService.getAvailableLanguages(),
            availableOtherLanguages: []
        };
        $scope.seoData = seoData;

        function initAvailableOtherLanguages()
        {
            $scope.data.availableOtherLanguages = [];
            angular.forEach($scope.data.availableLanguages, function(value) {
                if (value !== $scope.data.currentLanguage)
                {
                    $scope.data.availableOtherLanguages.push(value);
                }
            });
        }

        PubSub.subscribe('languageService.onLanguageChange', function(data) {
            $scope.data.currentLanguage = data.language;
            initAvailableOtherLanguages();
        });
        PubSub.subscribe('languageService.onAvailableLanguagesChange', function(data) {
            $scope.data.availableLanguages = data.languages;
            initAvailableOtherLanguages();
        });

        initAvailableOtherLanguages();

        // Watch keypresses for controlling the player
        angular.element($window).on('keyup', function(event) {
            if (event.target &&
                (
                    event.target.tagName === 'INPUT' ||
                    event.target.tagName === 'TEXTAREA' ||
                    event.target.tagName === 'SELECT')
            ) {
                return;
            }

            if (event.keyCode === 32) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }
        });
        angular.element($window).on('keydown', function(event) {
            // Voorkom dat de event handler getriggered wordt als we in een invoerveld zitten
            if (event.target &&
                (
                    event.target.tagName === 'INPUT' ||
                    event.target.tagName === 'TEXTAREA' ||
                    event.target.tagName === 'SELECT')
                )
            {
                return;
            }

            var handled = false;

            // Handle CTRL-C keypress; trigger a download for the snippet
            if (event.keyCode === 67 &&
                event.ctrlKey)
            {
                PubSub.publish('player.downloadSelection');
            }
            // Spacebar
            else if (event.keyCode === 32)
            {
                playService.togglePlayPause();
                handled = true;
            }
            // Home = beginning
            else if (event.keyCode === 36)
            {
                playService.seekToPercentage(0);
                handled = true;
            }
            // End = beginning
            else if (event.keyCode === 35)
            {
                playService.seekToPercentage(100);
                handled = true;
            }
            // Left arrow
            else if (event.keyCode === 37)
            {
                playService.seekPercentage(-10);
                handled = true;
            }
            // Right arrow
            else if (event.keyCode === 39)
            {
                playService.seekPercentage(10);
                handled = true;
            }
            // Up arrow
            else if (event.keyCode === 38)
            {
                playService.prev({ scrollToTrack : true, scrollDirection: 'up' });
                handled = true;
            }
            // Down arrow
            else if (event.keyCode === 40)
            {
                playService.next({ scrollToTrack : true, scrollDirection: 'down' });
                handled = true;
            }

            if (handled)
            {
                event.preventDefault();
                event.stopImmediatePropagation();
                return false;
            }
        });
    });
