'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:SearchBarCtrl
 * @description
 * # SearchBarCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('SearchBarCtrl', function ($scope, $rootScope, $state, loadSearchModal, localStorageService,
                                         appAuthService, gettext, gettextCatalog, uploadAudioModal, $sce) {

    this.setPlaceholder = function() {
        $scope.data.searchPlaceholder = gettextCatalog.getString('Search keywords, mood, label, etc.');
        if ($scope.currentSite.hasMusicMatcher) {
            $scope.data.searchPlaceholder = gettextCatalog.getString('Search keywords, mood, label, URL, etc.');
        }
    };
    $scope.data = {
        searchQuery: '',
        searchIn: {},
        searchOptionsOpen: false,
        referenceId: '',
        resultId: '',
        startTime: null,
        endTime: null,
        searchPlaceholder: null,
        preventClosing: false
    };
    this.setPlaceholder();
    $rootScope.$on("gettextLanguageChanged", this.setPlaceholder);

    var firstSearchQuery = localStorageService.get('search.firstQuery');
    if (firstSearchQuery === null)
    {
        firstSearchQuery = true;
    }

    $scope.toggleDropdown = function()
    {
        $scope.data.searchOptionsOpen = !$scope.data.searchOptionsOpen;
    };

    $scope.openDropdown = function()
    {
        if (firstSearchQuery)
        {
            $scope.data.searchOptionsOpen = true;
        }

        return true;
    };

    $scope.closeDropdown = function()
    {
        if(!$scope.data.preventClosing)
        {
            $scope.data.searchOptionsOpen = false;
        }

        return true;
    };

    $scope.preventClosing = function()
    {
        $scope.data.preventClosing = true;
    };

    $scope.allowClosing = function()
    {
        $scope.data.preventClosing = false;
    };

    $scope.focusSearchbar = function()
    {
        angular.element('#searchbar-input-field').trigger('focus');
    };

    $scope.loadSearch = function()
    {
        return appAuthService.requireLogin({modal: {message: gettextCatalog.getString('Please login to load searches.')}}).then(function () {
            loadSearchModal();
        });
    };

    $scope.clearFields = function()
    {
        $scope.data.searchIn = {};
    };

    $scope.trustSrc = function(src) {
        return $sce.trustAsResourceUrl(src);
    };

    $scope.submit = function()
    {
        var regExpSoundcloud = /^.*?soundcloud\.com\/([\w\-.]+)\/([\w\-.]+)/;
        var matchSoundcloud = $scope.data.searchQuery.match(regExpSoundcloud);
        var regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var matchYoutube = $scope.data.searchQuery.match(regExpYoutube);

        // It is a youtube link, show a message that it may take longer
        if (matchYoutube &&
            matchYoutube[2].length === 11 &&
            $scope.currentSite.hasMusicMatcher)
        {
            appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to use the MusicMatcher') }}).then(function() {
                $rootScope.$emit('resetYoutubePlayer');
                $rootScope.uploadAudioModalInstance = uploadAudioModal({
                    mode: 'loadingYoutubeLink',
                    youtubeId: matchYoutube[2],
                    url: $scope.data.searchQuery
                });

                // remove link from searchbar
                $scope.data.searchQuery = '';
            });
        }
        else if (matchSoundcloud &&
            matchSoundcloud[2] &&
            $scope.currentSite.hasMusicMatcher
        ) {
            appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to use the MusicMatcher') }}).then(function() {
                $rootScope.$emit('resetSoundcloudPlayer');
                $rootScope.uploadAudioModalInstance = uploadAudioModal({
                    mode: 'loadingSoundcloudLink',
                    soundcloudLink: $scope.data.searchQuery,
                    soundcloudLinkIframe: $sce.trustAsResourceUrl('https://w.soundcloud.com/player/?url=' + encodeURI($scope.data.searchQuery) + '&auto_play=false&show_artwork=true&color=0066cc&sharing=false&download=false&show_comments=false&hide_related=true&callback=true'),
                    url: $scope.data.searchQuery
                });
                $rootScope.uploadAudioModalInstance.opened.then(
                    function () {
                        setTimeout(function () {
                            $rootScope.soundcloudPlayer = SC.Widget('soundcloudPlayer');
                            jQuery(jQuery('.rz-bubble').get(10)).addClass('no-opacity');
                            jQuery(jQuery('.rz-bar-wrapper .rz-selection').get(2)).addClass('no-background');
                        }, 200);
                        setTimeout(function () {
                            document.getElementsByClassName('player-row')[0].style.opacity = 1;
                            jQuery(jQuery('.rz-bubble').get(10)).addClass('no-opacity');
                        }, 500);
                    });
            });
        } else {
            $scope.data.referenceId = null;
            $scope.submitQuery();
        }
    };
      $scope.submitQuery = function(){
        var fields = [];
        angular.forEach($scope.data.searchIn, function(value, key) {
            if (value)
            {
                fields.push(key);
            }
        });

        // Disable opening of search filters and close the filters
        localStorageService.set('search.firstQuery', false);
        firstSearchQuery = false;
        $scope.data.searchOptionsOpen = false;

        // Clear settings
        $scope.data.searchIn = {};

        var newQuery = $scope.data.searchQuery;
        $scope.data.searchQuery = '';
        $state.go('root.search-results', {
            scope: 'tracks',
            query: newQuery,
            subQuery: '',
            genre: null,
            catalogue: null,
            duration: null,
            releaseyear: null,
            bpm: null,
            sort: 'best-match',
            page: '1',
            fields: fields.join(','),
            referenceId: $scope.data.referenceId,
            resultId: $scope.data.resultId,
            startTime: $scope.data.startTime,
            endTime: $scope.data.endTime,
            logSearch: true
        }, { inherit: false, reload: 'root.search-results' });
      };

      $scope.keyUp = function() {
          if($scope.data.searchQuery.length > 0) {
            $scope.data.searchOptionsOpen = true;
          }
      };
  });
