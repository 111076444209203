'use strict';

angular.module('musicdirectorApp')
    .directive('customScroll', function() {
        return {
            restrict: 'A',
            link: function (scope, element) {
                var disabled = false;

                function handle(delta) {
                    disabled = true;
                    var time = 250;
                    var itemHeight = element[0].children[1].children[0].offsetHeight - 1;
                    var items = 3;
                    var distance = itemHeight * items;
                    element.stop().animate({
                        scrollTop: (Math.round(element.scrollTop() / distance) * distance) - (distance * delta)
                    }, time );
                    setTimeout(function(){
                        disabled = false;
                    },time);
                }

                function wheel(event) {
                    var delta = 0;
                    if (event.originalEvent.deltaY) {
                        delta = Math.sign(-event.originalEvent.deltaY);
                    }

                    if(!disabled){
                        handle(delta);
                    }
                    if (event.preventDefault) {
                        event.preventDefault();
                    }
                    event.returnValue = false;
                }

                element.on('wheel', wheel);
            }
        };
    });
