'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:trackDetails
 * @description
 * # trackDetails
 */
angular.module('musicdirectorApp')
    .directive('carrierCover', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                carrier: '=',
                size: '=?',
            },
            templateUrl: 'views/carrier-cover.html',
            controller: function($scope, $rootScope){
              // check if it was defined.  If not - set a default
              var size = angular.isDefined($scope.size) ? $scope.size + 'x' + $scope.size : '175x175';
              $scope.thumbnail = $scope.carrier.thumbnails[size] + $rootScope.addCacheBuster($scope.carrier.timeChange) || '';
            }
        };
    });
