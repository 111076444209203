'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:RecentlyPlayedCtrl
 * @description
 * # RecentlyPlayedCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('RecentlyPlayedCtrl', function ($scope, $state, $http, RecentlyPlayed, tracks, userService,
                                                localStorageService, RemotePlayQueue, downloads, RecentlyDownloaded) {
        var playQueue = new RemotePlayQueue(RecentlyPlayed.single('/recentlyplayed'), {site_id: $scope.currentSite.$pk});
        playQueue.load();
        var now = new Date();
        var minDate = new Date();

        $scope.downloadFilter = {
            dt: null,
            options: {
                datepickerMode: 'month',
                minMode: 'month',
                maxDate: now,
                minDate: minDate.setFullYear(now.getFullYear() - 10),
                isOpen: false,
                loadingResults: true,
            }
        };

        $scope.data = {
            playScope: playQueue,
            tracks: tracks,
            sideBarVisible: localStorageService.get('sideBarVisible') && userService.isAuthenticated() && tracks.length,
            playedTab: localStorageService.get('playedTab') === null ? true : localStorageService.get('playedTab'),
            downloadTab: localStorageService.get('downloadTab') === null ? false : localStorageService.get('downloadTab'),
            downloads: downloads,
            pageLimit: 10,
            page: 1,
            loadingResults: false,
            loadMoreDisabled: false,
        };

        $scope.applyDate = function () {
            if ($scope.downloadFilter.dt !== null) {
                $scope.downloadFilter.loadingResults = true;
                $scope.data.downloads = RecentlyDownloaded.$search({
                    site_id: $scope.currentSite.$pk,
                    expand: 'track,carrier,label',
                    month: $scope.downloadFilter.dt.getMonth() + 1, // months are 0 based so jan = 0
                    year: $scope.downloadFilter.dt.getFullYear(),
                    page: $scope.data.page,
                    perPage: $scope.data.pageLimit,
                }).$then(function(){
                    $scope.downloadFilter.loadingResults = false;
                });
            } else {
                $scope.resetDate();
            }
        };

        $scope.resetDate = function () {
            $scope.downloadFilter.loadingResults = true;
            $scope.data.downloads = RecentlyDownloaded.$search({
                site_id: $scope.currentSite.$pk,
                expand: 'track,carrier,label',
                page: $scope.data.page,
                perPage: $scope.data.pageLimit,
            }).$then(function(){
                $scope.downloadFilter.loadingResults = false;
            });
        };

        $scope.toggleSidebar = function () {
            $scope.data.sideBarVisible = !$scope.data.sideBarVisible;

            // Save setting in local storage
            localStorageService.set('sideBarVisible', $scope.data.sideBarVisible);
        };

        $scope.clearList = function () {
            $http.post(RecentlyPlayed.$new('clear').$url() + '?site_id=' + $scope.currentSite.$pk).then(function () {
                $scope.data.sideBarVisible = false;
                $scope.data.tracks.$refresh();
            });
        };

        // Load play queue when page is opened from link
        if (tracks.length > 0) {
            tracks[0].play($scope.data.playScope, {startPaused: true, onlyWhenEmpty: true});
        }

        $scope.loadMoreTracks = function() {
            if (!$scope.data.loadingResults && !$scope.data.loadMoreDisabled && $scope.data.downloadTab) {
                $scope.data.loadingResults = true;
                var params = {
                    site_id: $scope.currentSite.$pk,
                    expand: 'track,carrier,label',
                    perPage: $scope.data.pageLimit,
                    page: $scope.data.downloads.$metadata.currentPage + 1
                };
                if($scope.downloadFilter.dt){
                    Object.assign(params, {
                        month: $scope.downloadFilter.dt.getMonth() + 1,
                        year: $scope.downloadFilter.dt.getFullYear(),
                    });
                }
                $scope.data.downloads.$fetch(params).$then(function () {
                    $scope.data.loadMoreDisabled = this.$metadata.currentPage >= this.$metadata.pageCount;
                    $scope.data.loadingResults = false;
                });
            }
        };

        function getLastTenYears() {
            var thisYear = new Date();
            var years = {};
            years[thisYear.getFullYear()] = thisYear.getFullYear()
            for (var i = 10; i > 0; i--) {
                thisYear.setYear(thisYear.getFullYear() - 1);
                years[thisYear.getFullYear()] = thisYear.getFullYear();
            }
            return years;
        }

        $scope.saveTabToLocalStorage = function(tab){
            $scope.data.playedTab = !tab;
            $scope.data.downloadTab = tab;
            localStorageService.set('playedTab', !tab);
            localStorageService.set('downloadTab', tab);
        };

        $scope.open = function(event){
            $scope.downloadFilter.options.isOpen = true;
        };
    });
