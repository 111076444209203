'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistsBarCtrl
 * @description
 * # PlaylistsBarCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistsBarCtrl', function ($scope, $rootScope, $state, $q, Playlist, Track, playlistEditModal,
                                              playlistCloneModal, playlistImportModal, debounce, localStorageService,
                                              PubSub, gettextCatalog, appAuthService, loadSearchModal)
    {
        $scope.data = {
            loadMoreDisabled: true,
            isLoading: true,
            selectedPlaylist: null,
            dropdownState: false,
            playlistFilter: '',
            lastPlaylist: null,
            lastPlaylistId: localStorageService.get('lastPlaylistSelected'),
            sort: null
        };

        function handlePlaylistSearch(playlists)
        {
            $scope.data.loadMoreDisabled = playlists.$page >= playlists.$pageCount;
            $scope.data.isLoading = false;
            $scope.data.playlists = playlists;
        }

        $scope.data.playlists = {};
        var searchSettings = { 'per-page' : 20, site_id : $rootScope.currentSite.$pk };

        // set the playlist order from last time if it still remains
        if(localStorage.getItem('sortPlaylist')){
            $scope.data.sort = localStorage.getItem('sortPlaylist');
        }

        if ($scope.data.sort) {
            searchSettings.sort = $scope.data.sort;
        }
        var playlistsPromise = Playlist.$search(searchSettings).$then(handlePlaylistSearch).$asPromise();

        // Promise to load users favorite playlist
        var favoritePromise = Playlist.$new('favorite').$fetch({ site_id : $rootScope.currentSite.$pk }).$then(function(playlist) {
            // Overwrite $pk with ID parameter
            playlist.$pk = playlist.ID;
        }, function() {
            // If no playlist found, return null
            return null;
        }).$asPromise();

        // Load previous playlist and fall back to favorite
        var initialPlaylistPromise = null;
        if ($scope.data.lastPlaylistId) {
            initialPlaylistPromise = Playlist.$find($scope.data.lastPlaylistId).$then(function (playlist) {
                return playlist;
            }, function () {
                return favoritePromise;
            }).$asPromise();
        } else {
            initialPlaylistPromise = favoritePromise;
        }

        $q.all([playlistsPromise, initialPlaylistPromise]).then(function(data) {
            if (data[1] !== null &&
                data[1].$status !== 'error')
            {
                $scope.selectPlaylist(data[1]);
            }
            else
            {
                $scope.selectPlaylist(null);
            }
        });

        var playlistFilterFn = debounce(200, function() {
            $scope.data.isLoading = true;
            $scope.data.loadMoreDisabled = true;

            var searchSettings = { title: $scope.data.playlistFilter, 'per-page' : 20, site_id : $rootScope.currentSite.$pk };
            if($scope.data.sort) {
                searchSettings.sort = $scope.data.sort;
            }

            $scope.data.playlists = Playlist.$search(searchSettings).$then(handlePlaylistSearch);
        });

        $scope.filterPlaylists = function($event) {
            // Filter out non-printable eventcodes
            if (($event.keyCode > 47 && $event.keyCode < 58) ||
                ($event.keyCode === 32 || $event.keyCode === 13) ||
                ($event.keyCode > 64 && $event.keyCode < 91) ||
                ($event.keyCode > 95 && $event.keyCode < 112) ||
                ($event.keyCode > 185 && $event.keyCode < 193) ||
                ($event.keyCode > 218 && $event.keyCode < 223) ||
                ($event.keyCode === 8 || $event.keyCode === 46)) // Backspace or Delete
            {
                playlistFilterFn.cancel();
                playlistFilterFn();
            }
        };

        this.selectPlaylist = $scope.selectPlaylist = function(playlist)
        {
            if (playlist !== null) {
                // Check if playlist is already selected
                if ($scope.data.selectedPlaylist &&
                    playlist.$pk === $scope.data.selectedPlaylist.$pk)
                {
                    return;
                }

                // Store last selection in local storage
                localStorageService.set('lastPlaylistSelected', playlist.$pk);
            }
            else
            {
                // Unset last selected playlist
                localStorageService.remove('lastPlaylistSelected');
            }

            // Remove selected flag on previous selected playlist
            if ($scope.data.selectedPlaylist) {
                $scope.data.selectedPlaylist.$isSelected = false;
            }

            // Change selected playlist
            $scope.data.selectedPlaylist = playlist;

            $scope.onSelectPlaylist({ playlist: playlist });
        };

        $scope.toggleDropdown = function(open)
        {
            $scope.data.dropdownState = open;
        };

        $scope.addPlaylist = function()
        {
            var newPlaylist = Playlist.$build({ site : $rootScope.currentSite });
            return playlistEditModal({ model: newPlaylist }).then(function() {
                // Add the newly imported playlist, fetch from page 1
                $scope.refreshPlaylists();
            });
        };

        $scope.importPlaylist = function()
        {
            return playlistImportModal().then(function(/* playlist */) {
                // Add the newly imported playlist, fetch from page 1
                $scope.refreshPlaylists();
            });
        };

        this.clonePlaylist = function(playlist)
        {
            return playlistCloneModal({ model: playlist }).then(function(/* playlist */) {
                // Add the newly cloned playlist, fetch from page 1
                $scope.refreshPlaylists();
            });
        };

        $scope.unfollowPlaylist = function(playlist)
        {
            playlist.unfollow().then(function() {
                $scope.data.playlists.$add(playlist);
            });
        };

        this.toggleFavorite = function(playlist)
        {
            // Make visual changes to the active playlist
            angular.forEach($scope.data.playlists, function(value /*, key */) {
                if (value !== playlist) {
                    value.isFavorite = false;
                }
                if (value.folders.length) {
                    angular.forEach(value.folders, function(folder /*, key */) {
                        if (folder !== playlist) {
                            folder.isFavorite = false;
                        }
                    });
                }
            });

            playlist.toggleFavorite().then(function() {
                // Always reload playlists
                $scope.refreshPlaylists();
            });
        };

        $scope.refreshPlaylists = function()
        {
            $scope.data.loadMoreDisabled = true;
            $scope.data.isLoading = true;
            $scope.scrollPlaylistsToTop();

            $scope.data.playlists.$refresh({ page : 1 }).$then(function() {
                $scope.data.isLoading = false;
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;
            });
        };

       this.refreshPlaylists = function()
       {
           $scope.refreshPlaylists();
       };

        $scope.refreshFavoritePlaylistFolders = function()
        {
            // Check if the favorite playlist is in the current playlist list
            var favoritePlaylist = $scope.data.playlists.$indexOf(function(_obj) { return _obj.isFavorite; });
            if (favoritePlaylist !== -1) {
                // Reload playlist and folders
                $scope.data.playlists[favoritePlaylist].folders.$refresh();
            }
        };
        PubSub.subscribe('carrier.toggleFavorite', function() {
            $scope.refreshFavoritePlaylistFolders();
        });

        $scope.loadMorePlaylists = function()
        {
            $scope.data.loadMoreDisabled = true;
            $scope.data.isLoading = true;

            // check if localStorage holds a order for the playlist
            if( localStorage.getItem('sortPlaylist')){
                $scope.data.sort = localStorage.getItem('sortPlaylist');
            }

            var searchSettings = { site_id : $rootScope.currentSite.$pk, 'per-page' : 20, page: $scope.data.playlists.$page + 1 };
            if($scope.data.sort) {
                searchSettings.sort = $scope.data.sort;
            }

            $scope.data.playlists.$fetch(searchSettings).$then(function() {
                $scope.data.isLoading = false;
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;
            });
        };

        $scope.sortPlaylist = function(sortBy)
        {
            // setting localStorage so the playlist order
            // will be retrievable after closing a tab
            localStorage.setItem('sortPlaylist', sortBy);

            $scope.data.isLoading = true;
            $scope.data.loadMoreDisabled = true;
            $scope.data.sort = sortBy;

            var searchSettings = { title: $scope.data.playlistFilter, 'per-page' : 20, site_id : $rootScope.currentSite.$pk };
            if($scope.data.sort) {
                searchSettings.sort = $scope.data.sort;
            }

            $scope.data.playlists = Playlist.$search(searchSettings).$then(handlePlaylistSearch);
        };

        // Subscribe to multiple events that change the contents of the playlists bar
        var pubSubSubscriptions = [];
        angular.forEach(['carrier.toggleFavorite', 'playlist.change'], function(eventName) {
            pubSubSubscriptions.push(PubSub.subscribe(eventName, function() {
                $scope.refreshPlaylists();
            }));
        });

        $scope.$on('$destroy', function() {
            // Remove PubSub subscriptions
            angular.forEach(pubSubSubscriptions, function(sub) {
                PubSub.unsubscribe(sub);
            });
        });

        $scope.loadSearch = function()
        {
            return appAuthService.requireLogin({modal: {message: gettextCatalog.getString('Please login to load searches.')}}).then(function () {
                loadSearchModal();
            });
        };
    });
