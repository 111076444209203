'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.country
 * @description
 * # country
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Country', function (restmod) {
        return restmod.model('/countries');
    });