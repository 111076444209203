'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.playlistEditModal
 * @description
 * # playlistEditModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('playlistEditModal', function($uibModal) {
        return function(_options) {
            var instance = $uibModal.open({
                animation: false,
                templateUrl: 'views/playlist-edit-modal.html',
                controller: 'PlaylistEditModalCtrl',
                controllerAs: 'PlaylistEditModalCtrl',
                windowClass: 'playlist-edit-modal',
                backdrop: 'static',
                resolve: {
                    options : function() {
                        return _options;
                    }
                }
            });

            return instance.result;
        };
    });
