'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.stamps
 * @description
 * # stamps
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('StampService', function ($http, restmod) {
    return restmod.model('/stamps').mix({
        $extend: {
            Model: {
                status: function() {
                    return $http.get(this.$url() + '/status');
                },

                participate: function() {
                    return $http.post(this.$url() + '/participate');
                },

                optOut: function() {
                    return $http.post(this.$url() + '/opt-out');
                },

                nextRound: function() {
                    return $http.post(this.$url() + '/next-round');
                }
            }
        }
    });
  });
