'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:PlaylistEditorModalCtrl
 * @description
 * # PlaylistEditorModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('PlaylistEditorModalCtrl', function ($scope, $q, Playlist, options, editors) {
        $scope.options = options;

        var editorsMapped = editors.data.map(function(el){
            return {'ID': el.ID, 'name': el.name+' ('+el.email+')'};
        });

        $scope.data = {
            isSaving: false,
            message: 'Add or remove editors for this playlist.',
            playlist: Playlist.$new(options.model.$pk).$extend(options.model),
            editors: editorsMapped
        };

        this.cancel = function() {
            $scope.$dismiss();
        };

        this.submit = function () {
            $scope.data.isSaving = true;
            $scope.data.playlist.saveEditors($scope.data.editors)
                .then(function(){
                    $scope.$dismiss();
                });
            $scope.data.isSaving = false;
        };
    });
