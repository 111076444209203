'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Site', function (restmod) {
        return restmod.model('/sites').mix({
            labels: { hasMany: 'Label' },
            categories: { hasMany: 'Category' },
            companyTypes: { hasMany: 'CompanyType' },
            jobTitles: { hasMany: 'JobTitle' },
            registrationFields: { hasMany: 'RegistrationField' },
            latestPrivacyPolicy: { hasOne: 'PrivacyPolicy' },
            pages: { hasMany: 'Page' },
            homepage: { hasOne: 'Page' },
            news: { hasMany: 'SiteNews' },
            $hooks: {
                'after-feed': function() {
                    if (this.supportPhoneNumber) {
                        this.$supportPhoneNumberTel = this.supportPhoneNumber.replace(/\([0-9]+\)/, '').replace(/[\s]{1,}/g, '');
                    } else {
                        this.$supportPhoneNumberTel = '';
                    }
                }
            },
            $extend: {
                Record: {
                    getTranslationUrl: function(lang) {
                        return this.$url + '/translations?language=' + lang;
                    }
                }
            }
        });
    });
