'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.lyrics
 * @description
 * # lyrics
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('Lyrics', function (restmod) {
        return restmod.model('/lyrics');
    });
