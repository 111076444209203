'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.businessType
 * @description
 * # businessType
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('BusinessType', function (restmod) {
        return restmod.model('/business-types').mix({
            $config: {
                name: 'businessType',
                plural: 'businessTypes'
            }
        });
    });