'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:zopim
 * @description
 * # zopim
 */
angular.module('musicdirectorApp')
  .directive('zopim', function ($window, $log, gettextCatalog, languageService, userService, PubSub) {
    return {
      templateUrl: 'views/chat.html',
      restrict: 'E',
      replace: true,
      controller: function() {
        if (angular.isDefined($window.$zopim)) {
          PubSub.subscribe('userService.onCurrentUserChange', function () {

            userService.getAccount().then(function (account) {
              if (account !== null) {
                $zopim.livechat.setName(account.name);
                $zopim.livechat.setEmail(account.email);
              } else {
                $zopim.livechat.clearAll();
              }
            });
          });
        }
      },
      link: function postLink(scope, element, attrs) {
        if (angular.isUndefined($window.$zopim))
        {
          $log.warn('Zopim library not loaded ($zopim not defined)');
          element.remove();
          return;
        }

        $zopim(function() {
          // Hide the chat windows by default
          $zopim.livechat.hideAll();

          $zopim.livechat.window.setTitle(gettextCatalog.getString('Chat with us'));
          $zopim.livechat.setLanguage(languageService.getCurrentLanguage());

          // Sync user details with Zopim
          userService.getAccount().then(function(account) {
            if (account !== null) {
              $zopim.livechat.setName(account.name);
              $zopim.livechat.setEmail(account.email);
            } else {
              $zopim.livechat.clearAll();
            }
          });

          $zopim.livechat.window.onHide(function() {
            $zopim.livechat.hideAll();
          });

          $zopim.livechat.setOnStatus(function(status) {
            element.toggleClass('disabled', status !== 'online');
          });

          $zopim.livechat.theme.setColor(attrs.windowColor);
          $zopim.livechat.window.setPosition('br');
          $zopim.livechat.departments.filter(scope.currentSite.name);
        });

        // Show Zopim on click on chat button
        element.click(function(event) {
          event.preventDefault();
          if (element.hasClass('disabled') === false) {
            if ($zopim.livechat.window.getDisplay())
            {
              $zopim.livechat.hideAll();
            }
            else
            {
              $zopim.livechat.window.show();
            }

          }
        });
      }
    };
  });
