'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:checkValidOption
 * @description
 * # checkValidOption
 */
angular.module('musicdirectorApp')
  .directive('checkValidOption', function() {
      return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
          ctrl.$validators.checkValidOption = function(modelValue) {
            if (ctrl.$isEmpty(modelValue) ||
              modelValue === 0) {
              // consider empty models to be invalid
              return false;
            }

            // it is invalid
            return true;
          };
        }
      };
    });
