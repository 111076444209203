'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.searchQuery
 * @description
 * # searchQuery
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('SearchQuery', function ($window, restmod) {
      return restmod.model('/search-queries').mix({
          $config: {
              name: 'searchquery',
              plural: 'searchqueries'
          }
      });
    });
