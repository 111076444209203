'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:CataloguesCtrl
 * @description
 * # CataloguesCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('CatalogueCtrl', function (
    $scope, $state, $stateParams, $http, labels, configuration, Analytics,
    PubSub, categories, gettextCatalog, seoData
  ) {
        var perPage = labels.$metadata.perPage;

        $scope.data = {
            labels: labels,
            loadMoreDisabled: labels.$page >= labels.$pageCount,
            alphabet: {},
            activeLetter: { label: null },
            categories: categories,
            activeCategory: null,
            activeCategoryId: null
        };

        seoData.pageTitle = gettextCatalog.getString('Our labels');
        seoData.metaDescription = gettextCatalog.getString('An overview of our labels');

        angular.forEach((['0-9']).concat('abcdefghijklmnopqrstuvwxyz'.split('')), function(letter) {
            $scope.data.alphabet[letter] = {
                label: letter,
                available: false
            };

            if ($stateParams.letter === letter) {
              $scope.data.activeLetter = $scope.data.alphabet[letter];
            }
        });

        // load active category
        if ($stateParams.category) {
          $scope.data.activeCategory = categories.$find($stateParams.category);
          $scope.data.activeCategoryId = $stateParams.category;
        }

        // Retrieve available letters for this user
        var letterUrl = configuration.apiUrlPrefix + '/labels/first_letters?id=' + $scope.currentSite.$pk + '&active-category=' + $stateParams.category;
        $http.get(letterUrl).then(function(response) {
            angular.forEach(response.data.firstLetters, function(value) {
                var realLetter = value.letter.match(/[0-9]/) ? '0-9' : value.letter;
                $scope.data.alphabet[realLetter].available = true;
            });
        });

        $scope.setFilter = function()
        {
            // Update URL
            var urlParams = {
                letter: $scope.data.activeLetter ? $scope.data.activeLetter.label : null,
                category: $scope.data.activeCategory ? $scope.data.activeCategory.$pk : null
            };
            $scope.data.loadMoreDisabled = true;
            $state.transitionTo($state.current.name, urlParams);
        };

        $scope.loadMore = function()
        {
            if ($scope.data.loadMoreDisabled) {
              return;
            }
            $scope.data.loadMoreDisabled = true;
            $scope.data.labels.$fetch({ 'per-page': perPage, page: $scope.data.labels.$page + 1 }).$then(function() {
                $scope.data.loadMoreDisabled = this.$page >= this.$pageCount;
            });
        };

        var subscriptions = [];
        subscriptions.push(PubSub.subscribe('CatalogueCtrl.resetLetterFilter', function () {
            $scope.setFilter();
        }));

        $scope.$on('$destroy', function () {
            subscriptions.forEach(function (subscription) {
                PubSub.unsubscribe(subscription);
            });
        });

        $scope.setLetterFilter = function(letter){
            if (angular.isUndefined(letter))
            {
                letter = { label: null };
            }
            else if (!letter.available)
            {
                return false;
            }

            // Track the letter filter
            Analytics.trackEvent('label', 'filter', letter.label);
            $scope.data.activeLetter = letter;

            $scope.setFilter();
        };

        $scope.setCategoryFilter = function(category){
            $scope.data.activeCategory = category;
            $scope.setFilter();
        };

        $scope.resetFilters = function(){
            $scope.data.activeCategory = null;
            $scope.data.activeLetter = { label: null };
            $scope.setFilter();
        };
  });
