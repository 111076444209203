  'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:DownloadmodalCtrl
 * @description
 * # DownloadmodalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('DownloadModalCtrl', function ($scope, defaults) {

        // combine internal defaults with supplied defaults
        $scope.formData = angular.merge({
            format: 'mp3',
            remember: false,
            emailCopyrightInfo: false
        }, defaults);
        $scope.formats = {
            mp3: true,
            wav: true,
            aiff: $scope.currentSite.$pk !== 2
        };

        this.cancel = $scope.$dismiss;

        this.submit = function () {
            $scope.$close($scope.formData);
        };
    });
