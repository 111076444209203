'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:CopyrightModalCtrl
 * @description
 * # CopyrightModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
  .controller('CopyrightModalCtrl', function ($scope, modalOptions, FileSaver, Blob, $filter) {
    $scope.modalOptions = this.modalOptions = modalOptions;

    this.close = $scope.$dismiss;
    this.cancel = $scope.$dismiss;

    this.download = function () {
      var copyrightTxt = new Blob([modalOptions.copyrightData], {type: 'text/plain;charset=utf-8'});
      var filenameParts = [];
      filenameParts.push('tr' + (modalOptions.track.track < 10 ? '0' + modalOptions.track.track : modalOptions.track.track));
      filenameParts.push(modalOptions.track.title);
      filenameParts.push(modalOptions.track.composers);
      filenameParts.push(modalOptions.track.carrier.code);
      filenameParts.push(modalOptions.track.carrier.label.title);
      FileSaver.saveAs(copyrightTxt, $filter('filenamize')(filenameParts.join('-')) + '.txt');
      $scope.$close();
    };
  });
