'use strict';

/**
 * @ngdoc service
 * @name webappMusicdirectorComApp.site
 * @description
 * # site
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Carrier',
        function ($http, $rootScope, $window, $interval, $filter, restmod, configuration, downloadService, appAuthService,
                  Playlist, shareModal, playService, playlistEditModal, gettextCatalog, carrierEditModal,
                  RemotePlayQueue, PubSub, userService)
    {
        return restmod.model('/carriers').mix('RandomSortModel', {
            tracks: { hasMany: 'Track' },
            versionTracks: { hasMany: 'Track' },
            categories: { belongsToMany: 'Category' },
            label: { belongsTo: 'Label', key: 'labelID' },
            type: 'carrier',
            $hooks: {
                'after-feed': function () {
                    this.$keywords = $filter('split')(this.text.replace(/,[^\s]/, ', '), '/[ ;]/');
                    this.visibleCategories = this.categories.filter(function(category) {
                        return category.active && category.visible;
                    });
                    this.identifyVersionsMethod = this.identifyVersionsMethod !== null ? this.identifyVersionsMethod : 0;
                    this.identifyVersionsSortingMethod = this.identifyVersionsSortingMethod !== null ? this.identifyVersionsSortingMethod : 0;
                }
            },
            $extend: {
                Record: {
                    play: function (options) {
                        var handlePlay = function() {
                            options = angular.extend({onlyWhenEmpty: false, startPaused: false}, options);

                            // Use the click event to play a silent MP3, to circumvent iOS restrictions
                            playService.playSilentFirst();

                            new RemotePlayQueue(this).load().then(function (queue) {
                                playService.playQueue(queue, options);
                            });
                        }.bind(this);

                        if (!$rootScope.userCanPlay) {
                          return appAuthService.requireLogin({ modal : { message : gettextCatalog.getString('Please login to play tracks') }}).then(function() {
                              handlePlay();
                          });
                        }
                        else {
                            handlePlay();
                        }
                    },

                    download: function() {
                        return downloadService.downloadCarrier(this);
                    },

                    toggleFavorite : function(site) {
                        var self = this;

                        var pubSubCallback = function() {
                            PubSub.publish('carrier.toggleFavorite', self);
                        };

                        return appAuthService.requireLogin({ modal : { message : 'Please login to favorite albums' }}).then(function() {
                            var favoriteUrl = Playlist.$new( 'favorite').$url() + '/carriers/' + self.$pk + '?site_id=' + site.$pk;

                            if (self.isFavorite)
                            {
                                self.isFavorite = 0;
                                return $http.delete(favoriteUrl).catch(function(result) {
                                    if (result.status !== 404) {
                                        self.isFavorite = 1;
                                    }
                                }).then(pubSubCallback);
                            }
                            else
                            {
                                self.isFavorite = 1;
                                return $http.put(favoriteUrl).catch(function(result) {
                                    // Disable favorite status for now
                                    self.isFavorite = 0;

                                    // 404 means no favorite project, allow the user to create a new one
                                    if (result.status === 404)
                                    {
                                        var newPlaylist = Playlist.$build({ site : site });
                                        return playlistEditModal({
                                            model: newPlaylist,
                                            message: gettextCatalog.getString('Your account does not have an active playlist. Please enter a name for your new playlist.')
                                        }).then(function(playlist) {
                                            // Set new playlist as favorite
                                            playlist.toggleFavorite().then(function() {
                                                // Add the carrier to the new playlist
                                                self.toggleFavorite(site);
                                            });
                                        });
                                    }
                                }).then(pubSubCallback);
                            }
                        });
                    },

                    share: function() {
                        var shareUrl = $window.location.protocol + '//' + $window.location.hostname + '/carrier/' + this.$pk;
                        return shareModal({
                            model: this,
                            shareUrl: shareUrl,
                            shareText: gettextCatalog.getString('Listen to this carrier on {{siteName}}!', { siteName: $rootScope.currentSite.shortName })
                        });
                    },

                    edit: function()
                    {
                        return carrierEditModal({
                            model: this
                        });
                    },

                    generateTrackWaveforms: function()
                    {
                        return $http.post(this.$url() + '/generate-track-waveforms');
                    },

                    updateTrackDurations: function()
                    {
                        return $http.post(this.$url() + '/update-track-durations');
                    },

                    identifyVersions: function()
                    {
                        return $http.post(this.$url() + '/identify-versions').then(function() {
                            $window.location.reload();
                        });
                    }
                }
            }
        });
    });
