'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.passwordResetModal
 * @description
 * # passwordResetModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('passwordResetModal', function ($uibModal) {
      return function(_options) {
        var instance = $uibModal.open({
          animation: false,
          backdrop: 'static',
          templateUrl: 'views/password-reset-modal.html',
          controller: 'PasswordResetModalCtrl',
          controllerAs: 'PasswordResetModalCtrl',
          windowClass: 'password-reset-modal',
          resolve : {
            modalOptions : function() {
              return _options;
            }
          }
        });

        return instance.result;
      };
    });
