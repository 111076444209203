'use strict';

/**
 * @ngdoc function
 * @name musicdirectorApp.controller:ShareModalCtrl
 * @description
 * # ShareModalCtrl
 * Controller of the musicdirectorApp
 */
angular.module('musicdirectorApp')
    .controller('ShareModalCtrl', function ($scope, $http, options, gettextCatalog, languageService, userService, infoModal)
    {
        $scope.data = {
            multipleModelIds: options.multipleModelIds,
            shareUrl: options.shareUrl,
            twitterStatusText: null,
            isAuthenticated : userService.isAuthenticated(),
            form: {
            }
        };

        if (options.shareUrl) {
          $scope.data.twitterStatusText = (angular.isDefined(options.shareText) ? options.shareText : gettextCatalog.getString('Look what I found on {{siteName}}!', { siteName: $scope.currentSite.shortName })) + ' ' + options.shareUrl;
        }

        // For logged in users, we fix the sender to their email and contact info
        userService.getAccount().then(function(account) {
            if (account !== null) {
                $scope.data.form.sender = account.email + ' (' + account.contactFirstname + ' ' + account.contactLastname + ')';
            }
        });

        function showErrors(errors)
        {
            infoModal({
                title: gettextCatalog.getString('Please check your input'),
                message: gettextCatalog.getString('One or more errors prevented the mail to be send:'),
                errors: errors,
                closeButtonText: gettextCatalog.getString('OK')
            });
        }

        this.cancel = function() {
            $scope.$dismiss();
        };

        this.submit = function () {
            if (!$scope.data.isAuthenticated &&
                !$scope.data.form.captcha)
            {
                showErrors({
                    captcha: { message: gettextCatalog.getString('Captcha should be completed.') }
                });
                return;
            }

            // var ctrl = this;
            var postData = angular.extend({ modelIds: $scope.data.multipleModelIds, selection: options.selection }, $scope.data.form, $scope.data.shareUrl);
            $http.post(options.model.$url() + '/share_by_email?language=' + languageService.getCurrentLanguage(), postData).then(function() {
                $scope.$close();
            }, function(response) {
                // ctrl.reset();
                showErrors(response.data.errors);
            });
        };
    });
