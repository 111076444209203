'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.search
 * @description
 * # search
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .factory('Search', function (restmod)
    {
        function handleBeforeRequest(request_config, _params) {
            if (request_config.url.match(/\/search\//) !== null) {
              _params = _params || request_config.params;

              // Parameter page is always a get parameter
              angular.forEach(['page', 'per-page', 'expand'], function (field) {
                if (field in _params) {
                  request_config.url += request_config.url.indexOf('?') !== -1 ? '&' : '?';
                  request_config.url += field + '=' + _params[field];
                  delete _params[field];
                }
              });
              request_config.method = 'POST';
              request_config.data = _params;
              request_config.params = null;
            }
        }

        return restmod.model().mix('RandomSortModel', {
            tracks: {
                hasMany: 'SearchResult',
                hooks: {
                    'before-request': handleBeforeRequest
                }
            },
            carriers: {
              hasMany: 'SearchResult',
              hooks: {
                'before-request': handleBeforeRequest
              }
            },
            file : {
                hasMany: 'Carrier',
                hooks: {
                    'before-request': handleBeforeRequest
                }
            },
            $extend: {
                Resource: {
                    $fetch: function (_params) {
                        return this.$decorate({
                            'before-request': function(request_config) {
                                return handleBeforeRequest(request_config, _params);
                            }
                        }, function () {
                            return this.$super();
                        });
                    }
                }
            }
        }).single('/search');
    });
