'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.stampPromoModal
 * @description
 * # stampPromoModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('stampPromoModal', function ($uibModal, StampService, $state) {
    return function(_options) {
      var currentState = $state.current;

      var instance = $uibModal.open({
        animation: false,
        backdrop: 'static',
        templateUrl: '../../views/stamp-promo-modal.html',
        controller: 'StampPromoModalCtrl',
        controllerAs: 'StampPromoModalCtrl',
        windowClass: 'stamp-promo-modal',
        resolve : {
          modalOptions : function() {
            return angular.extend({ message : '' }, _options);
          },
          stampStatus: function() {
            return StampService.single('/stamps/status').$resolve().$asPromise();
          },
        }
      });

      return instance.result.finally(function () {
        if (currentState.name.length === 0) {
          $state.go('root.index');
        } else {
          $state.go(currentState);
        }
      });
    };
  });
