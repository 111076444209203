'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.apiMixin
 * @description
 * # apiMixin
 * Factory in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .factory('apiMixin', ['restmod', function (restmod) {
        return restmod.mixin('AMSApi', {
            $config : {
                primaryKey: 'ID',
                jsonMeta: '_meta',
                jsonLinks: '_links'
            },
            // Modify $fetch so it uses POST instead of GET:
            $hooks: {
                'before-request': function(_req) {
                    if (_req.params &&
                        _req.params._req)
                    {
                        angular.extend(_req, _req.params._req);
                        delete _req.params._req;
                    }
                }
            },
            $extend: {
                Model: {
                    unpack : function(_resource, _raw) {
                        // Do not use an envelope for single resources
                        if(_resource.$isCollection)
                        {
                            return this.$super(_resource, _raw);
                        }
                        else
                        {
                            return _raw;
                        }
                    }
                }
            }
        });
    }]);
