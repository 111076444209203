'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.saveSearchModal
 * @description
 * # saveSearchModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('saveSearchModal', function($uibModal) {
      return function(_options) {
        var instance = $uibModal.open({
          animation: false,
          templateUrl: 'views/save-search-modal.html',
          controller: 'SaveSearchModalCtrl',
          controllerAs: 'SaveSearchModalCtrl',
          windowClass: 'save-search-modal',
          resolve: {
            options : function() {
              return _options;
            },
          }
        });

        return instance.result;
      };
    });
