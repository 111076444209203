'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:track
 * @description
 * # track
 */
angular.module('musicdirectorApp')
    .directive('track', function (PubSub, RemotePlayQueue, $state, $window, gettextCatalog, $rootScope,
                                  $httpParamSerializer, $location) {
        return {
            restrict: 'A',
            replace: true,
            require: '^trackList',
            scope: {
                trackContainer: '=track',
                isLast: '=',
                enableDrag: '=',
                playScope: '=',
                shared: '='
            },
            templateUrl: (params) =>'views/'+params[0].attributes.view.value+'.html',
            link: function(scope, element, attrs, trackListCtrl) {
                // Get scope variables form list controller
                angular.forEach(['listViewOptions', 'currentSite', 'playScope', 'dropboxes', 'carrier', 'isSystemAdmin', 'userCanPlay', 'userViewPlayButton', 'userCanDownload', 'userViewDownloadButton'], function(value) {
                    scope[value] = trackListCtrl.getScopeAttribute(value);
                });

                scope.ratingText = {
                    'S': gettextCatalog.getString('Rate') + ': ' + 'Standard',
                    'P': gettextCatalog.getString('Rate') + ': ' + 'Premium',
                    'P+': gettextCatalog.getString('Rate') + ': ' + 'Premium Plus',
                    'SD': gettextCatalog.getString('Rate') + ': ' + 'Sound Design',
                    'PSD': gettextCatalog.getString('Rate') + ': ' + 'Premium Sound Design'
                };

                // Convert playScope into a remote playing queue
                if (scope.playScope &&
                    (typeof scope.playScope === 'object' &&
                    !(scope.playScope instanceof RemotePlayQueue)))
                {
                    scope.playScope = new RemotePlayQueue(scope.playScope);
                }

                scope.track = angular.isObject(scope.trackContainer.track) ? scope.trackContainer.track : scope.trackContainer;

                // Get a reference to the carrier, from the parent (trackList), track or the linked carrier
                if (scope.$parent.carrier)
                {
                    scope.carrier = scope.$parent.carrier;
                }
                else if (angular.isDefined(scope.trackContainer.carrier) && scope.trackContainer.carrier.$pk)
                {
                    scope.carrier = scope.trackContainer.carrier;
                }
                else if (angular.isDefined(scope.track.carrier))
                {
                    scope.carrier = scope.track.carrier;
                }

                // Get a reference to the label, from the parent (trackList), track or the linked carrier
                if (scope.$parent.label)
                {
                    scope.label = scope.$parent.label;
                }
                else if (angular.isDefined(scope.trackContainer.label) && scope.trackContainer.label.$pk)
                {
                    scope.label = scope.trackContainer.label;
                }
                else if (angular.isDefined(scope.track.label))
                {
                    scope.label = scope.track.label;
                }
                else if (scope.carrier !== null)
                {
                    scope.label = scope.carrier.label;
                }
                else
                {
                    scope.label = {title: ''};
                }

                scope.detailsOpen = false;
                scope.data = {
                    isChecked: false,
                    checkboxId: scope.track.$pk,
                    viaSearch: $state.current.name === 'root.search-results'
                };
                scope.trackListCtrl = trackListCtrl;

                scope.toggleTrackSelection = function() {
                  trackListCtrl.toggleTrackSelection(scope.track.$pk, scope.data.isChecked);
                };

                scope.toggleDetails = function() {
                    trackListCtrl.toggleDetails(scope.track.$pk);
                };

                scope.toggleVersions = function() {
                    trackListCtrl.toggleDetails(scope.track.$pk, { tab : 1 });
                };

                scope.toggleStems = function() {
                    trackListCtrl.toggleDetails(scope.track.$pk, { tab: 2 });
                };

                scope.removeFromPlaylist = function() {
                    var playlist = trackListCtrl.getScopeAttribute('playlist');
                    return scope.track.removeFromPlaylist(playlist).then(function() {
                        // Remove track from list, based on the PlaylistTrack model
                        playlist.linkedTracks.$remove(scope.trackContainer);
                    });
                };

                scope.savePosition = function($event, index, pageIndex)
                {
                    var params = $rootScope.getPageQuery(pageIndex);
                    // Calculate the position corresponding the page index
                    var position = ((angular.element($event.target).prop('offsetHeight') + 25) * index) + 100 /*header height*/;
                    // Force the replaceState function to use this position
                    $rootScope.forceYScrollPosition =  position;
                    // Set the url to the correct page index
                    history.replaceState(null, '', $location.absUrl().split(/\?/)[0] + '?' + params);
                    // Force the replaceState function to use this position
                    $rootScope.forceYScrollPosition =  position;
                    setTimeout(function () {
                        $rootScope.forceYScrollPosition =  position;
                    }, 500);
                };

                scope.handleToggleDetailsEvent = function(state)
                {
                    if (angular.isDefined(state))
                    {
                        scope.detailsOpen = state;
                    }
                    else
                    {
                        scope.detailsOpen = !scope.detailsOpen;
                    }

                    // Toggle open class
                    element.toggleClass('open', scope.detailsOpen);

                    // Change chevron class
                    if (scope.detailsOpen) {
                        element.find('.fa.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-up');
                    } else {
                        element.find('.fa.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
                    }

                    // Resolve other versions when details are shown
                    if (scope.detailsOpen) {
                        // Load categories for linked carrier
                        if (scope.carrier.categories.$resolve) {
                            scope.carrier.categories.$resolve();
                        }
                    }
                };

                // Handle the toggle all details call
                scope.$on('toggleAllDetails', function(event, state) {
                    scope.handleToggleDetailsEvent(state);
                });

                // Handle the toggle all details call
                scope.$on('toggleAllCheckboxes', function(event, data) {
                    scope.data.isChecked = data.state;
                });

                // Handle toggle on one track
                scope.$on('toggleDetails', function(event, args) {
                    var newState = angular.isDefined(args.state) ? args.state :  !scope.detailsOpen;

                    if (args.trackId === scope.track.$pk) {
                        scope.handleToggleDetailsEvent(newState);
                    } else if (!args.all) {
                        // Hide details when no match and not toggling all tracks
                        scope.handleToggleDetailsEvent(false);
                    }
                });

                var pubSubSubscriptions = [];
                pubSubSubscriptions.push(PubSub.subscribe('playService.playTrack', function(args) {
                    if (scope.track) {
                      // If current track is playing, and new track is played, remove playing
                      if ((scope.track.$isPlaying ||
                        scope.track.$isPaused) &&
                        scope.track.$pk !== args.track.$pk) {
                        scope.track.$isPlaying = false;
                        scope.track.$isPaused = false;
                      }
                      else if (scope.track &&
                        args.track &&
                        scope.track.$pk === args.track.$pk
                      ) {
                        // Copy incoming settings to the scope local track
                        scope.track.$isPlaying = args.track.$isPlaying;
                        scope.track.$isPaused = args.track.$isPaused;
                      }
                    }
                }));
                pubSubSubscriptions.push(PubSub.subscribe('playService.setState', function(args) {
                    if (scope.track !== null &&
                        args.track !== null &&
                        scope.track.$pk === args.track.$pk
                    ) {
                        scope.track.$isPlaying = args.track.$isPlaying;
                        scope.track.$isPaused = args.track.$isPaused;
                    }
                }));

                scope.$on('$destroy', function() {
                    for (var iSubscription = 0; iSubscription < pubSubSubscriptions.length; iSubscription++)
                    {
                        PubSub.unsubscribe(pubSubSubscriptions[iSubscription]);
                    }
                });
            }};
    });
