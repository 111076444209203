'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.loginModal
 * @description
 * # loginModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('videoModal', function($uibModal, playService) {
      return function(_options) {
        var isGlobalPlayerPlaying = playService.getState() === 'play';

        // Pause the normal MusicDIRECTOR player
        if (isGlobalPlayerPlaying) {
          playService.pause();
        }

        var instance = $uibModal.open({
          animation: false,
          templateUrl: 'views/video-modal.html',
          controller: 'VideoModalCtrl',
          controllerAs: 'VideoModalCtrl',
          windowClass: 'video-modal',
          resolve : {
            modalOptions : function() {
              return _options;
            }
          }
        });

        return instance.result.finally(function() {
          // Continue global player
          if (isGlobalPlayerPlaying) {
            playService.play();
          }
        });
      };
    });
