'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:trackList
 * @description
 * # trackList
 */
angular.module('musicdirectorApp')
  .directive('trackList', function ($state, dropboxService, $window) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: function(params){
        return 'views/'+params[0].attributes.view.value+'.html';
      },
      scope: {
        tracks: '=',
        playlist: '=',
        enableSideBar: '=',
        playScope: '=',
        currentSite: '=',
        carrier: '=',
        label: '=',
        enableDrag: '=',
        sidebarVisible: '=',
        onAddKeyword: '&',
        onToggleSidebar: '&',
        onTrackSelectionChange: '&',
        loadMoreTracks: '&',
        loadMoreDisabled: '=',
        loadingResults: '=',
        trackShared: '=',
        disableDrag: '=?',
        downloadFilter: '=',
        noResults: '='
      },
      link: function(scope, element, attrs) {
        // Add class to parent table
        if (attrs.extraTableClass) {
          element.addClass(attrs.extraTableClass);
        }

        scope.dropboxes = dropboxService.dropboxes;

        scope.data = {
          allCheckboxes: false,
          selectedTracks: {},
        };

        scope.scrollList = function(event){
          var top = $window.scrollMonitor.viewportTop;
          var bottom = $window.scrollMonitor.viewportBottom;
          if(event.pageY - 80 < top){
            $window.scrollTo(0, top - 20);
          } else if(event.pageY + 100 > bottom){
            $window.scrollTo(0, top + 20);
          }
          return true;
        };

        // Process default values for attributes
        scope.listViewOptions = {
          source: angular.isDefined(scope.playlist) ? 'playlist' : 'tracks',
          isPlaylistOwner: (angular.isDefined(scope.playlist) && scope.playlist !== null) ? scope.playlist.isOwner : false,
          isPlaylistEditor: (angular.isDefined(scope.playlist) && scope.playlist !== null) ? scope.playlist.isEditor : false
        };
        angular.forEach({ showTrackNumber: false, showVersions : true, showDuration: true, showCover : false,
          showFavoriteBtn : true, showCarrier: false, showLabel : false, showCheckboxes : false, isPlaylist: true }, function(value, valueKey)
        {
          // Use default value for non specified options
          if (angular.isUndefined(attrs[valueKey]))
          {
            scope.listViewOptions[valueKey] = value;
          }
          else
          {
            scope.listViewOptions[valueKey] = scope.$eval(attrs[valueKey]);
          }
        });

        scope.listViewOptions.sidebarVisible = scope.sidebarVisible;
        scope.$watch('sidebarVisible', function(newValue, oldValue) {
          if (newValue !== oldValue)
          {
            scope.listViewOptions.sidebarVisible = scope.sidebarVisible;
            scope.$broadcast('toggleWatchers', true);
          }
        });

        scope.useKeywordHandler = angular.isDefined(attrs.onAddKeyword);

        scope.toggleSidebar = function() {
          scope.listViewOptions.sidebarVisible = !scope.listViewOptions.sidebarVisible;
          scope.onToggleSidebar({ state: scope.listViewOptions.sidebarVisible });
        };

        scope.toggleAllDetails = function() {
          scope.showAllDetails = !scope.showAllDetails;
          scope.$broadcast('toggleAllDetails', scope.showAllDetails);
        };

        scope.toggleAllCheckboxes = function() {
          scope.$broadcast('toggleAllCheckboxes', { state: scope.data.allCheckboxes });
          if (scope.data.allCheckboxes) {
            if (scope.tracks) {
              scope.data.selectedTracks = {};
              // Fetch all track ID's and set them to true
              scope.tracks.map(function(x) { return x.track.$pk; }).forEach(function(val) {
                scope.data.selectedTracks[val] = true;
              });
            }
          } else {
            scope.data.selectedTracks = {};
          }
          if (scope.onTrackSelectionChange) {
            scope.onTrackSelectionChange({ tracks: scope.data.selectedTracks });
          }
        };

        scope.loadMore = function() {
          if (scope.loadMoreTracks) {
            scope.loadMoreTracks();
          }
        };
          // attributes for the drag and drop functionality
          scope.tracksDND = {
              allowedTypes: ['track'],
              list: [],
              sequence: []
          };

          if(scope.disableDrag === undefined){
              if(scope.carrier === undefined){
                  if(scope.enableDrag) {
                      scope.disableDrag = false;
                  }
              } else {
                  scope.disableDrag = true;
              }
          }

          scope.resequenceTracks = function(targetIndex, item, sourceIndex){
              var newIndex = Math.ceil(targetIndex/2);
              // remove the item from the list
              scope.tracksDND.sequence.splice(sourceIndex, 1);

              // taking the removed item into account
              if(newIndex > sourceIndex) {
                  newIndex--;
              }

              // adding the item to the list
              scope.tracksDND.sequence.splice(newIndex, 0, item);

              // setup new order for the sequence function
              var newOrder = [];
              angular.forEach(scope.tracksDND.sequence, function(value){
                  newOrder.push(value.ID);
              });

              // update backend with new order
              scope.playlist.sort(newOrder);

              // returning true so the default adding functionality doesn't trigger
              return true;
          };
      },
      controller: function($scope) {
        $scope.$watch('tracks.length', function(){
          // empty sequence variables to rebuild the new list
          $scope.tracksDND.list = [];
          $scope.tracksDND.sequence = [];

          angular.forEach($scope.tracks, function(value){
            var key = 'tr' + value.$pk.toString();
            var ID = value.track.$pk ? value.track.$pk : value.$pk;
            var title = value.track.title;
            $scope.tracksDND.sequence.push({
              key: key,
              ID: ID,
              title: title,
              type: $scope.tracksDND.allowedTypes[0]
            });
            $scope.tracksDND.list[key] = value;
          });
        });

        this.toggleDetails = function(trackId, options) {
          // Disable show all details, we made an exception
          $scope.showAllDetails = false;

          options = angular.extend({trackId: trackId}, options);

          // Toggle track and track details
          $scope.$broadcast('toggleDetails', options);
        };

        this.getScopeAttribute = function(attr) {
          return $scope[attr];
        };

        this.toggleTrackSelection = function(trackId, state) {
          if (state) {
            $scope.data.selectedTracks[trackId] = true;
          } else {
            delete $scope.data.selectedTracks[trackId];
          }

          var selectCount = Object.keys($scope.data.selectedTracks).length;
          if (selectCount === 0) {
            $scope.data.allCheckboxes = false;
          } else if (selectCount === $scope.tracks.length) {
            $scope.data.allCheckboxes = true;
          }

          if ($scope.onTrackSelectionChange) {
            $scope.onTrackSelectionChange({ tracks: $scope.data.selectedTracks });
          }
        };


        this.addKeyword = function(keyword) {
          // replace special chars in the search function
          keyword = keyword.replace(/[?!.\-:]/g, '');

          if ($scope.useKeywordHandler) {
            $scope.onAddKeyword({ keyword: keyword });
          } else {
            $state.go('root.search-results', { scope: 'tracks', query: keyword.toLowerCase()});
          }
        };
      },
      controllerAs: 'trackListCtrl'
    };
  });
