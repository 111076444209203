'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.playlistResequenceModal
 * @description
 * # playlistResequenceModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('playlistResequenceModal', function ($uibModal, $rootScope) {
      return function(_options) {
        var instance = $uibModal.open({
          animation: false,
          templateUrl: 'views/playlist-resequence-modal.html',
          controller: 'PlaylistResequenceModalCtrl',
          controllerAs: 'PlaylistResequenceModalCtrl',
          windowClass: 'playlist-resequence-modal',
          backdrop: 'static',
          resolve: {
            options : function() {
              return _options;
            },
            tracks: function() {
              var tracks = [];
              angular.forEach(_options.model.linkedTracks, function(value) {
                tracks.push({
                  ID: value.track.ID,
                  trackTitle: value.track.title,
                  carrierCode: value.track.carrier.code,
                  carrierTitle: value.track.carrier.title,
                  carrierCover: value.track.carrier.thumbnails['85x85'] + $rootScope.addCacheBuster(value.track.carrier.timeChange),
                  labelTitle: value.track.carrier.label.title
                });
              });

              return tracks;
            }
          }
        });

        return instance.result;
      };
    });
