'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.uploadAudioModal
 * @description
 * # uploadAudioModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
  .service('uploadAudioModal', function ($uibModal) {
      return function(_options) {
          var instance = $uibModal.open({
          animation: false,
          backdrop: 'static',
          templateUrl: 'views/upload-audio-modal.html',
          controller: 'UploadAudioModalCtrl',
          controllerAs: 'UploadAudioModalCtrl',
          windowClass: 'upload-audio-modal',
          resolve : {
            modalOptions : function() {
              return _options;
            }
          }
        });

        return instance;
      };
    });
