'use strict';

/**
 * @ngdoc service
 * @name musicdirectorApp.infoModal
 * @description
 * # infoModal
 * Service in the musicdirectorApp.
 */
angular.module('musicdirectorApp')
    .service('forgotPasswordModal', function($uibModal) {
        return function(_options) {
            var instance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                templateUrl: 'views/forgot-password-modal.html',
                controller: 'ForgotPasswordModalCtrl',
                controllerAs: 'ForgotPasswordModalCtrl',
                windowClass: 'forgot-password-modal',
                resolve : {
                    modalOptions : function() {
                        return _options;
                    }
                }
            });

            return instance.result;
        };
    });
