'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:compileTemplate
 * @description
 * # compileTemplate
 */
angular.module('musicdirectorApp')
    .directive('compileTemplate', function ($compile, $parse) {
        return {
            link: function (scope, element, attr) {
                var parsed = $parse(attr.ngBindHtml);

                function getStringValue() {
                    return (parsed(scope) || '').toString();
                }

                //Recompile if the template changes
                scope.$watch(getStringValue, function () {
                    $compile(element, null, -9999)(scope);  //The -9999 makes it skip directives so that we do not recompile ourselves
                });
            }
        };
    });