'use strict';

/**
 * @ngdoc directive
 * @name musicdirectorApp.directive:stamp
 * @description
 * # stamp
 */
angular.module('musicdirectorApp')
    .directive('stamp', function ($timeout, PubSub, stampPromoModal) {
        return {
            template: '<div id="stamp-overlay" ng-class="{ \'show-stamp\': data.show, \'hide-stamp\': data.hide }" ui-sref="root.extra.promo"></div>',
            replace: true,
            restrict: 'A',
            link: function postLink(scope /*, element, attrs */) {
                scope.data = {
                    show: false
                };

                scope.show = function () {
                    // Dispay
                    scope.data.show = true;
                    scope.data.hide = false;
                    scope.$apply();

                    // Hide after 2 seconds
                    $timeout(function() {
                        scope.data.show = false;
                        scope.data.hide = true;
                        scope.$apply();

                        // Remove element after 1 second
                        $timeout(function() {
                            scope.data.hide = false;
                            scope.$apply();
                        }, 1000);
                    }, 2000);
                };

                var subscription = PubSub.subscribe('track.logPlay', function(args) {
                    var data = args[1];
                    if (data.addons &&
                        data.addons.stamps) {
                        if (data.addons.stamps.participates) {
                            // Check if this play triggerd a full card
                            if (data.addons.stamps.card_completed) {
                                stampPromoModal();
                            }
                            // Check if this play triggered a new stamp
                            else if (data.addons.stamps.daily_action_limit === data.addons.stamps.daily_counter) {
                                scope.show();
                            }
                        }
                        // Display modal
                        else if (!data.addons.stamps.opted_out) {
                            stampPromoModal();
                        }
                    }
                });
                scope.$on('$destroy', function() {
                    PubSub.unsubscribe(subscription);
                });
            }
        };
    });
